<template>
	<div class="chat-view">
		<el-container class="chat-page">
			<div class="chat-list-box">
				<div class="friendRelated">
					<div class="searchInputBox">
						<search-input @handleToChatRoom="handleToChatRoom" />
					</div>

					<div class="search-right-add" @click="addFriendGroupPop">
						<i class="icon-sohu-jiahao iconfont" style="font-weight: bold"></i>
					</div>
				</div>
				<div class="chat-list-items" ref="chatListItemsRef">
					<div v-for="(item, index) in chatUserMsg" :key="item.id">
						<chatUserItem
							@chatUserItemClick="chatUserItemClick(item, index)"
							:item="item"
							:active="chatUserMsgActive"
							:chatUserActives="chatUserActives"
							:userList="userList"
							@chatUserItemRightClick="chatUserItemRightClick(item, index)"
						></chatUserItem>
					</div>
				</div>
			</div>
			<el-container
				class="chat-box"
				v-if="Object.values(chatUserMsgActive).some((item) => !!item)"
				v-loading="!isImMsgList"
			>
				<el-container>
					<el-header class="messageHeader">
						<messageUser_header
							ref="messageUser_header"
							:chatUserMsgActive="chatUserMsgActive"
							:chatUserMsg="chatUserMsg"
							:switchGroup="switchGroup"
							:imDetails="imDetails"
							:isMyinGroup="isMyinGroup"
							@update="handleUpdateData"
							@close="$emit('close')"
						>
						</messageUser_header>
					</el-header>
					<div
						class="strange-view"
						v-if="
							!imDetails.meFriend &&
							imDetails.sessionType === 'single' &&
							userInfo.id != imDetails.friendId &&
							!isTaskChat
						"
					>
						<div class="title">对方不是你的好友</div>
						<div class="btn" @click="handleOpenApplyFriend">加好友</div>
					</div>

					<div
						class="lh-17px h-41xp text-14px flex items-center justify-between py-12px px-21px sticky top-41px z-10 bg-#ffeee6 relative"
						v-if="isShowOrderDetails"
					>
						<!-- 客户群展示 -->
						<div
							class="text-#222222"
							v-if="
								['groupFormCustom', 'groupTaskCustom'].includes(
									this.imDetails?.groupType,
								)
							"
						>
							【{{ stateList[imDetails?.planVo?.state] }}】已达标人数
							<span class="text-#FF6C27">{{
								imDetails?.planVo?.standNum
							}}</span>
							人
						</div>
						<!-- 主群展示 -->
						<div
							class="flex items-center"
							v-if="['groupForm'].includes(imDetails?.groupType)"
						>
							<div class="max-w-150px truncate inline-block">
								{{ imDetails?.planVo?.taskTitle }}
							</div>
							<div class="w-1px bg-#222222 h-14px mx-10px"></div>
							<div class="">
								商单进度：
								<span class="text-#FF6C27">
									{{ imDetails?.planVo?.standNum }}
								</span>
								/
								{{ imDetails?.planVo?.deliveryStandard }}
							</div>
						</div>

						<div @click="handleOpenOrder" class="cursor-pointer">
							查看
							<i class="el-icon-arrow-right text-#222222"></i>
						</div>
					</div>

					<el-main style="padding: 0">
						<el-container class="content-box">
							<el-main id="el-main" class="im-chat-main">
								<div class="im-chat-box" @scroll="onScroll">
									<ul>
										<div
											class="messageLoading"
											v-if="
												(chatMsgLoading ||
													messagePages.total == messagesList.length) &&
												messagePages.pageNum > 1
											"
										>
											{{
												messagePages.total > messagesList.length
													? '加载中...'
													: '没有更多消息了'
											}}
										</div>
										<div
											class="im-chat-box-topmsg"
											v-if="imDetails.groupNotice"
										>
											<p
												v-for="(text, index) in imDetails.groupNotice.split(
													'\n',
												)"
												:key="index"
											>
												{{ text }}
											</p>
										</div>
										<li
											:id="`id_${item.chatId}`"
											v-for="(item, idx) in messagesList"
											:key="item.chatId"
											style="position: relative"
										>
											<chatMessageItem
												@imageLoad="imageLoad"
												:prev="idx == 0 ? null : messagesList[idx - 1]"
												:item="item"
												:detail="imDetails"
												:role="groupRole"
												:type="item.type"
												:userList="userList"
												:isMyinGroup="isMyinGroup"
												:isTaskChat="isTaskChat"
												@errorClick="errorClick"
												@forward="handleForward"
												@contextmenu="handleContextmenu"
												@messageItemClick="messageItemClick"
											>
											</chatMessageItem>
										</li>
										<li id="id_-1"></li>
									</ul>
								</div>
							</el-main>
							<el-footer class="im-chat-footer">
								<chatSendBox
									ref="chatSendBox"
									:chatUserMsgActive="chatUserMsgActive"
									:switchGroup="switchGroup"
									:imDetails="imDetails"
									:user-list="userList"
									:isMyinGroup="isMyinGroup"
									@sendMessage="sendMessage"
									@loadingFileMsg="handleLoadingMsg"
								></chatSendBox>
							</el-footer>
						</el-container>
					</el-main>
				</el-container>
			</el-container>
			<div v-else class="chatMessageEmpty">
				<el-image
					:src="require('@/assets/images/chat/messageEmpty.png')"
				></el-image>
				<div class="empty_text">暂无私信</div>
			</div>

			<!-- 通讯录弹窗 -->
			<friendsDialog ref="friendsDialogRef"></friendsDialog>

			<!-- 添加好友弹窗 -->
			<addFriendsDialog ref="AddFriendsDialogRef"></addFriendsDialog>

			<!-- 申请添加好友弹窗 -->
			<applicationDialog ref="ApplicationDialogRef"></applicationDialog>

			<!-- 建群聊 -->
			<selectMembersDialog ref="SelectMembersDialogRef"></selectMembersDialog>

			<!--转发-->
			<forwardDialog
				:userJoinGroups="userJoinGroups"
				:list="chatUserMsg"
				:limit="9"
				ref="forwardDialogRef"
			/>
			<!-- 加好友 建群聊弹窗 -->
			<dropdown @handleEvent="handleDropDown" ref="dropdownRef" />
			<!-- 商单 -->
			<orderDialog
				ref="orderDialogRef"
				@orderUpdata="getImDetailsInfo"
			></orderDialog>
		</el-container>
	</div>
</template>

<script>
import ChatSendBox from '@/views/chat/components/chat/chatSendBox.vue';
import ChatUserItem from '@/views/chat/components/chat/chatUserItem.vue';
import ChatMessageItem from '@/views/chat/components/chat/chatMessageItem.vue';
import FriendsDialog from '@/views/chat/components/user/friendsDialog.vue';
import ForwardDialog from '@/views/chat/components/chat/forwardDialog.vue';
import ApplicationDialog from '@/views/chat/components/user/applicationDialog.vue';
import orderDialog from '@/views/chat/components/user/order.vue';
import MessageUser_header from '@/views/chat/components/chat/messageUser_header.vue';
import SelectMembersDialog from '@/views/chat/components/group/setting/selectMembersDialog.vue';
import OperateDialog from '@/views/chat/components/chat/operateDialog.vue';
import AddFriendsDialog from '@/views/chat/components/user/addFriendsDialog.vue';
import SearchInput from '@/views/chat/components/chat/searchInput.vue';
import Dropdown from '@/views/chat/components/common/dropdown.vue';
import { CommandEnum } from '@/views/chat/enum/command';
import { sendWSPush } from '@/utils/websocket';
import { copyValue } from '@/utils/util';
import { MessageEnum } from '@/views/chat/enum/message';
import VueEmit from '@/utils/VueEmit';

export default {
	name: 'chat-view',
	components: {
		ChatSendBox,
		ChatUserItem,
		ChatMessageItem,
		FriendsDialog,
		ForwardDialog,
		ApplicationDialog,
		MessageUser_header,
		SelectMembersDialog,
		OperateDialog,
		AddFriendsDialog,
		SearchInput,
		Dropdown,
		orderDialog,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			friendRelated: [
				{ name: '通讯录', icon: require('@/assets/images/chat/Calendar.png') },
				{
					name: '加好友',
					icon: require('@/assets/images/chat/Profile.png'),
				},
				{
					name: '建群聊',
					icon: require('@/assets/images/chat/Chat.png'),
				},
			],
			chatUserMsgActive: {}, //选中的外列表信息
			messagesList: [], //消息聊天记录列表
			messagePages: {
				pageNum: 1,
				pageSize: 15,
				total: 0,
			},
			chatActiveIndex: 0,
			chatMsgLoading: false,
			localId: '',
			switchGroup: {},
			imgLoadNum: 0, // 图片已经加载数量
			imgTotal: 0, // 图片总数
			onMessage: false, // 判断是否是推送过来的消息
			imDetails: {}, //IM详情信息
			isShowChatTriggered: false, // 用于记录事件是否被触发
			userList: [], //群成员
			type: false,
			itemInfo: {},
			searchInput: '', //搜索框的值
			addFriendGroupList: [
				//添加功能
				{ title: '加好友', id: 1, event: 'addFriend' },
				{ title: '创建群聊', id: 2, event: 'createGroup' },
			],
			chatUserActives: false, //记录右键状态
			setchatUserItem: [], //控制弹窗口
			isImDetails: false, //聊天室加载中效果
			isImMsgList: false,
			userJoinGroups: [], //用户加入的群聊
			stateList: {
				Edit: '草稿',
				WaitPay: '待支付',
				WaitApprove: '待审核',
				WaitSplit: '待拆单',
				Pass: '已通过  ',
				Cancel: '已取消',
				WaitSplitPass: '已通过且是未拆单状态',
				SplitPass: '已通过且是拆单状态',
				Refuse: '审核拒绝',
				ReceiveTask: '已接单',
				Execute: '执行中',
				WaitApproveSettle: '结算申核中',
				WaitSettle: '待结算',
				OverSettle: '已结算',
				Error: '商单异常',
				Delete: '删除',
				WaitReceive: '待接单',
				OffShelf: '下架',
				OnShelf: '上架',
				Over: '已终止',
				CompelOff: '强制下架',
			},
			chatRoomID: '',
			isTaskChat: false,
		};
	},
	watch: {
		communicateData: {
			handler(newVal, oldVal) {
				if (!this.isTaskChat) this.isTaskChat = !!newVal;
				if (this.$store.state.communicateData) {
					this.$emit('activeChat');
					this.chatUserMsgActive = this.$store.state.communicateData;
					this.chatUserItemClick(this.$store.state.communicateData);
					this.$store.commit('setCommunicate', null);
				}
			},
			deep: true,
		},
		chatUserMsg: {
			handler(newVal, oldVal) {
				let unreadNum = 0;
				if (newVal.length > oldVal.length) {
					let arr = [];
					newVal.map((item, index) => {
						unreadNum += item.unreadCount;
						if (!item.asTopTime) {
							arr.push(item);
						}
					});
				} else {
					newVal.map((item) => {
						unreadNum += item.unreadCount;
						if (item.id == this.chatUserMsgActive.id)
							this.chatUserMsgActive = item;
					});
				}
				//设置未读数
				this.$store.commit('setUnReadNum', unreadNum);
			},
			deep: true, // 添加深度监听
		},
		message(val) {
			try {
				let msgInfo = JSON.parse(val);
				this.itemInfo = msgInfo;
				const messageReceiverId = Number(msgInfo.receiver.id); //接收消息对方id
				const messageSenderId = Number(msgInfo.sender.id); //接收消息发送人 id
				const receiverId = Number(
					this.switchGroup.id || this.chatUserMsgActive.receiver.id,
				); //当前聊天室对方id
				const isGroup = [
					'group',
					'groupTask',
					'groupForm',
					'groupFormCustom',
					'groupTaskCustom',
					'groupFromGeneral',
				].includes(msgInfo.sessionType); //判断是否为群聊
				const isSingle = ['single'].includes(msgInfo.sessionType); //判断当前聊天是否为私聊
				const isCommand = ['command'].includes(msgInfo.messageType); //判断当前是否为命令消息
				//若消息发送人id 与 聊天室对方id 匹配 则调用已读
				if (isSingle && messageSenderId === receiverId && this.show)
					this.chatMessageRead();
				if (isGroup && messageReceiverId === receiverId && this.show)
					this.chatMessageRead();
				setTimeout(() => {
					this.$store.dispatch('getUserMessageList');
				}, 500);

				if (msgInfo.sessionType !== this.chatUserMsgActive.sessionType) return;
				//若聊天室为群聊: 任何消息 对方id 都为群id, 消息id 与 聊天室id不匹配则一定不为当前聊天室消息
				if (isGroup && messageReceiverId !== receiverId) return;
				//若聊天室为私聊
				if (isSingle) {
					//若为本人发送 并且 对方消息id 与 聊天室消息id 不匹配则不为当前聊天室消息
					if (
						messageSenderId === this.userInfo.id &&
						messageReceiverId !== receiverId
					)
						return;

					//若不为本人发送 并且 消息发送id 与 当前聊天室对方id 不匹配则不为当前聊天室消息
					if (
						messageSenderId !== this.userInfo.id &&
						messageSenderId !== receiverId
					)
						return;
				}
				//文件消息单独处理
				if (msgInfo.messageType === 'file') return this.handleFileMsg(msgInfo);
				//收到命令禁止互相添加好友更新群内状态
				if (isCommand) {
					// 删除消息不处理 执行删除
					if ([CommandEnum.deleteChatMessage].includes(msgInfo.commandType))
						return this.deleteOperate(msgInfo.localId);
					// 常规命令执行
					this.handleCommandMsg(msgInfo);
				}
				this.onMessage = true;
				msgInfo.unreadCount = 0;

				if (
					msgInfo.mediaCall?.type == 'call' &&
					msgInfo.sessionType == 'single'
				)
					return;
				this.messagesList.push(msgInfo);
				if (msgInfo.isRecall) {
					this.messagesList = this.messagesList.map((item) => {
						if (item.id == id) {
							return { ...item, type: 'recall' };
						}
						return item;
					});
					this.messagesList = this.messagesList.filter(
						(item) => item.id !== msgInfo.id,
					);
				}
				this.$nextTick(() => {
					setTimeout(() => {
						let div = document.getElementById(`id_-1`);
						if (div) {
							div.scrollIntoView({ behavior: 'smooth' });
						}
					}, 200);
				});
				// setTimeout(() => {
				// 	this.$store.dispatch('getUserMessageList');
				// }, 500);
			} catch (error) {
				console.log('错误..', error);
			}
		},
	},
	computed: {
		//立即沟通数据
		communicateData() {
			return this.$store.getters.communicateDataInfo;
		},
		//计算气泡列表
		setchatUserList() {
			return this.chatUserMsgActive?.asTopTime
				? [
						{ name: '取消置顶', id: 1 },
						{ name: '删除会话', id: 2 },
				  ]
				: [
						{ name: '置顶会话', id: 1 },
						{ name: '删除会话', id: 2 },
				  ];
		},
		userInfo() {
			return this.$store.state.userInfo;
		},
		groupRole() {
			if (this.$store.state.userInfo.id == this.imDetails.userId) {
				return 'group_leader';
			} else if (
				this.userList.findIndex(
					(v) =>
						v.permissionType === 'group_admin' && v.userId === this.userInfo.id,
				) !== -1
			) {
				return 'group_admin';
			} else {
				return null;
			}
		},
		message() {
			return this.$store.state.message;
		},
		chatUserMsg() {
			if (
				this.$store.state.messageList.every(
					(item) => item.receiver.id != this.chatUserMsgActive.receiver?.id,
				)
			) {
				this.chatUserMsgActive = {};
			}
			return this.$store.state.messageList;
		},
		//判断自己是否在群内
		isMyinGroup() {
			let inGroup = false;
			if (this.userList.length == 0) return true;
			this.userList.map((item) => {
				if (item.userId == this.$store.state.userInfo.id) inGroup = true;
			});
			return inGroup;
		},
		// 判断是否出现顶部商单详情
		isShowOrderDetails() {
			let flag = false;
			// 发单方
			if (this.imDetails.specialRole == 'taskPublish') {
				if (
					['groupFormCustom', 'groupTaskCustom', 'groupForm'].includes(
						this.imDetails?.groupType,
					)
				) {
					flag = true;
				}
			}

			// 接单方
			if (this.imDetails.specialRole == 'taskRece') {
				if (!['groupForm'].includes(this.imDetails?.groupType)) {
					flag = true;
				}
			}

			return flag;
		}
	},
	created() {
		if (this.chatUserMsg.length > 0 && !this.isShowChatTriggered) {
			this.isShowChatTriggered = true;
			this.chatUserItemClick(this.chatUserMsg[0]);
		}
      this.getUserJoinGroup();
	},
	provide() {
		return {
			chatUserItemClick: this.chatUserItemClick,
			switchGroupChat: this.switchGroupChat,
			getImDetailsInfo: this.getImDetailsInfo,
			toUserGroupRoomFn: this.toUserGroupRoom,
		};
	},

	mounted() {
		VueEmit.$on('showChat', (res) => {
			this.chatUserItemClick(res);
		});
	},
	methods: {
		/**
		 * 更新聊天室数据
		 */
		handleUpdateData() {
			console.log('update');
			this.getImDetailsInfo();
			setTimeout(() => {
				this.$store.dispatch('getUserMessageList');
			}, 500);
		},
		//通过 userid 或者 群id跳转对应的聊天室
		toUserGroupRoom(item) {
			console.log('item: ======', item);
			if (item.id) {
				// 过滤出来 在外列表的val
				let newVal, valIndex;
				this.chatUserMsg.map((val, index) => {
					if (
						val.receiver.id == item.id &&
						!item.friendId &&
						val.sessionType != 'single'
					) {
						newVal = val;
						valIndex = index;
					}
					if (
						val.receiver.id == item.friendId &&
						item.friendId &&
						val.sessionType == 'single'
					) {
						newVal = val;
						valIndex = index;
					}
				});
				//存在于外列表 直接打开
				if (newVal) {
					this.chatUserMsgActive = newVal;
					this.chatUserItemClick(newVal);
					//添加定位聊天位置
					this.$nextTick(() => {
						let childHeight =
							this.$refs.chatListItemsRef.children[0].offsetHeight;
						this.$refs.chatListItemsRef.scrollTop = valIndex * childHeight;
						console.log('位聊天位置', valIndex, valIndex * childHeight);
					});
				} else {
					// 不存在添加一个 虚拟对象至外列表
					if (!item.friendId && item.sessionType != 'single') {
						this.$store.dispatch('creatToGroupRoom', item);
					} else {
						this.$store.dispatch('creatToSingleRoom', item);
					}
					this.chatUserItemClick(this.chatUserMsg[0]);
					// this.toUserGroupRoom(item)
					console.log(
						'该聊天室不存在外消息列表..创建新的',
						this.chatUserMsg[0],
					);
				}
			} else {
				console.log('主键id不存在');
			}
		},
		// 点击搜索框 跳转聊天室
		handleToChatRoom(item) {
			console.log('item: ------', item);
			console.log('点跳转聊天室', item);
			this.toUserGroupRoom(item);
		},
		// 右键点击左侧聊天
		chatUserItemRightClick(item, index) {
			console.log('右键点击左侧聊天..父元素emit', item, index);
		},
		//点击唤起弹窗
		addFriendGroupPop(e) {
			let list = this.addFriendGroupList;
			const top = e.pageY - e.offsetY + e.target.offsetHeight + 'px';
			const left = e.pageX - e.offsetX + e.target.offsetWidth + 'px';
			let module = {
				top: `calc(${top} + 15px)`,
				left: `calc(${left} - 84px)`,
				height: '120px',
				width: '120px',
			};
			this.$refs.dropdownRef.open(module, list);
		},
		//点击操作 加好友 建群聊
		handleDropDown(type, data) {
			switch (type) {
				case 'addFriend':
					this.$refs.AddFriendsDialogRef.open();
					break;
				case 'createGroup':
					this.$refs.SelectMembersDialogRef.open({
						title: '发起群聊',
					});
					break;
				case 'copy':
					copyValue(data.body.content);
					break;
				case 'forward':
					this.handleForward(data);
					break;
				case 'delete':
					sendWSPush({
						localId: data.localId,
						receiverId: data.receiver.id,
						sessionType: data.sessionType,
						commandType: CommandEnum.deleteChatMessage,
						messageType: MessageEnum.command,
						chatId: data.chatId,
						content: data.body.content,
					});
					break;
				case 'recall':
					let isMe = data.sender.id === this.userInfo.id;
					let content = isMe
						? `${data.sender.name} 撤回了一条消息`
						: `${this.userInfo.nickName} 撤回了 ${data.sender.name} 一条消息`;
					sendWSPush({
						localId: data.localId,
						receiverId: data.receiver.id,
						sessionType: data.sessionType,
						commandType: CommandEnum.recall,
						messageType: MessageEnum.command,
						content,
						chatId: data.chatId,
					});
					break;
			}
		},
		changeRecall(id) {
			this.messagesList = this.messagesList.filter(
				(item) => item.localId !== id,
			);
		},
		deleteOperate(id) {
			this.messagesList = this.messagesList.map((item) => {
				if (item.localId == id) {
					return { ...item, type: 'delete' };
				}
				return item;
			});
			this.messagesList = this.messagesList.filter(
				(item) => item.localId !== id,
			);
		},

		forwardOperate(val) {
			this.handleForward(val);
		},
		handleContextmenu(model, list) {
			const width = '120px';
			const height = list.length * 40 + 'px';
			const top = model.y;
			const left = model.x;
			console.log('右键转发', height, list.length);
			this.$refs.dropdownRef.open(
				{
					width,
					height,
					top,
					left,
				},
				list,
				model.message,
			);
		},
		//关闭消息弹框
		messageItemClick(item, idx) {
			//   this.messagesList.map((v, i) => {
			//     const ref = "operateDialogRef" + v.id;
			//     this.$refs[ref][0]?.close();
			//   });
			// this.messagesList.map((v, i) => {
			// 	const ref = 'operateDialogRef' + v.id;
			// 	this.$refs[ref][0]?.close();
			// });
		},

		//开启
		/**
		 * 操作命令消息
		 * @param message
		 */
		handleCommandMsg(message) {
			console.log(message, 'message');
			switch (message.commandType) {
				case CommandEnum.groupAddFriend: //处理群禁止相互添加好友
					this.imDetails.addFriend = !this.imDetails.addFriend;
					break;
				case CommandEnum.groupAdministrator: //群成员权限变化刷新成员列表
					this.getGroupMember();
					break;
				case CommandEnum.groupForbidTime: //禁言时间段开启关闭
					this.getImDetailsInfo();
					break;
				case CommandEnum.groupForbid: //全部禁言
					this.getImDetailsInfo();
					break;
				case CommandEnum.groupMemberForbid: //单独禁言某群员
					this.getImDetailsInfo();
					break;
				case CommandEnum.recall: //撤回消息
					this.changeRecall(message.localId);
					break;
				case CommandEnum.systemRecall: //系统撤回消息
					this.changeRecall(message.localId);
					break;
				case CommandEnum.kickGroup: //被动退出群聊刷新详情
					this.getImDetailsInfo();
					break;
				case CommandEnum.groupGreet: //邀请入群刷新
					this.getImDetailsInfo();
					break;
			}
		},
		/**
		 * 操作转发消息
		 * @param model
		 */
		handleForward(model) {
			console.log(model);
			this.$refs.forwardDialogRef.open(model);
		},
		/**
		 * 单独处理文件消息
		 * @param model
		 */
		handleFileMsg(model) {
			const index = this.messagesList.findIndex((v) => {
				return (
					v.file &&
					v.file.fileName === model.file.fileName &&
					v.file.fileSize === model.file.fileSize &&
					v.file.status === 'loading'
				);
			});
			if (index !== -1) {
				this.messagesList.splice(index, 1, {
					...model,
					file: { ...model.file, status: 'download' },
				});
			} else {
				this.onMessage = true;
				this.messagesList.push({
					...model,
					file: { ...model.file, status: 'download' },
				});
			}
			this.$nextTick(() => {
				setTimeout(() => {
					let div = document.getElementById(`id_-1`);
					if (div) {
						div.scrollIntoView({ behavior: 'smooth' });
					}
				}, 200);
			});
			this.chatMessageRead();
		},
		/**
		 * 滚动到聊天室底部
		 */
		scrollerBottom() {
			this.$nextTick(() => {
				const chatBoxDom = document.querySelector('.im-chat-box');
				chatBoxDom.scrollTop = chatBoxDom.scrollHeight;
			});
		},
		/**
		 * 操作加载中消息
		 * @param list
		 */
		handleLoadingMsg(list) {
			this.messagesList.push(...list);
			this.scrollerBottom();
		},
		async getGroupMember() {
			const res = await this.$http.imGroupUserAll({
				groupId: this.chatUserMsgActive.receiver.id,
			});
			this.userList = res.data;
		},
		/**
		 * 操作前往添加好友
		 */
		handleOpenApplyFriend() {
			this.$refs.ApplicationDialogRef.open(this.imDetails.friendId);
		},
		// 设置消息为已读
		async chatMessageRead() {
			console.log(this.chatUserMsgActive.receiver);
			if (this.chatUserMsgActive.receiver) {
				await this.$http.chatMessageRead(
					this.chatUserMsgActive.sessionType,
					this.chatUserMsgActive.receiver?.id,
				);
			}
		},
		// 切换关联群
		switchGroupChat(val) {
			console.log(val, '切换群聊');
			this.messagePages.pageNum = 1;
			this.chatRoomID = val;
			this.toUserGroupRoom(val);
			// this.switchGroup = val;
			// console.log(this.switchGroup.id);
			// this.getMessageList(this.switchGroup.id);
		},

		// 点击左侧用户
		async chatUserItemClick(val, index) {
			console.log('val: ', val);
			this.isImMsgList = false;
			this.isImDetails = false;
			this.chatUserMsgActive = {};
			this.chatActiveIndex = index;
			this.messagePages.pageNum = 1;
			this.chatUserMsgActive = val;
			this.switchGroup = {};
			this.getImDetailsInfo();
			this.isActiveData = true;
			// this.chatMessageRead();
			if (this.chatUserMsgActive.receiver) {
				await this.$http.chatMessageRead(
					this.chatUserMsgActive.sessionType,
					this.chatUserMsgActive.receiver?.id,
				);
			}

			let messageList = this.$store.state.messageList;
			messageList.forEach((item) => {
				if (
					item.receiver.id == val.receiver.id &&
					item.sessionType == val.sessionType
				) {
					item.unreadCount = 0;
				}
			});
			this.getMessageList();
			this.$nextTick(() => {
				this.$refs.messageUser_header.messageSetPopoverBox = false;
				if (val.shareId) {
					this.$refs.chatSendBox.showCard(val.shareId, val.shareType);
				} else {
					this.$refs.chatSendBox.showContentCard = false;
				}
			});
		},

		// 获取用户或群详情
		async getImDetailsInfo() {
			let res;
			if (
				[
					'group',
					'groupTask',
					'groupTaskCustom',
					'groupForm',
					'groupFormCustom',
					'groupFromGeneral',
				].includes(this.chatUserMsgActive.sessionType)
			) {
				res = await this.$http.imGroupDetails(
					this.chatUserMsgActive.receiver.id,
				);
				this.getGroupMember();
			} else {
				res = await this.$http.appFriendsDetails(
					this.chatUserMsgActive.receiver.id,
				);
			}
			this.isImDetails = true;
			this.imDetails = {};
			let timeArr = [];
			if (res.data?.timeList && res.data?.timeList.length)
				timeArr = [...res.data?.timeList];

			this.imDetails = {
				...res.data,
				...this.chatUserMsgActive,
				userName: res.data?.userName,
				timeList: timeArr,
				copyUserId: res.data?.userId,
			};

			let unreadnum = 0;
			this.chatUserMsg.map((item) => {
				unreadnum += item.unreadCount;
			});
			this.$store.commit('setUnReadNum', unreadnum);
			console.log(
				'点击切换后的数据',
				this.imDetails,
				res.data,
				this.chatUserMsgActive,
			);
		},

		// 获取对话消息列表
		async getMessageList(id) {
			this.chatMsgLoading = true;
			const res = await this.$http.imChatMessageInnerList({
				sessionType: this.chatUserMsgActive.sessionType,
				receiverId: this.chatUserMsgActive.receiver.id,
				pageNum: this.messagePages.pageNum,
				pageSize: this.messagePages.pageSize,
			});

			if (this.messagePages.pageNum == 1) {
				this.messagesList = res.data.reverse();
			} else {
				this.messagesList.unshift(...res.data.reverse());
				//过滤掉输入后 分页拿到重复数据
				this.messagesList = this.messagesList.filter((item, index, self) => {
					return index === self.findIndex((t) => t.id === item.id);
				});
			}
			this.messagePages.total = res.total;
			this.$nextTick(() => {
				if (this.messagePages.total) {
					let id = '';
					if (this.messagePages.pageNum == 1) {
						this.imgLoadNum = 0;
						this.imgTotal = res.data.filter((item) => {
							return item.messageType == 'photo';
						}).length;
						id = 'id_-1';
					} else {
						id = `id_${res.data[res.data.length - 1].chatId}`;
					}
					setTimeout(() => {
						let div = document.getElementById(id);
						if (div) {
							div.scrollIntoView({ behavior: 'auto' });
						}
						this.chatMsgLoading = false;
					}, 50);
				} else {
					this.chatMsgLoading = false;
				}
			});
			this.isImMsgList = true;

			this.messagesList = this.messagesList.filter(
				(item) => item.mediaType != 'call',
			);
		},

		// 图片加载完成
		imageLoad() {
			if (this.onMessage) {
				let div = document.getElementById('id_-1');
				if (div) {
					div.scrollIntoView({ behavior: 'smooth' });
				}
			} else {
				this.imgLoadNum++;
				if (
					this.imgLoadNum == this.imgTotal &&
					this.messagePages.pageNum == 1
				) {
					let div = document.getElementById('id_-1');
					if (div) {
						div.scrollIntoView({ behavior: 'smooth' });
					}
				}
			}
		},

		// 滚动事件
		onScroll(e) {
			if (e.target.scrollTop < 50) {
				if (this.messagePages.total > this.messagesList.length) {
					if (!this.chatMsgLoading) {
						this.messagePages.pageNum++;
						this.getMessageList();
					}
				}
			}
		},

		// 发送失败重发
		errorClick(val) {
			let i = this.messagesList.findIndex(
				(item) => item.localId == val.localId,
			);
			delete val.localId;
			this.messagesList.splice(i, 1);
			this.sendMessage(val);
		},

		// 发送消息
		sendMessage(val) {
			this.localId = this.$util.getUuid();
			sendWSPush({
				localId: this.localId,
				// receiverId: this.switchGroup.id || this.chatUserMsgActive.receiver.id,
				receiverId: this.chatUserMsgActive.receiver.id,
				sessionType: this.chatUserMsgActive.sessionType,
				...val,
			});
		},
		//获取 用户加入过的群聊
		async getUserJoinGroup() {
			let res = await this.$http.imGroupJoinList();
			if (res.code === 200) this.userJoinGroups = res.data;
		},

		// 商单打开
		handleOpenOrder() {
			this.$refs.orderDialogRef.open(this.imDetails);
		},
	},
};
</script>

<style lang="scss" scoped>
.setchatUserItem {
	width: 120px;
	height: 120px;
	border-radius: 10px;
	opacity: 1;
	background: #ffffff;
	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 65px;
	left: 70px;
	box-sizing: border-box;
	padding: 15px 26px;
	z-index: 1001;

	.setchatUserItems {
		width: 100%;
		padding: 16px 0;
		text-align: left;
		font-size: 14px;
		color: #222222;
	}

	.setchatUserItems:hover {
		color: $theme-color;
	}
}

.chat-box .el-header.messageHeader {
	height: 79px !important;
	border-bottom: 1px solid #e7e7e7;
	padding: 0;
}

.chat-view {
	width: calc(100% - 60px);
	height: 100%;
	background-color: #ffffff;
	border-radius: 40px;
	// border-radius: 40px 32px 32px 40px;
	overflow: hidden;

	.im-chat-box-topmsg {
		text-align: center;
		margin-bottom: 20px;
		color: #999;
		font-size: 12px;
		padding-top: 20px;
		line-height: 16px;
	}

	.chat-page {
		height: 100%;

		.strange-view {
			width: 100%;
			height: 42px;
			min-height: 42px;
			background-color: rgba(255, 108, 39, 0.1);
			display: flex;
			justify-content: space-between;
			padding: 0 30px 0 30px;
			align-items: center;
			box-sizing: border-box;

			.title {
				font-size: 14px;
				color: #3d3d3d;
			}

			.btn {
				width: 66px;
				height: 28px;
				background-color: $theme-color;
				text-align: center;
				color: #fff;
				line-height: 28px;
				font-size: 12px;
				cursor: pointer;
				border-radius: 4px;
			}
		}

		.chat-list-box {
			width: 260px;
			border-right: 1px solid #e7e7e7;
			display: flex;
			flex-direction: column;

			.friendRelated {
				height: 79px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #e7e7e7;
				position: sticky;
				top: 0;
				background-color: #fff;
				z-index: 10;
				box-sizing: border-box;
				padding: 0 20px;
				position: relative;

				.addFriendGroupPop {
					width: 120px;
					height: 120px;
					border-radius: 10px;
					opacity: 1;
					background: #ffffff;
					box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
					position: absolute;
					top: 80px;
					right: 16px;
					box-sizing: border-box;
					padding: 15px 26px;
					z-index: 1001;

					.addFriendGroupPop_item {
						width: 100%;
						padding: 16px 0;
						text-align: left;
						font-size: 14px;
						color: #222222;
					}

					.addFriendGroupPop_item:hover {
						color: $theme-color;
					}
				}

				.searchInputBox {
					width: 158px;
				}

				.search-right-add {
					width: 42px;
					height: 42px;
					border-radius: 8px;
					opacity: 1;
					box-sizing: border-box;
					border: 1px solid #dee0e3;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.friendRelated_item {
					color: #999999;
					text-align: center;
					cursor: pointer;
					margin-left: 18px;

					.friendRelated_item_name {
						margin-top: 2px;
						font-size: 12px;
					}

					&:hover {
						opacity: 0.8;
					}
				}
			}

			.chat-list-items {
				flex: 1;
				display: flex;
				flex-direction: column;
				overflow-y: auto;
				padding: 20px 10px 10px;
				/* 隐藏滚动条 - webkit浏览器 */
				&::-webkit-scrollbar {
					display: none;
				}

				/* 隐藏滚动条 - Firefox */
				scrollbar-width: none;

				/* 隐藏滚动条 - IE/Edge */
				-ms-overflow-style: none;

				div {
					position: relative;
				}
			}
		}

		.chat-box {
			display: flex;
			background-color: #fff;

			.content-box {
				height: 100%;

				.im-chat-main {
					padding-bottom: 0;

					.im-chat-box {
						box-sizing: border-box;
						height: 100%;
						overflow-y: auto;
						padding: 0 30px;

						&::-webkit-scrollbar {
							width: 3px;
						}

						.messageLoading {
							text-align: center;
							margin-top: 20px;
							color: #999;
							font-size: 12px;
						}
					}
				}
			}

			.im-chat-footer {
				padding: 0 0 20px 0;
				box-sizing: border-box;
				margin-top: 10px;
				height: auto !important;
			}
		}

		.chatMessageEmpty {
			margin: 220px auto;
			text-align: center;

			.el-image {
				width: 246.68px;
				height: 96px;
			}

			.empty_text {
				color: #999999;
				font-size: 14px;
				line-height: 22px;
				margin-top: 7px;
			}
		}
	}
}
</style>
