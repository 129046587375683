<template>
	<dialogBox
		appendToBody
		width="500px"
		v-model="dialogVisible"
		class="BindFriendsDialog"
		title="进群绑定好友关系"
	>
		<template #header_title>
			<div class="bindFriends_title">
				进群绑定好友关系
				<el-link href="/inviteFriendsIndex" :underline="false">
					<el-button type="primary">邀请好友活动</el-button></el-link
				>
			</div>
		</template>
		<div class="topSwitch">
			<div class="switchBox">
				<div class="switchBoxTitle">进群绑定好友关系</div>
				<el-switch
					v-model="details.bindUser"
					active-color="#13ce66"
					@change="bindUserChange"
				>
				</el-switch>
			</div>
			<div class="subtitle">
				开启后，新用户通过群链接进入群聊，分享群链接的人可获得新用户的收益
			</div>
		</div>
		<div class="list">
			<div class="listTitle">邀请数据</div>
			<div class="rowItem" v-for="item of list" :key="item.id">
				<div class="userData">
					<el-image
						class="avatar"
						:src="item.userAvatar"
						fit="cover"
					></el-image>
					{{ item.userName }}
				</div>
				<div class="number">
					邀请人数：
					<span class="num cur" @click="showMembers(item)">{{
						item.inviteCount
					}}</span>
				</div>
			</div>
			<CommonEmpty v-if="!list.length" height="300px"></CommonEmpty>
		</div>
		<GroupMembers ref="GroupMembersRef"></GroupMembers>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import GroupMembers from "@/views/chat/components/group/setting/groupMembers.vue";

export default {
	components: { dialogBox, GroupMembers },
	data() {
		return {
			dialogVisible: false,
			details: {},
			list: [],
		};
	},
	methods: {
		open(details) {
			this.dialogVisible = true;
			this.details = details;
			this.getList();
		},

		// 获取列表
		async getList() {
			const res = await this.$http.imGroupInviteUserList({
				channelType: 'bindUser',
				groupId: this.details.id,
			});
			this.list = res.data;
		},

		// 显示人员列表
		showMembers(val) {
			if (!val.inviteList) return;
			this.$refs.GroupMembersRef.open(val.inviteList);
		},

		// 切换开关
		bindUserChange() {
			this.$http.editImGroup({
				...this.details,
			});
		},
	},
};
</script>
<style lang="scss">
.BindFriendsDialog {
	.bindFriends_title {
		.el-button {
			border-radius: 205px;
			background: linear-gradient(
				90deg,
				#ff6c27 -1%,
				#ff8f27 29%,
				#fcb54a 52%,
				#fe702c 100%
			);
			border: 0 solid #000;
			height: 26px;
			font-size: 12px;
			padding: 0 16px;
			margin-left: 4px;
			@extend .cur;
		}
	}
	.topSwitch {
		border: 1px dashed #ff7d11;
		background: #fbf2ed;
		border-radius: 2px;
		height: 64px;
		margin-top: 12px;
		padding: 10px 12px 15px;
		box-sizing: border-box;
		.switchBox {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.switchBoxTitle {
				font-size: 14px;
				color: #ff602b;
				line-height: 10px;
			}
		}
		.subtitle {
			color: #b1b1b1;
			font-size: 12px;
			margin-top: 8px;
			line-height: 12px;
		}
	}
	.list {
		max-height: 500px;
		margin-top: 14px;
		overflow: auto;
		.listTitle {
			color: #333333;
			font-size: 14px;
			font-weight: 500;
		}
		.rowItem {
			padding: 10px 0;
			border-bottom: 1px solid #e7e7e7;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.userData {
				display: flex;
				align-items: center;
				color: #3d3d3d;
				font-size: 12px;
				.avatar {
					width: 30px;
					height: 30px;
					margin-right: 6px;
					border-radius: 50%;
				}
			}
			.number {
				font-size: 12px;
				color: #999999;
				.num {
					color: #ff6c27;
					font-weight: 500;
				}
			}
		}
	}
}
</style>
