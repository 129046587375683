//消息类型
export const MediaCallEnum = {
   call: "call", // 呼叫
   join: "join", // 加入房间 仅限于群通话
   leave: "leave", // 离开房间
   offer: "offer", // 发送offer
   answer: "answer", // 发送answer
   camera: "camera", // 切换摄像头 会发送一个offer 
   video: "video", // 开关摄像头
   ice: "ice", // 发送ice配置信息
   refuse: "refuse", // 拒绝通话请求
   cancel: "cancel", // 取消通话
   finish: "finish", // 结束通话
   busy: "busy", // 占线未接通
   timeout: "timeout",// 超时未接听 1分钟自动挂断
   state: "state",
}