import { pinyin } from 'pinyin-pro';

/**
 * 处理字符串首字符返回 首字符处理结果
 * @param str
 * @returns {string}
 */
function processString (str) {
   if (str?.length == 0) return '';

   const firstChar = str?.length > 0 ? str[0] : '';

   // 检查是否为中文字符
   if (/[\u4e00-\u9fa5]/.test(firstChar)) {
      // 如果是中文字符，获取其拼音首字母并转为大写
      const firstPinyin = pinyin(firstChar, { pattern: 'first', toneType: 'none' });
      return firstPinyin.replace(' ', '').toUpperCase();
   } else if (/[a-zA-Z]/.test(firstChar)) {
      // 检查是否为英文字符，并直接返回首字母的大写形式
      return firstChar.toUpperCase();
   } else {
      // 如果是其他字符（符号或数字），返回#
      return '#';
   }
}

export default {
   processString
}