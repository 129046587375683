import { render, staticRenderFns } from "./labelView.vue?vue&type=template&id=184d7a41&scoped=true&"
import script from "./labelView.vue?vue&type=script&lang=js&"
export * from "./labelView.vue?vue&type=script&lang=js&"
import style0 from "./labelView.vue?vue&type=style&index=0&id=184d7a41&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "184d7a41",
  null
  
)

export default component.exports