var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-view"},[_c('el-container',{staticClass:"chat-page"},[_c('div',{staticClass:"chat-list-box"},[_c('div',{staticClass:"friendRelated"},[_c('div',{staticClass:"searchInputBox"},[_c('search-input',{on:{"handleToChatRoom":_vm.handleToChatRoom}})],1),_c('div',{staticClass:"search-right-add",on:{"click":_vm.addFriendGroupPop}},[_c('i',{staticClass:"icon-sohu-jiahao iconfont",staticStyle:{"font-weight":"bold"}})])]),_c('div',{ref:"chatListItemsRef",staticClass:"chat-list-items"},_vm._l((_vm.chatUserMsg),function(item,index){return _c('div',{key:item.id},[_c('chatUserItem',{attrs:{"item":item,"active":_vm.chatUserMsgActive,"chatUserActives":_vm.chatUserActives,"userList":_vm.userList},on:{"chatUserItemClick":function($event){return _vm.chatUserItemClick(item, index)},"chatUserItemRightClick":function($event){return _vm.chatUserItemRightClick(item, index)}}})],1)}),0)]),(Object.values(_vm.chatUserMsgActive).some((item) => !!item))?_c('el-container',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.isImMsgList),expression:"!isImMsgList"}],staticClass:"chat-box"},[_c('el-container',[_c('el-header',{staticClass:"messageHeader"},[_c('messageUser_header',{ref:"messageUser_header",attrs:{"chatUserMsgActive":_vm.chatUserMsgActive,"chatUserMsg":_vm.chatUserMsg,"switchGroup":_vm.switchGroup,"imDetails":_vm.imDetails,"isMyinGroup":_vm.isMyinGroup},on:{"update":_vm.handleUpdateData,"close":function($event){return _vm.$emit('close')}}})],1),(
						!_vm.imDetails.meFriend &&
						_vm.imDetails.sessionType === 'single' &&
						_vm.userInfo.id != _vm.imDetails.friendId &&
						!_vm.isTaskChat
					)?_c('div',{staticClass:"strange-view"},[_c('div',{staticClass:"title"},[_vm._v("对方不是你的好友")]),_c('div',{staticClass:"btn",on:{"click":_vm.handleOpenApplyFriend}},[_vm._v("加好友")])]):_vm._e(),(_vm.isShowOrderDetails)?_c('div',{staticClass:"lh-17px h-41xp text-14px flex items-center justify-between py-12px px-21px sticky top-41px z-10 bg-#ffeee6 relative"},[(
							['groupFormCustom', 'groupTaskCustom'].includes(
								this.imDetails?.groupType,
							)
						)?_c('div',{staticClass:"text-#222222"},[_vm._v(" 【"+_vm._s(_vm.stateList[_vm.imDetails?.planVo?.state])+"】已达标人数 "),_c('span',{staticClass:"text-#FF6C27"},[_vm._v(_vm._s(_vm.imDetails?.planVo?.standNum))]),_vm._v(" 人 ")]):_vm._e(),(['groupForm'].includes(_vm.imDetails?.groupType))?_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"max-w-150px truncate inline-block"},[_vm._v(" "+_vm._s(_vm.imDetails?.planVo?.taskTitle)+" ")]),_c('div',{staticClass:"w-1px bg-#222222 h-14px mx-10px"}),_c('div',{},[_vm._v(" 商单进度： "),_c('span',{staticClass:"text-#FF6C27"},[_vm._v(" "+_vm._s(_vm.imDetails?.planVo?.standNum)+" ")]),_vm._v(" / "+_vm._s(_vm.imDetails?.planVo?.deliveryStandard)+" ")])]):_vm._e(),_c('div',{staticClass:"cursor-pointer",on:{"click":_vm.handleOpenOrder}},[_vm._v(" 查看 "),_c('i',{staticClass:"el-icon-arrow-right text-#222222"})])]):_vm._e(),_c('el-main',{staticStyle:{"padding":"0"}},[_c('el-container',{staticClass:"content-box"},[_c('el-main',{staticClass:"im-chat-main",attrs:{"id":"el-main"}},[_c('div',{staticClass:"im-chat-box",on:{"scroll":_vm.onScroll}},[_c('ul',[(
											(_vm.chatMsgLoading ||
												_vm.messagePages.total == _vm.messagesList.length) &&
											_vm.messagePages.pageNum > 1
										)?_c('div',{staticClass:"messageLoading"},[_vm._v(" "+_vm._s(_vm.messagePages.total > _vm.messagesList.length ? '加载中...' : '没有更多消息了')+" ")]):_vm._e(),(_vm.imDetails.groupNotice)?_c('div',{staticClass:"im-chat-box-topmsg"},_vm._l((_vm.imDetails.groupNotice.split(
												'\n',
											)),function(text,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(text)+" ")])}),0):_vm._e(),_vm._l((_vm.messagesList),function(item,idx){return _c('li',{key:item.chatId,staticStyle:{"position":"relative"},attrs:{"id":`id_${item.chatId}`}},[_c('chatMessageItem',{attrs:{"prev":idx == 0 ? null : _vm.messagesList[idx - 1],"item":item,"detail":_vm.imDetails,"role":_vm.groupRole,"type":item.type,"userList":_vm.userList,"isMyinGroup":_vm.isMyinGroup,"isTaskChat":_vm.isTaskChat},on:{"imageLoad":_vm.imageLoad,"errorClick":_vm.errorClick,"forward":_vm.handleForward,"contextmenu":_vm.handleContextmenu,"messageItemClick":_vm.messageItemClick}})],1)}),_c('li',{attrs:{"id":"id_-1"}})],2)])]),_c('el-footer',{staticClass:"im-chat-footer"},[_c('chatSendBox',{ref:"chatSendBox",attrs:{"chatUserMsgActive":_vm.chatUserMsgActive,"switchGroup":_vm.switchGroup,"imDetails":_vm.imDetails,"user-list":_vm.userList,"isMyinGroup":_vm.isMyinGroup},on:{"sendMessage":_vm.sendMessage,"loadingFileMsg":_vm.handleLoadingMsg}})],1)],1)],1)],1)],1):_c('div',{staticClass:"chatMessageEmpty"},[_c('el-image',{attrs:{"src":require('@/assets/images/chat/messageEmpty.png')}}),_c('div',{staticClass:"empty_text"},[_vm._v("暂无私信")])],1),_c('friendsDialog',{ref:"friendsDialogRef"}),_c('addFriendsDialog',{ref:"AddFriendsDialogRef"}),_c('applicationDialog',{ref:"ApplicationDialogRef"}),_c('selectMembersDialog',{ref:"SelectMembersDialogRef"}),_c('forwardDialog',{ref:"forwardDialogRef",attrs:{"userJoinGroups":_vm.userJoinGroups,"list":_vm.chatUserMsg,"limit":9}}),_c('dropdown',{ref:"dropdownRef",on:{"handleEvent":_vm.handleDropDown}}),_c('orderDialog',{ref:"orderDialogRef",on:{"orderUpdata":_vm.getImDetailsInfo}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }