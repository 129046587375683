<template>
	<dialogBox
		appendToBody
		width="500px"
		v-model="dialogVisible"
		class="ChangeGroupName"
		@confirm="confirmClick"
	>
		<div slot="header_title">
			<div class="header-view">
				<div class="title">我在群里的昵称</div>
				<div class="subtile">昵称修改后，只会在此群内显示，群内成员都可以看见</div>
			</div>
		</div>
		<div class="setRow">
			<div>
				<el-avatar :src="userInfo.userAvatar" ></el-avatar>
			</div>
			<el-input placeholder="请输入在本群的昵称" :maxlength="24" :minlength="2" clearable v-model.trim="myGroupName" @input="handleInput"></el-input>
		</div>
		<div class="bottom-view">
			<div class="tip-view">请设置2-24个字符，不能使用@<>/等字符哦</div>
			<el-button type="primary" :disabled="isDisabled" @click="confirmClick">保存</el-button>
		</div>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
export default {
	components: { dialogBox },
	data() {
		return {
			dialogVisible: false,
			details: {},
			myGroupInfo:{},
			myGroupName: "",
			isDisabled:true
		};
	},
	computed:{
		userInfo(){
			return this.$store.state.userInfo;
		},
	},
	inject: ['getDetails', 'getImDetailsInfo'],
	methods: {
		handleInput(event) {
			if (event === "" || event.length < 2) {
				this.isDisabled = true;
			} else {
				this.isDisabled = false;
			}
		},

		open(id) {
			this.dialogVisible = true;
			this.isDisabled = true;
			this.getAppFriendsDetails(id);
			this.getUserList(id)
		},

		// 详情
		async getAppFriendsDetails(id) {
			const res = await this.$http.imGroupDetails(id);
			this.details = res.data;
			console.log(res);
		},

		// 获取群成员列表
		async getUserList(id) {
			const res = await this.$http.imGroupUserAll({
				groupId: id,
			});
			this.myGroupInfo = res.data.find(v => v.userId === this.userInfo.id)
			if (this.myGroupInfo.groupUserNickName) {
				this.myGroupName = this.myGroupInfo.groupUserNickName;
			} else if (this.myGroupInfo.nickName) {
				this.myGroupName = this.myGroupInfo.nickName;
			} else {
				this.myGroupName = this.myGroupInfo.userName;
			}
		},

		// 确定按钮
		async confirmClick() {
			const res = await this.$http.updateImGroupMyName({
				groupId:this.details.id,
				userId:this.userInfo.id,
				groupUserNickName:this.myGroupName
			});
			if (res.code == 200) {
				this.dialogVisible = false;
				this.getImDetailsInfo();
				this.getDetails(this.details.id);
				this.$store.dispatch('getUserMessageList');
			}
		},
	},
};
</script>
<style lang="scss">
.ChangeGroupName {
	.header-view {
		.title {
			color: #333333;
			font-size: 24px;
			line-height: 36px;
		}
		.subtile {
			color: #999999;
			line-height: 22px;
			margin-top: 10px;
			font-size: 16px;
		}
	}
  .setRow {
		width: 100%;
		height: 81px;
    margin-top: 47px;
		border-top: 1px solid #E7E7E7;
		border-bottom: 1px solid #E7E7E7;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		.el-avatar {
			width: 40px;
			height: 40px;
			border-radius: 8px;
		}
		.el-input .el-input__inner  {
			border: none !important;
		}
  }
	.bottom-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 55px;
		.tip-view {
			width: 100%;
			margin-top: 17px;
			color: rgba(0,0,0,0.26);
			font-size: 12px;
			line-height: 18px;
		}
		.el-button--primary {
			background-color: $theme-color;
			color: #FFF;
			border: 1px solid $theme-color;
			&:hover {
				opacity: 0.8;
			}
			&:active {
				border: 1px solid $theme-color;
				outline: 0;
			}
		}
    .el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover {
		//   background-color: #F3F3F3;
		//   color: #999999;
		//   border: 1px solid #F3F3F3;
			opacity: 0.4;
		}
	}
}
</style>
