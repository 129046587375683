<template>
	<div class="messageUser_header">
		<div class="messageUser_header_userName line-1">
			{{ chatTitle }}
			<span v-if="IMdetails.groupUserCount || imDetails.groupUserNum"
				>（{{
					switchGroup.groupUserNum ||
					chatUserMsgActive.groupUserCount ||
					imDetails.groupUserNum
				}}）</span
			>
		</div>
		<div class="rightSet" v-if="isMyinGroup">
			<!-- 关联群 -->
			<div
				class="messageUser_header_set"
				@click="switchGroupChat"
				v-if="chatUserMsgActive.groupType == 'groupForm'"
			>
				<i class="icon-sohu-qiehuanqunzu"></i>
			</div>
			<div
				class="messageUser_header_set"
				@click="setGroupOrSingle"
				v-if="showMoreBtn"
			>
				<i
					class="icon-sohu-ef-gengduo-chuizhi"
					style="transform: rotate(90deg)"
				></i>
			</div>
			<div class="close-btn" @click="$emit('close')">
				<i class="icon-sohu-guanbidanchuang iconfont"></i>
			</div>
		</div>
		<!-- 设置好友群聊弹窗 -->
		<transition name="el-zoom-in-top">
			<div class="popoverBox-pop" v-if="messageSetPopoverBox">
				<div
					class="search-user-group-close"
					@click.prevent.stop="messageSetPopoverBox = false"
					@contextmenu.prevent.stop="messageSetPopoverBox = false"
				></div>
				<div class="popoverBox">
					<!-- imDetails -->
					<div class="popoverBox_user">
						<el-avatar
							class="popoverBox_user_img"
							icon="el-icon-user-solid"
							size="large"
							shape="circle"
							:src="imDetails.userAvatar"
							fit="fill"
						>
						</el-avatar>
						<div class="popoverBox_user_name">
							<h4>
								{{
									imDetails.alias || imDetails.nickName || imDetails.userName
								}}
							</h4>
							<p v-if="imDetails.nickName">
								昵称:<b>{{ imDetails.nickName }}</b>
							</p>
							<span v-if="imDetails.userName">ID:{{ imDetails.userName }}</span>
						</div>
					</div>
					<div class="popoverBox_item" @click="setRemarksClick">
						<span>备注和标签</span>
						<i class="icon-sohu-xiayiyeqianjinchakangengduo"></i>
					</div>
					<div class="popoverBox_item" @click="othersHOme">
						<span>个人空间</span>
						<i class="icon-sohu-xiayiyeqianjinchakangengduo"></i>
					</div>
					<div class="popoverBox_item">
						<span>置顶聊天</span>
						<el-switch
							class="popoverBox_item_switch"
							active-color="#13ce66"
							v-model="asTop"
							@change="changeAsTop"
						>
						</el-switch>
					</div>
				</div>
			</div>
		</transition>

		<!-- 群设置弹窗 -->
		<GroupSettingsDialog
			:chatUserMsgActive="chatUserMsgActive"
			:chatUserMsg="chatUserMsg"
			ref="GroupSettingsDialogRef"
			:nweChatUserMsgActive="nweChatUserMsgActive"
		></GroupSettingsDialog>

		<!-- 名片弹窗 -->
		<businessCardDialog ref="businessCardDialogRef"></businessCardDialog>

		<!-- 切换群聊 -->
		<SwitchGroupChatDialog
			ref="SwitchGroupChatDialogRef"
			:chatUserMsg="chatUserMsg"
		></SwitchGroupChatDialog>
		<!-- 备注和标签设置弹窗 -->
		<setRemarksDialog
			ref="setRemarksDialogRef"
			@update="$emit('update')"
		></setRemarksDialog>
	</div>
</template>
<script>
import GroupSettingsDialog from '@/views/chat/components/group/groupSettingsDialog.vue';
import BusinessCardDialog from '@/views/chat/components/user/businessCardDialog.vue';
import SwitchGroupChatDialog from '@/views/chat/components/group/setting/switchGroupChatDialog.vue';
import SetRemarksDialog from '@/views/chat/components/user/setRemarksDialog.vue';

export default {
	components: {
		GroupSettingsDialog,
		BusinessCardDialog,
		SwitchGroupChatDialog,
		SetRemarksDialog,
	},
	props: [
		'chatUserMsgActive',
		'chatUserMsg',
		'switchGroup',
		'imDetails',
		'isMyinGroup',
	], //im会话消息对象
	data() {
		return {
			messageSetPopoverBox: false,
			messageSetList: [
				{ name: '置顶' },
				{ name: '设置' },
				// { name: '静音' }
			],
			IMdetails: {},
			asTop: false,
			nweChatUserMsgActive: {},
		};
	},
	computed: {
		chatTitle() {
			return (
				this.switchGroup.name ||
				this.imDetails.alias ||
				this.chatUserMsgActive.alias ||
				this.chatUserMsgActive.userName ||
				this.chatUserMsgActive.receiver.name ||
				this.imDetails.name ||
				this.imDetails.nickName
			);
		},
		showMoreBtn() {
			if (this.chatUserMsgActive.sessionType === 'single') {
				return (
					this.userInfo.id != this.imDetails.friendId &&
					(this.imDetails.meFriend || this.imDetails.himFriend)
				);
			} else {
				console.log('this.imDetails:======= ', this.imDetails);
				return (
					this.userInfo.id != this.imDetails.friendId &&
					this.imDetails.groupType !== 'groupFromGeneral'
				);
			}
		},
		userInfo() {
			return this.$store.state.userInfo;
		},
	},
	watch: {
		chatUserMsgActive: {
			handler(val) {
				console.log('val:这是群设置传的数据 ', val);
				if (!val) return;
				//监听选中会话消息
				this.IMdetails = val;
				//区分单聊群聊是否有置顶
				if (val.asTopTime) {
					this.asTop = true;
				} else {
					this.asTop = false;
				}
			},
			deep: true,
			immediate: true,
		},
		chatUserMsg: {
			handler(val) {
				val.forEach((v) => {
					if (v.id == this.chatUserMsgActive.id) {
						this.nweChatUserMsgActive = v;
					}
				});
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		// 他的空间
		othersHOme() {
			let link = this.$router.resolve({
				path: '/othersHome',
				query: {
					userId: this.IMdetails.receiver.id,
				},
			});
			window.open(link.href, '_blank');
		},
		//切换置顶与取消置顶
		async changeAsTop() {
			let res = await this.$http.chatMessageAsTop(
				this.chatUserMsgActive.sessionType,
				this.chatUserMsgActive.receiver.id,
			);
			if (res.code == 200) {
				if (this.asTop) {
					this.$message.success('置顶成功');
				} else {
					this.$message.success('取消置顶');
				}
				this.$store.dispatch('getUserMessageList');
			}
		},
		//打开设置弹窗
		setGroupOrSingle() {
			if (this.imDetails.sessionType == 'single') {
				this.messageSetPopoverBox = !this.messageSetPopoverBox;
			} else {
				this.$refs.GroupSettingsDialogRef.open(
					this.switchGroup.id || this.chatUserMsgActive.receiver.id,
				);
			}
		},
		// 设置备注
		setRemarksClick() {
			this.$refs.setRemarksDialogRef.open(this.imDetails.friendId);
		},
		// 设置操作
		setClick({ name }) {
			this.messageSetPopoverBox = false;
			switch (name) {
				case '置顶':
					this.$confirmDialog({
						content: '是否确定置顶该聊天？',
						confirmText: '确认置顶',
						beforeClose: async (action, close) => {
							if (action == 'confirm') {
								const res = await this.$http.chatMessageAsTop(
									this.chatUserMsgActive.sessionType,
									this.chatUserMsgActive.receiver.id,
								);
								if (res.code == 200) {
									this.$message.success('置顶成功');
									this.asTop = true;
									close();
									this.$store.dispatch('getUserMessageList');
								}
							}
						},
					});
					break;
				case '已置顶':
					this.$confirmDialog({
						content: '是否确定取消置顶？',
						confirmText: '确认取消',
						beforeClose: async (action, close) => {
							if (action == 'confirm') {
								const res = await this.$http.chatMessageAsTop(
									this.chatUserMsgActive.sessionType,
									this.chatUserMsgActive.receiver.id,
								);
								if (res.code == 200) {
									this.$message.success('取消成功');
									this.asTop = false;
									close();
									this.$store.dispatch('getUserMessageList');
								}
							}
						},
					});
					break;
				case '设置':
					if (this.chatUserMsgActive.sessionType == 'single') {
						this.$refs.businessCardDialogRef.open(
							this.switchGroup.id || this.chatUserMsgActive.receiver.id,
						);
					} else {
						this.$refs.GroupSettingsDialogRef.open(
							this.switchGroup.id || this.chatUserMsgActive.receiver.id,
						);
					}
					break;
				case '删除':
					this.$confirmDialog({
						content: '是否确定删除好友？',
						confirmText: '确认删除',
						beforeClose: async (action, close) => {
							if (action == 'confirm') {
								const res = await this.$http.friendsDelete(
									this.chatUserMsgActive.receiver.id,
								);
								if (res.code == 200) {
									this.$message.success('删除成功');
									close();
									this.$store.dispatch('getUserMessageList');
								}
							}
						},
					});
					break;
				case '退群':
					this.$confirmDialog({
						content: '是否退出群聊？',
						confirmText: '确认退出',
						beforeClose: async (action, close) => {
							if (action == 'confirm') {
								if (this.chatUserMsgActive.asTopTime) {
									const topres = await this.$http.chatMessageAsTop(
										this.chatUserMsgActive.sessionType,
										this.chatUserMsgActive.receiver.id,
									);
									if (topres.code != 200) {
										this.$message.error(topres.data);
										close();
									}
								}
								const res = await this.$http.imGroupUserRemoveGroup(
									this.chatUserMsgActive.receiver.id,
								);
								if (res.code == 200) {
									this.$message.success('退出成功');
									this.$store.dispatch('getUserMessageList');
								}
								close();
							}
						},
					});
					break;
			}
		},

		// 切换群聊
		switchGroupChat() {
			this.$refs.SwitchGroupChatDialogRef.open(this.chatUserMsgActive);
		},
	},
};
</script>
<style lang="scss" scoped>
.search-user-group-close {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 1;
	left: 0;
}

.messageUser_header {
	background: #ffffff;
	height: 78px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 0 24px 0 30px;
	position: relative;

	.messageUser_header_userName {
		color: $text-color;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
	}

	.rightSet {
		display: flex;
		justify-content: space-between;
		.close-btn {
			width: 40px;
			height: 40px;
			text-align: center;
			margin-left: 10px;
			cursor: pointer;
			i {
				font-size: 28px;
				line-height: 40px;
			}
			&:hover {
				background: #f3f3f3;
			}
		}

		.messageUser_header_set {
			font-size: 24px;
			cursor: pointer;
			height: 40px;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 8px;

			&:hover {
				background: #f3f3f3;
			}
		}
	}
	.popoverBox-pop {
		position: absolute;
		z-index: 1001;
		right: 21px;
		top: 66px;
	}

	.popoverBox {
		position: relative;
		z-index: 2;
		width: 280px;
		height: 300px;
		border-radius: 10px;
		box-sizing: border-box;
		padding: 30px 30px 0 30px;
		opacity: 1;
		background: #ffffff;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);

		.popoverBox_item {
			width: 100%;
			height: 54px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-top: 1px solid #f2f2f2;
			font-size: 14px;
			color: #333333;

			.popoverBox_item_switch {
				width: 38px;
				height: 28px;
			}
		}

		.popoverBox_user {
			width: 100%;
			margin-bottom: 30px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.popoverBox_user_img {
				width: 66px;
				height: 66px;
				border-radius: 8px;
				overflow: hidden;
			}

			.popoverBox_user_name {
				width: 140px;
				height: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				text-align: left;

				h4 {
					width: 100%;
					color: #222222;
					font-size: 16px;
					font-weight: 500;
					margin-bottom: 8px;
					white-space: nowrap;
					overflow: hidden;
					/* 隐藏超出容器的内容 */
					text-overflow: ellipsis;
					/* 当文本溢出时显示省略号 */
				}

				p {
					width: 100%;
					color: #999999;
					font-size: 12px;
					margin-bottom: 8px;
					white-space: nowrap;
					overflow: hidden;
					/* 隐藏超出容器的内容 */
					text-overflow: ellipsis;

					/* 当文本溢出时显示省略号 */
					b {
						color: #222222;
					}
				}

				span {
					color: #999999;
					font-size: 12px;
					white-space: nowrap;
					overflow: hidden;
					/* 隐藏超出容器的内容 */
					text-overflow: ellipsis;
					/* 当文本溢出时显示省略号 */
				}
			}
		}
	}
}
</style>
