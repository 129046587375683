<template>
	<div
		:class="[{ active: active.receiver?.id == item.receiver?.id }, 'chat-item']"
		@click="chatUserItemClick"
		@contextmenu.prevent.stop="chatUserItemRightClick"
	>
		<el-avatar class="user-item-avatar" :src="item.userAvatar"></el-avatar>
		<div class="chat-right">
			<div class="chat-name">
				<div class="chat-name-text line-1">{{ item.userName }}</div>
				<div class="chat-time-text">{{ showTime }}</div>
			</div>
			<div class="chat-content">
				<div class="chat-at-text line-1" v-html="content"></div>
				<div class="dot" v-if="item.unreadCount">
					{{ item.unreadCount <= 99 ? item.unreadCount : '99+' }}
				</div>
			</div>
		</div>
		<!-- 置顶图标 -->
		<i class="icon-sohu-zhiding iconfont asTop-icon" v-if="item?.asTopTime"></i>
		<!-- 弹窗制作 -->
		<!-- <transition name="el-zoom-in-top">
			<div class="setchatUserItem" v-if="setchatUserItem">
			<div
				v-for="setitem of setchatUserList"
				:key="setitem.id"
				class="setchatUserItems"
				@click.prevent.stop="setchatUserClick(setitem,item)"
			>
				{{ setitem.name }}
			</div>
			</div>
		</transition> -->
		<dropdown @handleEvent="handleDropDown" ref="dropdownRef" />
	</div>
</template>
<script>
import dropdown from '../common/dropdown.vue';
import groupUserAvatar from '../group/setting/groupUserAvatar.vue';
export default {
	props: ['item', 'active', 'chatUserActives', 'userList'],
	components: { dropdown, groupUserAvatar },
	data() {
		return {
			// setchatUserList:[
			// 	{name:'置顶会话',id:1},
			// 	{name:'删除会话',id:2}
			// ],
			setchatUserItem: false,
			disMyName: '',
			asTop: false,
		};
	},
	computed: {
		showTime() {
			return this.formatMessageTime(this.item.updateTime);
		},
		content() {
			switch (this.item.messageType) {
				case 'text':
					console.log(this.item.atIds,"文字消息");
					if (this.item.atIds != '') {
						//如果存在进行替换
						if (this.item.atIds == 0 && this.item.unreadCount > 0) {
							//0为@所有人 有未读数才高亮
							return this.item.body.content.replace(new RegExp('@所有人', "g"),`<b style="color:#F94040">[@所有人]</b>${this.item.body.content.replace(/@所有人/g, '')}`)
							// return `<b style="color:#F94040">[有人@我]</b>${this.item.body.content}`;
						} else if (
							this.item.atIds == this.userInfo.id &&
							this.item.unreadCount > 0
						) {
							//被他人艾特
							// return this.item.body.content.replace(new RegExp(`@${this.groupUserInfo}`, "g"), `<b style="color:#F94040">@${this.groupUserInfo}</b>`)
							return `<b style="color:#F94040">[有人@我]</b>${this.item.body.content}`;
						} else {
							return this.item.body.content;
						}
					} else {
						return this.item.body.content;
					}
				// return this.item.body.content.replace(new RegExp('@所有人', "g"),'<b style="color:#F94040">@所有人</b>')
				// return this.item.body.content.replace(new RegExp(`@${this.groupUserInfo}`, "g"),`<b style="color:#F94040">@${this.groupUserInfo}</b>`)
				case 'photo':
					return '[图片]';
				case 'video':
					return '[视频]';
				case 'voice':
					return `[语音]${this.item.body.duration}"`;
				case 'share':
					return '[分享]';
				case 'file':
					return '[文件]';
				case 'msg':
					return this.item.body.content;
				case 'command':
					return this.item.body.content;
			}
		},
		setchatUserList() {
			console.log('this.item: ', this.item);
			return this.item?.asTopTime
				? [
						{ title: '取消置顶', id: 1, event: 'handleAsTop' },
						{ title: '删除会话', id: 2, event: 'handleDeleteMsg' },
				  ]
				: [
						{ title: '置顶会话', id: 1, event: 'handleAsTop' },
						{ title: '删除会话', id: 2, event: 'handleDeleteMsg' },
				  ];
		},
		userInfo() {
			return this.$store.state.userInfo;
		},
		groupUserInfo() {
			let obj = this.userList.filter(
				(item) => item.userId == this.$store.state.userInfo.id,
			);
			return obj[0].alias || obj[0].nickName || obj[0].userName;
		},
	},
	methods: {
		formatMessageTime(messageTime) {
			if (!messageTime) return '';
			const now = new Date();
			const messageDate = new Date(messageTime);

			const isToday = now.toDateString() === messageDate.toDateString();
			const isYesterday =
				new Date(now.setDate(now.getDate() - 1)).toDateString() ===
				messageDate.toDateString();
			const isLast7Days =
				now - messageDate < 7 * 24 * 60 * 60 * 1000 && !isToday && !isYesterday;
			const isThisYear = now.getFullYear() === messageDate.getFullYear();

			if (isToday) {
				return messageDate.toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
				});
			} else if (isYesterday) {
				return `昨天${messageDate.toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
				})}`;
			} else if (isLast7Days) {
				const days = [
					'星期日',
					'星期一',
					'星期二',
					'星期三',
					'星期四',
					'星期五',
					'星期六',
				];
				return days[messageDate.getDay()];
			} else if (isThisYear) {
				return `${(messageDate.getMonth() + 1)
					.toString()
					.padStart(2, '0')}/${messageDate
					.getDate()
					.toString()
					.padStart(2, '0')}`;
			} else {
				return `${messageDate.getFullYear().toString().slice(-2)}/${(
					messageDate.getMonth() + 1
				)
					.toString()
					.padStart(2, '0')}/${messageDate
					.getDate()
					.toString()
					.padStart(2, '0')}`;
			}
		},
		// 点击事件抛出
		async handleDropDown(event) {
			console.log(
				'点击事件抛出',
				event,
				this.item,
				this.active,
				this.chatUserActives,
			);
			switch (event) {
				case 'handleAsTop':
					let res = await this.$http.chatMessageAsTop(
						this.item.sessionType,
						this.item.receiver.id,
					);
					if (res.code == 200) {
						if (this.item.asTopTime) {
							this.$message.info('取消置顶');
							this.asTop = false;
						} else {
							this.$message.success('置顶成功');
							this.asTop = true;
						}
						this.$store.dispatch('getUserMessageList');
					}
					this.$emit('chatUserItemRightClick', this.item);
					break;
				case 'handleDeleteMsg':
					console.log('点击删除会话事件抛出');
					let contentStr;
					if (this.item.sessionType == 'single')
						contentStr = `确定删除与好友 " ${this.item.userName} " 会话记录吗？`;
					if (this.item.sessionType != 'single')
						contentStr = `确定删除群 " ${this.item.userName} " 会话记录吗？`;
					this.$confirmDialog({
						content: contentStr,
						title: '删除会话',
						confirmText: '确认',
						beforeClose: async (action, close) => {
							if (action == 'confirm') {
								let res = await this.$http.deleteImChatMessageOuterList(
									this.item.id,
								);
								if (res.code == 200) {
									console.log('删除..', this.item);
									this.$message.success('删除成功');
									this.$store.dispatch('getUserMessageList');
								}
								close();
							}
						},
					});
					break;
			}
		},
		// 点击左侧聊天
		chatUserItemClick() {
			this.setchatUserItem = false;
			this.$emit('chatUserItemClick', this.item);
		},
		// 右键点击左侧聊天
		chatUserItemRightClick(e) {
			let list = this.setchatUserList;
			let module = {
				top: e.pageY + 'px',
				left: e.pageX + 'px',
				height: '120px',
				width: '120px',
			};
			this.$refs.dropdownRef.open(module, list);
			this.$emit('chatUserItemRightClick', this.item);
		},
	},
};
</script>
<style lang="scss" scoped>
.user-item-avatar {
	width: 44px;
	height: 44px;
	border-radius: 8px;
}

.chat-item {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	// height: 60px;
	width: 240px;
	height: 74px;
	border-radius: 8px;
	padding: 15px 10px;
	position: relative;
	margin: 0 auto;

	.setchatUserItem {
		width: 120px;
		height: 120px;
		border-radius: 10px;
		opacity: 1;
		background: #ffffff;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
		position: absolute;
		top: 65px;
		left: 70px;
		box-sizing: border-box;
		padding: 15px 26px;
		z-index: 1001;

		.setchatUserItems {
			width: 100%;
			padding: 16px 0;
			text-align: left;
			font-size: 14px;
			color: #222222;
		}

		.setchatUserItems:hover {
			color: $theme-color;
		}
	}

	.el-avatar {
		width: 44px;
		height: 44px;
		margin-right: 10px;
	}

	.chat-right {
		position: relative;
		flex: 1;
		height: 44px;

		.chat-name {
			display: flex;
			justify-content: space-between;
			color: #011627;
			font-weight: 500;
			font-size: 16px;
			line-height: 22px;
			height: 22px;
			margin-bottom: 4px;

			.chat-name-text {
				max-width: 110px;
				color: $text-color;
			}

			.chat-time-text {
				color: #bbb;
				font-size: 12px;
				line-height: 16px;
				white-space: nowrap;
			}
		}

		.chat-content {
			color: #999;
			font-size: 12px;
			line-height: 18px;

			.chat-at-text {
				max-width: 150px;
			}

			.dot {
				position: absolute;
				top: -4px;
				left: -2px;
				background: #f94040;
				height: 14px;
				font-size: 12px;
				color: #ffffff;
				height: 18px;
				display: flex;
				align-items: center;
				justify-content: center;
				transform: translateX(-100%);
				padding: 0 5px;
				border-radius: 9px;
			}
		}
	}

	.asTop-icon {
		position: absolute;
		bottom: 14px;
		right: 15px;
		color: $theme-color;
	}

	&.asTop {
		// background-color: #eeeeee;
	}

	&.active {
		// background-color: #e7e7e7;
		background: #f3f3f3;
	}
}
</style>
