<template>
	<el-container class="chat-page">
		<div class="main">
			<div class="chat-menu">
				<!-- <el-image
					class="avatar"
					:src="userInfo.userAvatar"
					@click="messageSetPopoverBox = !messageSetPopoverBox"
				></el-image> -->
				<div class="menu-list">
					<div
						class="item"
						v-for="(item, index) in menuList"
						:key="item.type"
						@click="handleTabChange(item)"
					>
						<img
							:style="{
								width: item.width,
								height: item.height,
							}"
							:src="tabActive == item.type ? item.icon2 : item.icon"
							alt=""
						/>
						<div
							:style="{
								color: tabActive == item.type ? '#FF6C27' : '#999999',
							}"
							class="text-12px mt-6px"
						>
							{{ item.text }}
						</div>
						<div class="dot-icon" v-if="item.count > 0">
							{{ item.count > 99 ? '99+' : item.count }}
						</div>
					</div>
				</div>
				<!--				<div class="system-menu">-->
				<!--					<div-->
				<!--						class="item"-->
				<!--						v-for="(item, index) in systemList"-->
				<!--						:key="item.type"-->
				<!--						@click="handleSystemMenu($event, item)"-->
				<!--					>-->
				<!--						<i :class="[item.icon]"></i>-->
				<!--					</div>-->
				<!--				</div>-->
				<!-- 打开个人简介 -->
				<transition name="el-zoom-left">
					<div v-if="messageSetPopoverBox">
						<div
							class="popoverBox-close"
							@click="messageSetPopoverBox = false"
							@contextmenu.prevent.stop="messageSetPopoverBox = false"
						></div>
						<div class="popoverBox">
							<!-- imDetails -->
							<div class="popoverBox_user">
								<el-avatar
									class="popoverBox_user_img"
									icon="el-icon-user-solid"
									size="large"
									shape="circle"
									:src="userInfo.userAvatar"
									fit="fill"
								>
								</el-avatar>
								<div class="popoverBox_user_name">
									<div class="popoverBox_flex" @click="openEditUser">
										<h4>
											{{
												userInfo.alias || userInfo.nickName || userInfo.userName
											}}
										</h4>
										<i class="icon-sohu-xiugai iconfont"></i>
									</div>

									<span v-if="userInfo.userName"
										>ID:{{ userInfo.userName }}</span
									>
								</div>
							</div>
							<p>
								{{ userInfo.remark || '暂无简介' }}
							</p>
							<div class="popoverBox_item">
								服务器ID：<b>{{ tenantId }}</b>
							</div>
							<div class="popoverBox_item">
								手机号：<b>{{
									userInfo.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
								}}</b>
							</div>
						</div>
					</div>
				</transition>
			</div>
			<template>
				<chat-view
					v-show="tabActive === 'message'"
					ref="chatViewRef"
					@close="$emit('close')"
					@activeChat="handleTabToChat"
					:show="isChatShow"
				/>
				<friend-view
					v-show="tabActive === 'friend'"
					ref="friendViewRef"
					@toChat="handleToChat"
					@updateRead="handleFriendRead"
					@close="$emit('close')"
				/>
			</template>
		</div>
	</el-container>
</template>
<script>
import ChatView from '@/views/chat/views/chat/index.vue';
import FriendView from '@/views/chat/views/friend/index.vue';
import addressBook from '@/assets/images/addressBook.png';
import addressBook2 from '@/assets/images/addressBook2.png';
import message from '@/assets/images/message.png';
import message2 from '@/assets/images/message2.png';

export default {
	components: {
		ChatView,
		FriendView,
	},
	data() {
		return {
			menuList: [
				{
					icon: message,
					icon2: message2,
					type: 'message',
					count: 0,
					text: '消息',
					width: '28px',
					height: '25.2px',
				},
				{
					icon: addressBook,
					icon2: addressBook2,
					type: 'friend',
					count: 0,
					text: '通讯录',
					width: '24.75px',
					height: '27px',
				},
			],
			systemList: [
				{
					icon: 'icon-sohu-shezhi1',
					type: 'setting',
				},
			],
			tabActive: 'message',
			messageSetPopoverBox: false,
			advertisementList: [],
			showAvertisement: true,
			tenantId: process.env.VUE_APP_TENANT,
		};
	},
	created() {
		console.log('获取到userInfo.....', this.userInfo);
		// this.getAdvertise();
	},
	mounted() {
		this.$nextTick(() => {
			console.log('调取跳转房间.....');
			// this.$refs.chatViewRef.toUserGroupRoom({id: 489});
		});
	},
	watch: {
		dotNum: {
			handler(val) {
				this.menuList.find((v) => v.type === 'message').count = val;
			},
			deep: true,
			immediate: true,
		},
	},
	computed: {
		isChatShow() {
			return this.$store.getters.isChatShow;
		},
		isLogin() {
			return this.$store.getters.isLogin;
		},
		userInfo() {
			return localStorage.getItem('userInfo')?.id
				? localStorage.getItem('userInfo')
				: this.$store.state.userInfo;
		},
		dotNum() {
			//返回为读消息数 arr
			return this.$store.state.unReadNum;
		},
	},
	methods: {
		/**
		 * 操作前往聊天界面
		 */
		handleTabToChat() {
			this.tabActive = 'message';
		},
		/**
		 * 操作好友未读数
		 * @param model
		 */
		handleFriendRead(model) {
			this.menuList.find((v) => v.type === 'friend').count = model;
		},
		// async getAdvertise() {
		// 	const res = await this.$http.getAdvertiseList();
		// 	if (res.code === 200) {
		// 		this.advertisementList = res.data;
		// 	}
		// },
		/**
		 * 复制操作
		 */
		handleCopy() {
			var textArea = document.createElement('textarea');
			// 防止在页面上显示textarea
			textArea.style.position = 'fixed';
			textArea.style.left = '-9999px';
			textArea.style.top = '-9999px';
			textArea.value = process.env.VUE_APP_TENANT;
			document.body.appendChild(textArea);
			textArea.select();
			try {
				var successful = document.execCommand('copy');
				this.$message.success('复制成功');
			} catch (err) {
				console.log('Fallback: Oops, unable to copy', err);
			}
			document.body.removeChild(textArea);
		},
		/**
		 * 前往聊天
		 * @param model
		 */
		handleToChat(model) {
			this.tabActive = 'message';
			this.$refs.chatViewRef.toUserGroupRoom({ ...model });
		},
		//编辑用户信息弹窗
		openEditUser() {
			this.$refs.editUserInfoDialogRef.init();
		},
		//打开用户个人信息弹窗
		openUserInfo() {},
		/**
		 * tab功能切换
		 * @param model
		 */
		handleTabChange(model) {
			if (this.tabActive === model.type) return;
			this.tabActive = model.type;
			if (this.tabActive === 'friend') this.$refs.friendViewRef.getList();
		},
		/**
		 * 操作系统菜单
		 * @param model
		 */
		handleDropdownEvent(model) {
			switch (model) {
				case 'userAgree':
					window.open(this.$store.state.agreeData['userAgreement'].link);
					break;
				case 'privacyAgree':
					window.open(this.$store.state.agreeData['privacyAgreement'].link);
					break;
				case 'suggestion':
					this.$refs.feedbackDialogRef.open();
					break;
				case 'editPwd':
					this.$refs.changePassWordDialogRef.open();
					break;
				case 'logOut':
					this.$confirmDialog({
						title: '',
						content: '确定退出登录？',
						confirmText: '确认',
						beforeClose: async (action, close) => {
							if (action === 'confirm') await this.$store.dispatch('logOut');
						},
					});
					break;
			}
		},
		/**
		 * 操作系统菜单
		 * @param event 事件对象
		 * @param model 数据
		 */
		handleSystemMenu(event, model) {
			switch (model.type) {
				case 'setting':
					const width = '120px';
					const height = '250px';
					const top =
						event.pageY - event.offsetY + event.target.offsetHeight + 'px';
					const left =
						event.pageX - event.offsetX + event.target.offsetWidth + 'px';
					const list = [
						{
							title: '用户协议',
							event: 'userAgree',
						},
						{
							title: '隐私协议',
							event: 'privacyAgree',
						},
						{
							title: '反馈与建议',
							event: 'suggestion',
						},
						{
							title: this.userInfo.hasUserPwd ? '修改密码' : '设置密码',
							event: 'editPwd',
						},
						{
							title: '退出登录',
							event: 'logOut',
						},
					];
					this.$refs.dropdownRef.open(
						{
							width,
							height,
							top: `calc(${top} - ${height})`,
							left: `calc(${left} + 1rem)`,
						},
						list,
					);
					break;
			}
		},
	},
};
</script>
<style lang="scss">
.chat-page {
	height: 100%;
	.main {
		// width: 1200px;
		width: 100%;
		height: 100%;
		//max-height: calc(100% - 134px);
		background-color: #f3f3f3;
		//border-radius: 40px;
		display: flex;
		align-items: center;
		padding: 10px 8px 10px 0;
		box-sizing: border-box;
		position: relative;

		.chat-menu {
			width: 60px;
			height: 100%;
			padding: 80px 0 48px 0;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			z-index: 20;

			.avatar {
				width: 44px;
				height: 44px;
				border-radius: 8px;
			}

			.menu-list {
				width: 100%;
				font-size: 32px;
				margin-top: -20px;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 40px;
				flex: 1;

				.item {
					cursor: pointer;
					position: relative;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					.dot-icon {
						font-size: 12px;
						color: #ffffff;
						height: 18px;
						opacity: 1;
						border-radius: 9px;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #fff;
						padding: 0 5px;
						background: #f94040;
						position: absolute;
						top: -6px;
						transform: translate(50%, 0);
						right: 0;
					}
				}
			}

			.system-menu {
				width: 100%;
				font-size: 32px;
				margin-top: 57.37px;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 54.63px;

				.item {
					cursor: pointer;

					color: #999;

					&:hover {
						color: #007fff;
					}
				}
			}
		}

		.advertisement {
			position: absolute;
			width: 147px;
			height: 340px;
			border-radius: 20px;
			left: -167px;
			bottom: 0;
			background-color: pink;
			overflow: hidden;

			.close {
				width: 20px;
				height: 20px;
				position: absolute;
				top: 10px;
				right: 10px;
				cursor: pointer;
				z-index: 999;
			}
		}
	}
}
</style>
