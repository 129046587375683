<template>
	<dialogBox
		appendToBody
		width="500px"
		v-model="dialogVisible"
		class="addLinkDialog"
		:title="title"
		showBottomBtn
		confirmText="保存"
		@confirm="confirmClick"
	>
		<el-form
			:rules="linkRules"
			:model="linkForm"
			class="linkForm"
			ref="linkFormRef"
		>
			<el-form-item label="渠道名称" prop="channelName">
				<commonInput v-model="linkForm.channelName" placeholder="请输入"></commonInput>
			</el-form-item>
			<el-form-item label="渠道备注" prop="channelNote">
				<commonInput
					:maxlength="200"
					v-model="linkForm.channelNote"
					placeholder="请输入"
				></commonInput>
			</el-form-item>
			<el-form-item label="备注图片" prop="channelImage">
				<UploadImage v-model="linkForm.channelImage" limit="5"></UploadImage>
			</el-form-item>
		</el-form>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import UploadImage from '@/components/UploadImage.vue';
import CommonInput from "@/views/chat/components/common/commonInput.vue";
export default {
	components: { dialogBox , UploadImage , CommonInput },
	data() {
		return {
			title: '新增渠道',
			dialogVisible: false,
			linkForm: {
				groupId: '',
				channelName: '',
				channelNote: '',
				channelImage: '',
			},
			linkRules: {
				channelName: [
					{ required: true, message: '请输入渠道名称', trigger: 'blur' },
				],
			},
		};
	},
	inject: ['getChannelList'],
	methods: {
		open(groupId, id) {
			this.dialogVisible = true;
			this.$nextTick(async () => {
				if (id) {
					this.title = '编辑渠道';
					const res = await this.$http.imGroupChannelId(id);
					this.linkForm = res.data;
				} else {
					this.title = '新增渠道';
					delete this.linkForm.id;
				}
				this.linkForm.groupId = groupId;
				this.$refs.linkFormRef.resetFields();
			});
		},

		// 保存按鈕
		confirmClick(close) {
			console.log(this.linkForm);
			this.$refs.linkFormRef.validate(async (valid) => {
				if (valid) {
					let res;
					if (this.linkForm.id) {
						res = await this.$http.editGroupChannel(this.linkForm);
					} else {
						res = await this.$http.addGroupChannel(this.linkForm);
					}
					console.log(res);
					if (res.code == 200) {
						this.getChannelList();
						this.$message.success('保存成功');
						close();
					} else {
						close(true);
					}
				}
			});
		},
	},
};
</script>
<style lang="scss">
.addLinkDialog {
	.linkForm {
		.el-form-item {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			.el-form-item__label {
				font-weight: 500;
				font-size: 14px;
				color: #333333;
			}
			.el-form-item__content {
				width: 100%;
			}
		}
	}
}
</style>
