<template>
	<dialog-box width="500px" appendToBody v-model="dialogVisible" :title="dialogTitle">
		<div class="handleLabel">
			<div class="label-name">
				<div class="title">标签名称</div>
				<el-input placeholder="请输入标签名称" v-model="labelName" :maxlength="15"></el-input>
			</div>
			<div class="select-client" @click="handleSelectFriend">
				<i class="icon-sohu-jiahao"></i>
				<div class="value">添加好友</div>
			</div>
			<div class="list-view" v-if="labelFriendList.some(v=>v.value.length)">
				<div class="item" :id="`item-${index}`" v-for="(item,index) in labelFriendList" :key="item.key"
						 v-if="item.value.length">
					<div class="key-view">
						<div class="key">{{ item.key }}</div>
					</div>
					<div class="value-view">
						<div
							class="value-item"
							v-for="(friend) in item.value"
							:key="friend.id"
						>
							<el-image class="avatar" :src="friend.userAvatar"></el-image>
							<div class="name">{{ handleNameFilter(friend) }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="empty-view" v-else>
				<el-image class="empty" :src="require('@/assets/images/empty/client-empty.png')"></el-image>
			</div>
			<div class="handle-view">
				<div class="btn cancel" @click="dialogVisible = false">取消</div>
				<div class="btn confirm" @click="handleConfirm">确认</div>
			</div>
		</div>
		<select-member ref="selectMemberRef" title="已选择" emptyTitle="全部好友已在该标签" :list="friendBook.filter(v=>v.id)"
									 @confirm="handleSelectUser" :isEmpty="true" />
	</dialog-box>
</template>

<script>
import DialogBox from "@/components/dialogBox.vue";
import pinyin from "@/utils/pinyin";
import SelectMember from "@/views/chat/components/common/selectMember.vue";
import { friendBaseList } from "@/views/chat/components/friend/data/friend";

export default {
	name: "handleLabel",
	components: { SelectMember, DialogBox },
	props: {
		friendList: {
			type: Array,
			default: () => [],
		},
	},
	watch: {
		friendList: {
			handler(val) {
				this.friendBook = [...val.filter(v => v.id)];
			},
			deep: true,
			immediate: true,
		},
		dialogVisible(val) {
			if (!val) {
				this.labelFriendList = [...friendBaseList()];
				this.friendBook = this.friendBook.map(v => ({ ...v, check: false }));
				this.selectList = [];
				this.labelName = "";
			}
		},
	},
	data() {
		return {
			id: null, //标签主键id
			dialogVisible: false,
			dialogTitle: "",
			labelFriendList: [...friendBaseList()], //标签下用户列表
			labelName: "", //标签名称
			dialogType: "", //弹窗类型
			friendBook: [], //所有好友数据 处理是否选中
			selectList: [], //选中数据
		};
	},
	methods: {
		open(model) {
			this.dialogType = model.type;
			switch (model.type) {
				case "add":
					this.dialogTitle = "新建标签";
					break;
				case "edit":
					this.dialogTitle = "编辑标签";
					break;
			}
			if (model.data && model.type === "edit") {
				this.id = model.data.id;
				this.labelName = model.data.name;
				this.selectList = [...model.data.list];
				this.handleSelectUser(model.data.list, false);
			}
			this.dialogVisible = true;
		},
		close() {
			this.dialogVisible = false;
		},
		/**
		 * 操作名称过滤
		 * @param model
		 */
		handleNameFilter(model) {
			return model.displayName || model.alias || model.nickName || model.userName;
		},
		/**
		 * 操作选择好友
		 */
		handleSelectFriend() {
			this.friendBook = [...this.friendList];
			this.friendBook = this.friendBook.map(v => ({
				...v,
				check: false,
				keyValue: pinyin.processString(v.displayName),
			})).sort((a, b) => {
				// 如果a的keyValue是'#'，那么它应该在b之后
				if (a.keyValue === "#") return 1;
				// 如果b的keyValue是'#'，那么它应该在a之后
				if (b.keyValue === "#") return -1;
				// 如果都不是'#'，那么按照正常的字典顺序排序
				return a.keyValue.localeCompare(b.keyValue);
			});
			this.friendBook.forEach((item, index) => {
				if (this.selectList.find(v => item.id === v.id)) {
					this.$set(this.friendBook, index, { ...item, check: true });
				}
			});
			this.$refs.selectMemberRef.open();
		},
		/**
		 * 操作选中用户
		 * @param list 好友列表数据
		 * @param flag 是否为操作数据
		 */
		handleSelectUser(list, flag = true) {
			this.labelFriendList = [...friendBaseList()];
			if (flag && this.$refs.selectMemberRef) this.selectList.splice(0);
			list.forEach(v => {
				const valueKey = pinyin.processString(v.alias || v.nickName || v.userName);
				const index = this.labelFriendList.findIndex(friend => friend.key === valueKey);
				this.labelFriendList[index].value.push({ ...v });
				if (flag && this.$refs.selectMemberRef) this.selectList.push({ ...v });
			});
			if (flag && this.$refs.selectMemberRef) {
				this.$refs.selectMemberRef.close();
			}
		},
		/**
		 * 操作确认新建
		 */
		handleConfirm() {
			if (!this.labelName) return this.$message.error("标签名称不能为空");
			// if (this.labelFriendList.every(v => v.value.length === 0)) return this.$message.error("请选择好友后再新建");
			const friendList = [];
			this.labelFriendList.forEach(v => {
				friendList.push(...v.value);
			});
			this.$emit("confirm", {
				id: this.id,
				type: this.dialogType,
				name: this.labelName,
				friendIds: friendList.map(v => v.id),
			});
			// this.labelName = "";
			this.labelFriendList = [...friendBaseList()];
		},
	},
};
</script>

<style lang="scss" scoped>
.handleLabel {
  width: 100%;
  max-height: 732px;
  display: flex;
  flex-direction: column;

  .label-name {
    margin-top: 23px;

    .title {
      font-size: 14px;
      color: #222;
      margin-bottom: 8px;
    }

    ::v-deep .el-input .el-input__inner {
      background-color: #F3F3F3;
      border: none;
    }
  }

  .select-client {
    width: 200px;
    height: 42px;
    min-height: 42px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: rgba(255, 108, 39, 0.1);
    color: $theme-color;
    font-size: 14px;
    cursor: pointer;

    i {
      font-size: 12px;
      margin-right: 5px;
    }
  }

  .list-view {
    flex: 1;
    height: 100%;
    overflow-y: auto;

    .item {
      width: 100%;
      height: fit-content;

      .key-view {
        padding: 0 30px;
        box-sizing: border-box;

        .key {
          width: 100%;
          height: 100%;
          line-height: 50px;
          font-size: 12px;
          color: #999;
          border-bottom: 1px solid #E7E7E7;
        }
      }

      .value-view {
        width: 100%;
        height: fit-content;
        padding: 0 10px;
        margin-top: 15px;
        box-sizing: border-box;

        .value-item {
          width: 100%;
          height: 74px;
          display: flex;
          align-items: center;
          padding-left: 20px;
          box-sizing: border-box;
          cursor: pointer;
          border-radius: 8px;

          &.active {
            background-color: #F3F3F3;
          }

          .avatar {
            width: 44px;
            height: 44px;
            border-radius: 8px;
            margin-right: 15px;
          }

          .name {
            font-size: 16px;
            color: #222222;
          }
        }
      }
    }
  }

  .empty-view {
    width: 100%;
    padding: 66px 0 73px 0;
    display: flex;
    justify-content: center;

    .empty {
      width: 200px;
      height: 115px;
    }
  }

  .handle-view {
    display: flex;
    justify-content: center;
    gap: 16px;

    .btn {
      width: 100px;
      height: 40px;
      border-radius: 8px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;

      &.cancel {
        color: #333333;
        font-size: 16px;
        background-color: #F3F3F3;
      }

      &.confirm {
        color: #FFF;
        font-size: 16px;
        background-color: $theme-color;
      }
    }
  }
}
</style>