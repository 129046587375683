<template>
	<div class="emptyBox" :style="{height : height ? height : ''}">
		<el-image
			:src="require('@/assets/images/newEmpty.png')"
			fit="cover"></el-image>
		<div class="empty_text">{{ content }}</div>
	</div>
</template>
<script>
export default {
	props: {
		height: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: '暂无数据'
		},
	},
	data() {
		return {};
	},
	methods: {},
};
</script>
<style lang="scss">
.emptyBox{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.el-image {
		height: 96px !important;
		width: auto !important;
	}

	.empty_text {
		text-align: center;
		color: #333333;
		font-size: 16px;
		line-height: 24px;
		margin-top: 10px;
	}
}</style>
