import { get, postFile, postJson, putJson, deleteApi } from "@/utils/request";
import { post } from "axios";

export default {
   // 获取外层消息列表
   imChatMessageOuterList (data) {
      return get(`/im/chat/message/outer/list`, data);
   },

   // 获取聊天对话记录
   imChatMessageInnerList (data) {
      return get(`/im/chat/message/inner/list`, data);
   },

   // 查询好友和记录
   appFriendsList (data) {
      return get(`/app/friends/list`, data);
   },

   // 查询im群组列表
   imGroupList (data) {
      return get(`/im/group/list`, data);
   },

   // 获取好友详细信息
   appFriendsDetails (id, data) {
      return get(`/app/friends/get/${id}`, data);
   },

   // IM消息已读
   chatMessageRead (sessionType, receiverId, data) {
      return postJson(`/im/chat/message/read/${sessionType}/${receiverId}`, data);
   },

   // 删除好友
   friendsDelete (friendId, data) {
      return deleteApi(`/app/friends/delete/${friendId}`, data);
   },

   // 新增好友
   addAppFriends (data) {
      return postJson(`/app/friends`, data);
   },

   // 修改好友
   editFriends (data) {
      return putJson(`/app/friends`, data);
   },

   // 置顶/取消置顶
   chatMessageAsTop (sessionType, receiverId, data) {
      return postJson(
         `/im/chat/message/asTop/${sessionType}/${receiverId}`,
         data,
      );
   },

   // 获取im群详情
   imGroupDetails (id, data) {
      return get(`/im/group/${id}`, data);
   },

   // 修改群信息
   editImGroup (data) {
      return putJson(`/im/group`, data);
   },

   // 查询用户的标签列表
   appUserTagList (data) {
      return get(`/app/user/tag/list`, data);
   },

   // 进群审批开关
   imGroupSwitch (id, data) {
      return postJson(`/im/group/switch/${id}`, data);
   },

   // 	群关联（查询小群列表）
   imGroupSubList (data) {
      return get(`/im/group/sub/list`, data);
   },

   // 获取已经禁言用户列表
   imGroupForbidUsers (groupId, data) {
      return get(`/im/group/forbid/users/${groupId}`, data);
   },

   // 查询im群全部用户列表
   imGroupUserAll (data) {
      return get(`/im/group/user/all`, data);
   },

   // 移除群成员-被动
   delImGroupUser (ids, data) {
      return deleteApi(`/im/group/user/${ids}`, data);
   },

   // 邀请用户进群
   imGroupUser (data) {
      return postJson(`/im/group/user`, data);
   },

   // 系统通知分页列表
   noticeSystemPage (data) {
      return get(`/app/notice/system/page`, data);
   },

   // 互动通知分页列表
   noticeInteractPage (data) {
      return get(`/app/notice/interact/page`, data);
   },

   // 创建小群
   imGroupSubCreate (data) {
      return postJson(`/im/group/sub/create`, data);
   },

   // 解散群
   imGroupDismiss (id) {
      return postJson(`/im/group/dismiss/${id}`);
   },

   // 查询im大群用户，但不在子群的用户
   imGroupUserNotInSubGroup (data) {
      return get(`/im/group/user/notInSubGroup`, data);
   },

   // 创建群聊
   imGroupCreate (data) {
      return postJson(`/im/group/create`, data);
   },

   // 批量设置用户身份
   imGroupBatchSetrole (data) {
      return postJson(`/im/group/batch/setrole`, data);
   },

   // 批量设置用户禁言/解除禁言
   imGroupBatchSetforbid (data) {
      return postJson(`/im/group/batch/setforbid`, data);
   },

   // 通过好友申请
   appFriendsPass (data) {
      return postJson(`/app/friends/pass`, data);
   },

   // 申请加群列表
   imApplyList (data) {
      return get(`/im/apply/list`, data);
   },

   // 申请加群审批
   imApplyGroupAudit (data) {
      return postJson(`/im/apply/group/audit`, data);
   },

   // 查询用户已加入的群列表
   imGroupJoinList (data) {
      return get(`/im/group/join/list`, data);
   },

   // 退群-主动，通过群ID
   imGroupUserRemoveGroup (groupId, data) {
      return postJson(`/im/group/user/remove/group/${groupId}`, data);
   },

   // 获取用户是否被禁言
   imGroupForbid (groupId, data) {
      return get(`/im/group/forbid/${groupId}`, data);
   },

   // 群禁言时间段设置开关
   imGroupForbidTime (id) {
      return postJson(`/im/group/forbid/time/${id}`);
   },

   // 修改群禁用时间段
   imGroupForbidTimeList (data) {
      return postJson(`/im/group/forbid/time`, data);
   },
   //获取自己是群主的群列表  /im/group/created/list
   imGroupIsLeader (data) {
      return postJson(`/im/group/created/list`, data);
   },

   //修改群防骚扰设置 新增群防骚扰设置
   imGroupNoHarass (data) {
      return postJson(`/im/group/disturb`, data);
   },

   //获取群防骚扰设置详细信息
   imGroupNoHarassDetails (id) {
      return get(`/im/group/disturb/${id}`);
   },

   //删除群防骚扰设置
   DeleteImGroupNoHarass (ids) { //ids array
      return deleteApi(`/im/group/disturb/${ids}`);
   },

   // 生成群二维码
   imGroupGencode (id, data) {
      return postJson(`/im/group/gencode/${id}`, data);
   },

   // 申请加群
   imApplyGroup (data) {
      return postJson(`/im/apply/group`, data);
   },

   // 用户是否在群里
   imGroupUserInGroup (id, data) {
      return get(`/im/group/user/inGroup/${id}`, data);
   },

   // 新增群授权渠道列
   addGroupChannel (data) {
      return postJson(`/im/group/channel`, data);
   },

   // 修改群授权渠道列
   editGroupChannel (data) {
      return putJson(`/im/group/channel`, data);
   },

   // 获取群授权渠道列详细信息
   imGroupChannelId (id) {
      return get(`/im/group/channel/${id}`);
   },

   // 查询全部群授权渠道列列表
   imGroupChannelList (data) {
      return get(`/im/group/channel/list`, data);
   },

   //系统通知未读消息列表
   imSystemNoticeTab () {
      return get("/app/notice/tab/list");
   },
   //商城消息
   imSystemShopNotice (data) {
      return get("/app/notice/shop/page", data);
   },
   //钱包通知
   imSystemWalletNotice (data) {
      return get("/app/notice/wallet/page", data);
   },
   //mcn通知
   imSystemMcnNotice (data) {
      return get("/app/notice/mcn/page", data);
   },
   //任务通知
   imTaskNotice (data) {
      return get("/app/notice/task/page", data);
   },
   //系统通知未读消息获取
   getSystemReadNum (data) {
      return get("/app/notice/tab/list", data);
   },
   //获取新的粉丝列表
   getFansNoticeList (data) {
      return get("/app/api/common/fans", data);
   },
   //获取系统消息外层列表
   getSystemOuterList () {
      return get("/app/notice/outer/list");
   },
   //假列表
   getFakeList (data) {
      return get("/app/im/list", data);
   },
   //假列表新增
   fakeListAdd (data) {
      return postJson(`/app/im/addFakeMessage`, data);
   },
   //编辑假列表
   updatedFakeList (data) {
      return putJson("/app/im", data);
   },
   //假列表删除
   fakeListDel (groupld, id) {
      return deleteApi(`/app/im/${groupld}/${id}`);
   },
   // 邀请的用户列表，二维数组
   imGroupInviteUserList (data) {
      return get(`/im/group/user/invite/list`, data);
   },
   // 修改我在群里的昵称
   updateImGroupMyName (data) {
      return postJson("/im/group/user/update/nickname", data);
   },
   //群内是否禁止添加好友
   updateIsAddFriend (id) {
      return postJson(`/im/group/addfriend/${id}`);
   },
   //通讯录列表
   appFriendBook () {
      return get("/app/friends/book");
   },
   //通讯录数据统计
   getFriendNumber () {
      return get("/im/chat/stat/person");
   },
   //删除外消息列表
   deleteImChatMessageOuterList (id) {
      return deleteApi(`/im/chat/message/delete/${id}`);
   },
   //标签下的好友列表
   labelFriendList (params) {
      return get("/app/user/tag/friend/list", params)
   },
   //新增好友标签
   setFriendLabel (data) {
      return postJson("/app/user/tag/setFriendTag", data);
   },
   deleteLabel (tagId) {
      return deleteApi(`/app/user/tag/${tagId}`)
   },
   // 获取达标群列表
   getListGroupUserApi (data) {
      return get(`/im/imGroupUserRecord/listByGroupId/${data.groupId}`)
   },
   // 获取群列表
   getListGroupApi (data) {
      return get(`/im/group/sub/list`, data)
   },
   // 结算接口
   settleApi (data) {
      return postJson(`/busy-order/app/busy/task/delivery/apply/settle`, data)
   }
};
