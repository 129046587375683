<template>
	<dialogBox appendToBody v-model="dialogVisible" class="SwitchGroupChatDialog">
		<div slot="header_title">切换群聊</div>
		<!-- <q-input
			class="searchInput"
			v-model="searchInput"
			placeholder="请输入要查找的内容"
			@input="searchChange"
		></q-input> -->
		<commonInput
			class="searchInput"
			v-model="searchInput"
			placeholder="请输入要查找的内容"
			@input="searchChange"
		>
		</commonInput>
		<div class="list">
			<template v-if="list.length">
				<addressBook_row
					@close="dialogVisible = false"
					type="切换群聊"
					v-for="item of list"
					:item="item"
					:key="item.id"
					:chatUserMsgActive="chatUserMsgActive"
					:chatUserMsg="chatUserMsg"
				></addressBook_row>
			</template>
			<div class="userList_empty" v-else>暂无关联群</div>
		</div>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import AddressBook_row from '@/views/chat/components/user/addressBook_row.vue';
import CommonInput from '@/views/chat/components/common/commonInput.vue';
export default {
	components: { dialogBox, AddressBook_row, CommonInput },
	props: ['chatUserMsg'],
	data() {
		return {
			dialogVisible: false,
			searchInput: '',
			list: [],
			oldList: [],
			mainGroup: [],
			chatUserMsgActive: {},
		};
	},
	methods: {
		open(val) {
			console.log('val: ', val);
			val.receiver.isMain = true;
			this.chatUserMsgActive = val;
			this.$set(val.receiver, 'isMain', true);
			this.$set(val.receiver, 'groupUserCount', val.groupUserCount);
			this.mainGroup = [val.receiver];
			this.dialogVisible = true;
			this.getGroupSubList(val.receiver.id);
		},

		// 搜索按钮
		searchChange() {
			this.list = this.oldList.filter((item) => {
				return item.name.includes(this.searchInput);
			});
		},

		// 群列表
		async getGroupSubList(id) {
			const res = await this.$http.imGroupSubList({
				groupId: id,
			});
			this.oldList = [...this.mainGroup, ...res.data];
			this.list = this.oldList;
			console.log('this.list : ', this.list);
		},
	},
};
</script>
<style lang="scss">
.SwitchGroupChatDialog {
	.searchInput {
		margin-top: 24px;
	}

	.list {
		height: 340px;
		overflow-y: auto;
		margin-top: 20px;
		overflow-x: hidden;

		.userList_empty {
			margin-top: 58px;
			text-align: center;
			color: #999999;
			font-size: 18px;
			font-weight: 500;
		}
	}
}
</style>
