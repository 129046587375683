<template>
    <!-- 选中了已经有规则的群聊提醒弹窗 -->
    <DialogBox
        confirmText="确认"
        showBottomBtn
        appendToBody
        v-model="subdialogVisible"
        class="Anti-Har-sub"
        @confirm="confirmActive"
        @cancel="cancelActive"
        style="z-index: 3000;"
    >
        <div slot="header_title"></div>
        <div class="Anti-Har-sub-title">确认选择群聊</div>
        <p>部分群聊有设置规则，规则保存后将会适用新的规则</p>
    </DialogBox>
</template>

<script>
import DialogBox from '@/components/dialogBox.vue';
export default{
    components:{
        DialogBox
    },
    data(){
        return{
            subdialogVisible:false,
        }
    },
    methods:{
        open(data){
            this.subdialogVisible = true          
        },
        cancelActive(close){
            // this.activeGroupList.pop()
            this.subdialogVisible = false
            // this.$emit('popActiveGroupList',true)
            // close(true)
        },
        confirmActive(close){
            this.subdialogVisible = false
            this.$emit('confimActiveGroupList',true)
            close(true)
        },
    }
}
</script>
<style>
/* .Anti-Har-sub .dialogBox.el-dialog{
    margin-top: 35vh!important;
} */
</style>
<style scoped>
.Anti-Har-sub-title{
    width: 100%;
    text-align: center;
    color: #3d3d3d;
    font-size: 24px;
    font-weight: bold;
}

</style>