<template>
	<div class="FileUpload">
		<div class="upload-head">
			<div
				class="fileUploadBtn"
				@click="fileUploadClick"
				:class="{ isDisabled: disabled }"
			>
				<slot>
					<el-button
						size="small"
						:disabled="disabled"
						type="primary"
						:loading="buttonLoading"
						icon="el-icon-upload2"
						>{{ buttonLoading ? '上传中' : '附件上传' }}
					</el-button>
				</slot>
			</div>

			<!-- 上传提示 -->
			<div class="el-upload__tip" v-if="showTip && !disabled">
				请上传
				<template v-if="fileMaxSize">
					大小不超过 <b style="color: #ef8052">{{ fileMaxSize }}MB</b>
				</template>
				<template v-if="fileTypes">
					格式为 <b style="color: #ef8052">{{ fileTypes.join('/') }}</b>
				</template>
				的文件
			</div>
		</div>

		<!-- 文件列表 -->
		<template v-if="fileListShow">
			<div class="file-item" v-for="(file, index) in fileList" :key="file">
				<div class="file-name">
					<el-link :href="file" :underline="false" target="_blank">{{
						file
					}}</el-link>
				</div>
				<i
					class="iconfont icon-sohu-shanchu"
					@click="handleDelete(index)"
					v-if="!disabled"
				></i>
			</div>
		</template>

		<p class="icon-tip" v-if="iconTip !== ''">
			<i class="iconfont icon-sohu-tishi"></i>
			<span>{{ iconTip }}</span>
		</p>

		<input
			ref="inputFileRef"
			:accept="fileTypes.join()"
			type="file"
			hidden
			:multiple="limit > 1"
			@change="fileChange"
		/>
	</div>
</template>
<script>
export default {
	props: {
		value: String,
		fileTypes: {
			type: Array,
			default: () => [],
		},
		fileMaxSize: {
			type: Number,
			default: 200,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		showTip: {
			type: Boolean,
			default: false,
		},
		fileListShow: {
			type: Boolean,
			default: false,
		},
		iconTip: {
			type: String,
			default: '',
		},
		limit: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			file: '',
			buttonLoading: false,
		};
	},
	watch: {
		value: {
			handler(val) {
				if (val) {
					this.fileList = val.split(',');
				} else {
					this.fileList = [];
				}
			},
			depp: true,
			immediate: true,
		},
	},
	methods: {
		// 选择文件按钮
		fileUploadClick() {
			if (!this.buttonLoading && !this.disabled) {
				this.$refs.inputFileRef.click();
			}
		},

		async fileChange(e) {
			if (this.limit === 1) {
				await this.handleSingleFile(e);
			} else {
				await this.handleBatchFile(e);
			}
			this.$refs.inputFileRef.value = '';
		},
		/**
		 * 校验文件流是否通过校验
		 * @param e
		 * @returns {Promise<unknown>}
		 */
		validateFile(e) {
			return new Promise((resolve, reject) => {
				let fileMaxSize = 1024 * 1024 * this.fileMaxSize;
				const fileList = [...e.target.files];
				console.log(fileList.length);
				if (fileList.length > this.limit)
					reject(
						`当前限制选择 ${this.limit} 个文件，本次选择了 ${fileList.length} 个文件`,
					);
				fileList.forEach((v) => {
					if (v.size > fileMaxSize) {
						reject(`${v.name}文件大小超过${this.fileMaxSize}M`);
					}
					let strList = v.name.split('.');
					if (!this.fileTypes.includes('.' + strList[strList.length - 1]))
						reject(`${v.name}文件格式错误`);
				});
				resolve(e);
			});
		},
		/**
		 * 批量上传文件
		 * @param e
		 * @returns {Promise<void>}
		 */
		async handleBatchFile(e) {
			this.validateFile(e)
				.then(async (res) => {
					const fileList = [...res.target.files];
					//上传前执行消息loading展示
					this.$emit('beforeUpload', fileList);
					//开始执行依次上传 (IM)
					fileList.forEach((item) => {
						this.$http
							.uploadFile(item)
							.then((res) => {
								if (res.code === 200) {
									this.$emit('uploadSuccess', {
										...res.data,
										size:
											fileList.find((v) => v.name === res.data.fileName).size ||
											0,
									});
								} else {
									this.$message.error('上传失败');
								}
							})
							.catch((err) => {
								console.log(err, 'err');
							});
					});
					//开始执行批量上传
					// const uploadResponse = await Promise.all(fileList.map((v) => {
					// 	return new Promise((resolve, reject) => {
					// 		this.$http.uploadFile(v).then(res => {
					// 			if (res.code === 200) {
					// 				resolve({ ...res.data, size: fileList.find(v => v.name === res.data.fileName).size || 0 });
					// 			} else {
					// 				reject("上传失败");
					// 			}
					// 		}).catch(err => {
					// 			console.log(err);
					// 		});
					// 	});
					// }));
				})
				.catch((err) => {
					console.log(err, 'err');
					this.$message.error(err);
				});
		},
		/**
		 * 普通单文件上传
		 * @param e
		 * @returns {Promise<void>}
		 */
		async handleSingleFile(e) {
			this.file = '';
			let file = e.target.files[0];
			let fileName = e.target.files[0].name;
			let fileMaxSize = 1024 * 1024 * this.fileMaxSize;
			let fileType = this.fileTypes.join('|');
			let fileNameReg = new RegExp(`${fileType}$`); //设置文件格式
			if (fileNameReg.test(fileName.toLowerCase())) {
				if (file.size > fileMaxSize) {
					this.$message.error(`文件大小不能超过${this.fileMaxSize}M`);
				} else {
					this.file = file;
				}
			} else {
				this.$message.error('文件格式错误');
			}
			if (this.file) {
				try {
					this.buttonLoading = true;
					const res = await this.$http.uploadFile(this.file);
					this.$emit('success', res.data, this.file);
					this.buttonLoading = false;
					this.fileList.push(res.data.url);
					this.$emit('input', this.fileList.join());
				} catch (error) {
					this.buttonLoading = false;
				}
			}
		},

		// 删除文件
		handleDelete(index) {
			this.fileList.splice(index, 1);
			this.$emit('input', this.fileList.join());
		},
	},
};
</script>
<style lang="scss">
.FileUpload {
	.fileUploadBtn {
		&.isDisabled {
			opacity: 0.4;
		}
	}

	.el-upload__tip {
		line-height: 30px;
		margin: 0;
		color: #999999;
	}

	.file-item {
		display: flex;
		align-items: center;
		margin-top: 16px;

		.file-name {
			width: 320px;
			height: 26px;
			color: #333333;
			background-color: #f5f7fa;
			padding: 0 12px;
			line-height: 26px;

			.el-link {
				width: 100%;
				vertical-align: bottom;

				span {
					white-space: nowrap;
					overflow: hidden; /* 隐藏超出容器的内容 */
					text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
				}
			}

			border-radius: 4px;
		}

		i {
			margin-left: 24px;
		}
	}

	.icon-tip {
		color: #ff6c27;
		display: flex;
		align-items: center;
		margin-top: 12px;

		i {
			font-size: 13px;
		}

		span {
			font-size: 12px;
			margin-left: 4px;
			line-height: 16px;
		}
	}
}
</style>
