<template>
	<dialog-box
		width="734px"
		appendToBody
		v-model="dialogVisible"
		class="SelectMembersDialog"
		:showHeader="false"
	>
		<div class="SelectMembersDialog_container">
			<div class="leftAllList">
				<commonInput
					@input="searchInputChange"
					v-model="searchInput"
					class="searchInput-com"
					placeholder="请输入"
					prefixIcon="icon-sohu-sousuo1"
				></commonInput>
				<scroll-box
					height="500px"
					class="userDataList"
					:total="beSelectedList?.length"
				>
					<div
						class="userData_row"
						v-for="item of beSelectedList"
						:key="item.id"
					>
						<div class="userData">
							<el-avatar
								:src="item.userAvatar"
								class="user-avatar-img"
							></el-avatar>
							<div class="line-1">{{ getUserName(item) }}</div>
						</div>
						<div>
							<commonCheckBox
								:disabled="
									(dialogTitle == '新增管理员' &&
										(item.permissionType == 'group_leader' ||
											item.permissionType == 'group_admin')) ||
									(dialogTitle == '新增禁言' &&
										item.permissionType == 'group_leader')
								"
								v-model="item.checked"
							></commonCheckBox>
						</div>
					</div>
				</scroll-box>
				<!-- </div> -->
			</div>
			<div class="rightSelectList">
				<div class="rightSelectList_title">{{ dialogTitle }}</div>
				<div class="userDataList right-userDataList">
					<div class="userBox" v-for="item of selectList" :key="item.id">
						<div class="userBox-item">
							<div class="userBox-item-left">
								<el-avatar
									:src="item.userAvatar"
									class="user-avatar-img"
								></el-avatar>
								<div class="userName line-1">{{ getUserName(item) }}</div>
							</div>
							<i
								class="icon-sohu-guanbi2 remove"
								@click="removeClick(item)"
							></i>
						</div>
					</div>
				</div>
				<div class="bottom_btn">
					<button @click="dialogVisible = false">取消</button>
					<button
						:disabled="!selectList?.length || btnLoading"
						@click="throttleSearchChange"
					>
						确定
					</button>
				</div>
			</div>
		</div>
	</dialog-box>
</template>
<script>
import DialogBox from '@/components/dialogBox.vue';
import CommonInput from '@/views/chat/components/common/commonInput.vue';
import CommonCheckBox from '@/views/chat/components/common/commonCheckBox.vue';
export default {
	props: ['title'],
	components: { DialogBox, CommonInput, CommonCheckBox },
	data() {
		return {
			dialogVisible: false,
			dialogTitle: '',
			beSelectedList: [],
			id: '',
			searchInput: '',
			list: [],
			btnLoading: false,
			currentUserRole: null, //存储当前用户角色
			timer: '',
			debouncedChange: null,
		};
	},
	computed: {
		selectList() {
			console.log(this.beSelectedList, 'selectList');
			return this.beSelectedList
				? this.beSelectedList.filter((item) => item.checked)
				: [];
		},
	},
	inject: {
		getDetails: { value: 'getDetails', default: null },
		imGroupSubList: { value: 'imGroupSubList', default: null },
		groupAdminList: { value: 'groupAdminList', default: null },
		imGroupForbidList: { value: 'imGroupForbidList', default: null },
		getGroupUserList: { value: 'getGroupUserList', default: null },
	},

	mounted() {
		// 在组件挂载时初始化防抖函数
		this.debouncedChange = this.debounce(this.confirmClick.bind(this), 800);
	},
	methods: {
		//优先显示成员在群的名称 别名 昵称 用户名
		getUserName(item) {
			return (
				item.groupUserNickName || item.alias || item.nickName || item.userName
			);
		},
		getCurrentUserRole() {
			console.log('00000');
			const currentUserId = this.$store.state.userInfo.id; // 假设用户ID存储在 Vuex 中
			const currentUser = this.beSelectedList.find(
				(item) => item.userId === currentUserId,
			);
			this.currentUserRole = currentUser ? currentUser.permissionType : null;
			return this.currentUserRole;
		},
		async open({ title, id, pid, details }) {
			console.log('details: ', details);
			this.dialogVisible = true;
			this.dialogTitle = title;
			this.id = id;
			let res;
			switch (title) {
				case '发起群聊':
					res = await this.$http.appFriendsList({
						applyState: 'pass',
						pageSize: 9999,
						pageNum: 1,
					});
					break;
				case '邀请进群':
					if (pid && details.copyGroupType == 'group') {
						res = await this.$http.imGroupUserNotInSubGroup({
							groupId: id,
						});
					} else {
						res = await this.$http.appFriendsList({
							notInGroupId: id,
							applyState: 'pass',
							pageSize: 9999,
						});
					}
					break;
				case '移除群成员':
				case '新增管理员':
				case '新增禁言':
					res = await this.$http.imGroupUserAll({
						groupId: id,
					});
					break;
				case '新增群关联':
					res = await this.$http.imGroupUserAll({
						groupId: id,
					});
					break;
			}
			console.log(res);
			res?.data?.forEach((item) => {
				item.checked = false;
			});

			// 不显示群主
			this.beSelectedList = res.data.filter(
				(item) => item.permissionType !== 'group_leader',
			);
			this.getCurrentUserRole();

			// 如果当前角色是管理员  也不显示管理员
			if (this.currentUserRole == 'group_admin') {
				this.beSelectedList = this.beSelectedList.filter(
					(item) => item.permissionType !== 'group_admin',
				);
			}

			console.log('	this.currentUserRole: ', this.currentUserRole);

			this.list = res.data;
		},

		// 输入框的值发生变化
		searchInputChange(val) {
			console.log(val);
			this.beSelectedList = this.list.filter((item) => {
				return val ? item.userName.includes(val) : true;
			});
		},

		// 移除按钮
		removeClick(val) {
			this.beSelectedList.forEach((item) => {
				if (val.id == item.id) {
					item.checked = false;
				}
			});
		},

		// 确定按钮
		async confirmClick() {
			console.log(this.beSelectedList);
			let key;
			if (
				[
					'邀请进群',
					'新增群关联',
					'发起群聊',
					'新增管理员',
					'新增禁言',
				].includes(this.dialogTitle)
			) {
				key = 'friendId';
			} else {
				key = 'id';
			}
			let ids = this.selectList.map((item) => item[key] || item.userId);
			let res;
			switch (this.dialogTitle) {
				case '发起群聊':
					try {
						this.btnLoading = true;
						res = await this.$http.imGroupCreate({
							userIds: ids,
						});
						if (res.code == 200 && res.data && res.data.id) {
							setTimeout(() => {
								this.$store.dispatch('getUserMessageList');
								this.btnLoading = false;
							}, 500);
						}
					} finally {
					}

					break;
				case '邀请进群':
					res = await this.$http.imGroupUser({
						groupId: this.id,
						userIds: ids,
					});
					break;
				case '移除群成员':
					res = await this.$http.delImGroupUser(ids.join());
					if (res.code == 200) this.$store.dispatch('getUserMessageList');
					break;
				case '新增群关联':
					res = await this.$http.imGroupSubCreate({
						groupId: this.id,
						userIds: ids,
					});
					break;
				case '新增管理员':
					res = await this.$http.imGroupBatchSetrole({
						groupId: this.id,
						userIds: ids,
						permissionType: 'group_admin',
					});
					break;
				case '新增禁言':
					res = await this.$http.imGroupBatchSetforbid({
						groupId: this.id,
						userIds: ids,
						forbid: true,
					});
					break;
			}

			if (res.code == 200) {
				this.$message.success('操作成功');
				if (['邀请进群', '移除群成员'].includes(this.dialogTitle)) {
					if (this.getGroupUserList) {
						this.getGroupUserList();
					} else {
						this.getDetails(this.id);
					}
				}
				if (['新增群关联'].includes(this.dialogTitle)) {
					this.imGroupSubList(this.id);
				}
				if (['新增管理员'].includes(this.dialogTitle)) {
					this.groupAdminList(this.id);
				}
				if (['新增禁言'].includes(this.dialogTitle)) {
					this.imGroupForbidList(this.id);
				}
				this.dialogVisible = false;
			}
		},

		// 防抖
		debounce(func, wait) {
			let timeout;
			return (...args) => {
				const context = this; // 使用箭头函数保持上下文
				clearTimeout(timeout); // 清除之前的定时器
				timeout = setTimeout(() => {
					func.apply(context, args); // 在延迟后调用目标函数
				}, wait);
			};
		},

		throttleSearchChange(e) {
			this.debouncedChange(e); // 调用存储的防抖函数
		},
	},
};
</script>
<style lang="scss">
.SelectMembersDialog {
	.SelectMembersDialog_container {
		display: flex;
		.user-avatar-img {
			border-radius: 8px;
		}
		.leftAllList {
			width: 268px;
			padding-right: 32px;
			box-sizing: border-box;
			&::after {
				content: '';
				height: 1000px;
				width: 1px;
				background-color: #f3f3f3;
				position: absolute;
				left: 300px;
				top: 0;
			}
			.userDataList {
				// height: 440px;
				// overflow: auto;
				margin-top: 20px;
				.userData_row {
					padding-right: 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 24px;
					.userData {
						display: flex;
						align-items: center;
						color: #3d3d3d;
						font-size: 14px;
						font-weight: 500;
						.el-avatar {
							height: 40px;
							width: 40px;
							min-width: 40px;
							margin-right: 10px;
						}
					}
				}
			}
		}
		.rightSelectList {
			flex: 1;
			padding-left: 28px;
			.rightSelectList_title {
				color: #3d3d3d;
				line-height: 36px;
				font-weight: 500;
				font-size: 24px;
				margin-bottom: 24px;
			}

			.userDataList {
				height: 400px;
				display: grid;
				grid-template-columns: repeat(5, 1fr);
				grid-column-gap: 18px;
				grid-row-gap: 16px;
				align-content: start;
				overflow-y: auto;
				.userBox {
					position: relative;
					text-align: center;
					// max-width: 54px;
					width: 100%;
					.el-avatar {
						width: 48px;
						height: 48px;
						margin-bottom: 6px;
						font-size: 14px;
						color: #3d3d3d;
						line-height: 22px;
					}
					.remove {
						// position: absolute;
						// top: 0;
						// right: -6px;
						// font-size: 16px;
						// color: #f53f3f;
						// cursor: pointer;
						// &:hover {
						// 	opacity: 0.8;
						// }
						cursor: pointer;
						margin-right: 4px;
					}
				}
			}
			.right-userDataList {
				height: 440px;
				overflow-y: scroll;
				overflow-x: hidden;
				display: flex;
				flex-wrap: wrap;
				.userBox-item {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.userBox-item-left {
						width: 300px;
						display: flex;
						align-items: center;
						.el-avatar {
							margin-right: 15px;
							margin-bottom: 0;
						}
					}
				}
			}
			.bottom_btn {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 16px;
				button {
					width: 100px;
					height: 40px;
					border-radius: 4px;
					background: #f3f3f3;
					font-weight: 500;
					font-size: 16px;
					line-height: 36px;
					cursor: pointer;
					&:hover {
						opacity: 0.8;
					}
					& + button {
						background: $theme-color;
						color: #ffffff;
						margin-left: 16px;
					}
					&[disabled] {
						cursor: not-allowed;
						opacity: 0.5;
					}
				}
			}
		}
	}
	.el-input__icon {
		line-height: 100% !important;
	}
}
.searchInput-com .el-input__inner {
	background: #fff !important;
	border: 1px solid #dee0e3;
	border-radius: 8px;
}
.searchInput-com .el-input__icon {
	line-height: 100%;
	font-weight: bold;
	color: #909399;
	position: relative;
	top: 2px;
	right: 2px;
}
</style>
<style lang="scss" scoped>
.line-1 {
	color: #222;
}
</style>
