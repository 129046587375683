<template>
	<dialog-box
		confirmText="新增群关联"
		showBottomBtn
		appendToBody
		v-model="dialogVisible"
		class="GroupAssociationDialog"
		@confirm="confirmClick"
	>
		<div slot="header_title">群关联</div>
		<div class="groupList">
			<AddressBook_row
				v-for="item of groupList"
				:key="item.id"
				:item="item"
				type="群关联"
				:chatUserMsg ="chatUserMsg"
			></AddressBook_row>

			<CommonEmpty
				content="暂无关联"
				height="200px"
				v-if="groupList.length == 0"
			></CommonEmpty>
		</div>

		<SelectMembersDialog ref="SelectMembersDialogRef"></SelectMembersDialog>
	</dialog-box>
</template>

<script>
import DialogBox from '@/components/dialogBox.vue';
import AddressBook_row from "@/views/chat/components/user/addressBook_row.vue";
import SelectMembersDialog from "@/views/chat/components/group/setting/selectMembersDialog.vue";
import CommonEmpty from "@/views/chat/components/common/commonEmpty.vue"
export default {
	components: {
		AddressBook_row,
		DialogBox,
		SelectMembersDialog,
		CommonEmpty
	},
	props:['chatUserMsg'],
	data() {
		return {
			dialogVisible: false,
			groupList: [],
			id: '',
		};
	},
	provide() {
		return {
			imGroupSubList: this.open,
		};
	},
	methods: {
		async open(id) {
			id && (this.id = id);
			this.dialogVisible = true;
			const res = await this.$http.imGroupSubList({
				groupId: this.id,
			});
			this.groupList = res.data;
			console.log('获取群关联传递得',this.chatUserMsg)
		},

		// 新增群关联
		confirmClick(close) {
			console.log('新增群关联');
			this.$refs.SelectMembersDialogRef.open({
				title: '新增群关联',
				id: this.id,
			});
			close(true);
		},
	},
};
</script>

<style scoped lang="scss">
.GroupAssociationDialog {
	.groupList {
		display: grid;
		grid-row-gap: 20px;
		margin-top: 24px;

		.group_row {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.left_user {
				display: flex;
				align-items: center;

				.el-avatar {
					width: 40px;
					height: 40px;
					margin-right: 10px;
				}

				.groupName {
					color: #3d3d3d;
					font-size: 14px;
					font-weight: 500;
					line-height: 36px;
				}
			}

			.right_set {
				display: flex;
				align-items: center;

				.setBox {
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #f3f3f3;
					width: 32px;
					height: 32px;
					margin-left: 20px;
					cursor: pointer;

					i {
						font-size: 20px;
					}

					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
	}
}
</style>
