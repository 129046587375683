<template>
	<dialogBox appendToBody v-model="dialogVisible" class="AddFriendsDialog">
		<div slot="header_title" style="display: flex; align-items: center">
			添加好友
		</div>
		<el-input
			v-model="searchInput"
			placeholder="请输入ID或好友昵称"
			class="searchInput"
			clearable
			@input="throttleSearchChange"
			maxlength="20"
		>
			<i @click="searchClick" slot="suffix" class="icon-sohu-sousuo1"></i
		></el-input>
		<scroll-box
			:loading="loading"
			:total="userList.length"
			height="340px"
			@scrolltolower="userLoad"
			class="add-friend-scroll"
			:isemptyImg="false"
		>
			<template>
				<AddressBook_row
					v-for="item of userList"
					type="添加好友"
					:item="item"
					:key="item.id"
				></AddressBook_row>
			</template>
			<div v-if="!userList.length && isSearch" class="friend-scroll-flex">
				<template>
					<el-image
						class="friend-scroll-img"
						:src="require('@/assets/images/search_empty.png')"
						fit="fill"
					></el-image>
				</template>
			</div>
		</scroll-box>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import AddressBook_row from '@/views/chat/components/user/addressBook_row.vue';
export default {
	components: { dialogBox, AddressBook_row },
	data() {
		return {
			dialogVisible: false,
			searchInput: '',
			userList: [],
			pageNum: 1,
			total: 0,
			loading: false,
			isSearch: false,
			timer: '',
			debouncedSearchChange: null,
		};
	},

	mounted() {
		// 在组件挂载时初始化防抖函数
		this.debouncedSearchChange = this.debounce(
			this.searchClick.bind(this),
			300,
		);
	},

	methods: {
		open() {
			this.dialogVisible = true;
		},

		// 搜索按钮
		searchClick() {
			if (this.searchInput) this.isSearch = true;
			this.pageNum = 1;
			this.getUserList();
		},

		// 获取用户列表
		async getUserList() {
			this.loading = true;
			const res = await this.$http.userPage({
				keyword: this.searchInput,
				pageNum: this.pageNum,
				pageSize: 10,
				// queryFriend: true,
			});
			if (res.code == 200) {
				if (this.pageNum == 1) {
					this.userList = res.data || [];
				} else {
					this.userList = this.userList.concat(res.data);
				}
				this.total = res.total;
				this.loading = false;
				this.isSearch = false;
			}
		},

		// 好友弹窗滚动到底部
		userLoad() {
			if (this.total > this.userList.length) {
				this.pageNum++;
				this.getUserList();
			}
		},

		debounce(func, wait) {
			let timeout;
			return (...args) => {
				const context = this; // 使用箭头函数保持上下文
				clearTimeout(timeout); // 清除之前的定时器
				timeout = setTimeout(() => {
					func.apply(context, args); // 在延迟后调用目标函数
				}, wait);
			};
		},

		throttleSearchChange(e) {
			this.debouncedSearchChange(e); // 调用存储的防抖函数
		},
	},
};
</script>
<style lang="scss">
.AddFriendsDialog {
	.add-friend-scroll {
		position: relative;
		top: 26px;
		overflow-y: scroll;
		overflow-x: hidden;

		.friend-scroll-flex {
			width: 100%;
			padding: 70px 0;
			display: flex;
			justify-content: center;
		}

		.friend-scroll-img {
			width: 200px;
			height: auto;
			margin: 0 auto;
		}
	}

	.searchInput {
		margin-top: 24px;
		border-radius: 4px;
		height: 36px;
		position: relative;

		.el-input__inner {
			border: 0 solid #000;
			background: #f3f3f3;
			padding-left: 30px;
			padding-right: 40px;

			&::placeholder {
				color: #999999;
				font-size: 14px;
			}
		}

		.el-input__suffix {
			.el-input__clear {
				position: absolute;
				right: 5px;
				top: -2px;
			}

			.icon-sohu-sousuo1 {
				left: -424px;
				position: absolute;
				top: 8px;
				font-size: 20px;
				color: #5d5d5d;
				cursor: pointer;

				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
}
</style>
