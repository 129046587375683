import { render, staticRenderFns } from "./groupSettingsDialog.vue?vue&type=template&id=5bdef4f8&scoped=true&"
import script from "./groupSettingsDialog.vue?vue&type=script&lang=js&"
export * from "./groupSettingsDialog.vue?vue&type=script&lang=js&"
import style0 from "./groupSettingsDialog.vue?vue&type=style&index=0&id=5bdef4f8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bdef4f8",
  null
  
)

export default component.exports