<template>
	<div class="searchInput-main">
		<el-input
			class="searchInput searchInput-com"
			v-model="searchInput"
			placeholder="搜索"
			@input="throttleSearchChange"
			prefix-icon="icon-sohu-sousuo1"
			clearable
		>
			<!-- <i @click="searchClick" slot="suffix" class="icon-sohu-sousuo1"></i
            > -->
		</el-input>
		<!-- icon-sohu-sousuo2 prefix-icon="el-icon-search" -->
		<transition name="el-zoom-top">
			<div v-show="searchUserGroup">
				<div
					class="search-user-group-close"
					@click.prevent.stop="searchUserGroup = false"
					@contextmenu.prevent.stop="searchUserGroup = false"
				></div>
				<div class="search-user-group">
					<div class="search-list-scroll">
						<div class="search-list-scroll-box">
							<div
								class="search-list"
								v-for="(list, index) in searchData"
								:key="index"
							>
								<div class="search-list-title" v-if="list.length">
									{{ index == 0 ? '联系人' : '群聊' }}
								</div>

								<div class="search-items">
									<div
										class="search-item"
										v-for="(item, inx) in list"
										:key="inx"
										@click="toChatRoom(item)"
									>
										<el-avatar
											icon="el-icon-user-solid"
											class="search-item-img"
											size="large"
											shape="circle"
											:src="item.userAvatar || item.logo"
											fit="fill"
										></el-avatar>
										<div class="search-item-user" v-if="index == 0">
											<p
												class="over-hidden"
												v-html="
													setActiveStr(
														item.alias || item.nickName || item.userName,
														searchInput,
													)
												"
											></p>
											<span
												v-if="item.nickName"
												class="over-hidden"
												v-html="
													setActiveStr(
														item.nickName || item.userName,
														searchInput,
														null,
														'nick',
													)
												"
											></span>
										</div>
										<div class="search-item-user" v-if="index == 1">
											<p
												v-html="
													setActiveStr(
														item.name,
														searchInput,
														`(${item.groupUserNum})`,
													)
												"
											></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="all-btn"
						@click="allClick"
						v-if="friendToal > 5 || groupToal > 5"
					>
						{{ isAll ? '收起全部' : '展开全部' }}
					</div>
					<!-- <CommonEmpty v-if="!friendToal && !groupToal"></CommonEmpty> -->
					<!--                    <el-image class="no-search-img" v-if="!friendToal && !groupToal" :src="require('@/assets/images/search_empty.png')" fit="fill"></el-image>-->
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	data() {
		return {
			searchInput: '', //输入框值
			searchUserGroup: false, //是否展开弹出
			searchData: [], //模拟数据
			isAll: false, //是否展开全部
			searchAllData: [],
			friendToal: 0,
			groupToal: 0,
			isSearchBox: true,
			timer: '',
			debouncedSearchChange: null,
		};
	},
	mounted() {
		// 在组件挂载时初始化防抖函数
		this.debouncedSearchChange = this.debounce(
			this.searchChange.bind(this),
			500,
		);
	},
	methods: {
		// 点击搜索内容跳转对应聊天室
		toChatRoom(item) {
			this.$emit('handleToChatRoom', item);
			this.searchUserGroup = false;
		},
		// 获取聊天查询列表
		async getImChatList() {
			let friendData, groupData;
			// 直接获取查询的人和群
			// if(this.isAll){
			//     friendData = {
			//         alias: this.searchInput.trim(),
			//     }
			//     groupData = {
			//         name: this.searchInput.trim(),
			//     }
			// }else{
			//     friendData = {
			//         pageSize : 5,
			//         pageNum : 1,
			//         alias: this.searchInput.trim(),
			//     }
			//     groupData = {
			//         pageSize : 5,
			//         pageNum : 1,
			//         name: this.searchInput.trim(),
			//     }
			// }
			// let res = await this.$http.appFriendsList(friendData)
			// let res1 = await this.$http.imGroupList(groupData) //imGroupJoinList 查询用户已经加入的群聊
			// this.searchData = []
			// if(res.code == 200 && res1.code == 200){
			//     this.searchData = [[...res.data],[...res1.data]]
			//     this.friendToal = res.total
			//     this.groupToal = res1.total
			// }
			//查询所有列表 自己查询
			//通讯录 this.$http.appFriendBook()
			let res = await this.$http.appFriendBook();
			let res1 = await this.$http.imGroupJoinList(); //imGroupJoinList 查询用户已经加入的群聊
			let arr = [];
			let arr1 = [];
			if (res.code != 200 && res1.code != 200) return;

			res.data.map((item) => {
				if (
					(item.alias || item.nickName || item.userName).indexOf(
						this.searchInput.trim(),
					) != -1
				)
					arr.push(item);
			});
			res1.data.filter((item) => {
				if (item.name.indexOf(this.searchInput.trim()) != -1) arr1.push(item);
			});
			this.friendToal = arr.length;
			this.groupToal = arr1.length;
			if (this.isAll) {
				//看所有
				this.searchData = [[...arr], [...arr1]];
				console.log('过滤所有的有的', arr, arr1);
			} else {
				// 看1-5个
				this.searchData = [[...arr.splice(0, 5)], [...arr1.splice(0, 5)]];
				console.log('过滤1-5所有的有的', arr.splice(0, 5), arr1.splice(0, 5));
			}
			console.log('操作搜索框....', this.searchData, res.data, res1.data);
		},
		// 展开全部点击
		allClick() {
			this.isAll = !this.isAll;
			this.getImChatList();
			console.log('展开全部点击');
		},
		// 高亮字
		setActiveStr(str, inputStr, numStr, nick) {
			let innerArr = '';
			let regex = new RegExp(inputStr.trim(), 'g'); // 创建一个全局正则表达式
			let index = str.indexOf(inputStr.trim());
			//正则匹配替换高亮字符
			if (index == -1) {
				innerArr = str;
			} else {
				innerArr = str.replace(
					regex,
					`<b style="color:#007FFF">${inputStr.trim()}</b>`,
				); // 使用高亮样式替换匹配到的子字符串
			}
			if (nick) innerArr = '昵称:' + innerArr;
			return numStr ? innerArr + numStr : innerArr;
		},
		//操作搜索框
		searchChange(e) {
			if (e) {
				this.getImChatList();
				this.searchUserGroup = true;
				this.isAll = false;
			} else {
				// this.$message.info('想要搜索的名字不能为空')
				this.searchUserGroup = false;
			}
		},

      debounce(func, wait) {
         let timeout
         return (...args) => {
         const context = this // 使用箭头函数保持上下文
         clearTimeout(timeout) // 清除之前的定时器
         timeout = setTimeout(() => {
            func.apply(context, args) // 在延迟后调用目标函数
         }, wait)
         }
      },

		throttleSearchChange(e) {
			this.debouncedSearchChange(e); // 调用存储的防抖函数
		},
	},
};
</script>

<style lang="scss" scoped>
.searchInput-main {
	width: 100%;
	position: relative;
	.all-btn {
		width: 100%;
		color: $theme-color;
		font-size: 14px;
		padding-top: 10px;
		text-align: left;
		position: absolute;
		padding: 2px 20px 10px;
		background: #fff;
		bottom: 0;
		left: 0;
		box-sizing: border-box;
		cursor: pointer;
	}
	b {
		color: $theme-color;
	}
	.search-user-group-close {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
	}
	.search-user-group {
		width: 238px;
		max-height: 780px;
		border-radius: 10px;
		box-sizing: border-box;
		padding: 16px 20px 18px;
		opacity: 1;
		background: #ffffff;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
		position: absolute;
		z-index: 1002;
		top: 55px;
		left: 0;
		overflow: hidden;
		z-index: 1001;
		.no-search-img {
			width: 100%;
			box-sizing: border-box;
			padding: 10px 20px;
		}
		.search-list-scroll {
			width: 200px;
			max-height: 738px;
			box-sizing: border-box;
			padding-bottom: 6px;
			overflow-y: scroll;
			overflow-x: hidden;
			.search-list-scroll-box {
				width: 100%;
			}
		}
		.search-list {
			width: 100%;
			.search-list-title {
				color: #999999;
				font-size: 12px;
				width: 100%;
				height: 30px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e7e7e7;
			}
			.search-items {
				width: 100%;
				.search-item {
					width: 100%;
					height: 44px;
					padding: 10px 0;
					display: flex;
					align-items: center;
					cursor: pointer;
					.search-item-img {
						width: 44px;
						height: 44px;
						border-radius: 8px;
						margin-right: 15px;
					}
					.search-item-user {
						width: 115px;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						.over-hidden {
							white-space: nowrap;
							overflow: hidden; /* 隐藏超出容器的内容 */
							text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
						}
						p {
							width: 100%;
							color: #222222;
							font-size: 16px;
							font-weight: 500;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2; /* 定义文本的行数 */
							overflow: hidden;
							text-overflow: ellipsis;
							b {
								color: $theme-color;
							}
							//line-height: 1.2rem;
						}
						span {
							color: #999999;
							margin-top: 6px;
							font-size: 12px;
							b {
								color: $theme-color;
							}
						}
					}
				}
			}
		}
	}
}

.searchInput {
	width: 100%;
	height: 42px;
	border-radius: 8px;
	opacity: 1;
	box-sizing: border-box;
	// border: 1px solid #DEE0E3;

	background: #fff;
}
</style>
<style lang="scss">
.searchInput-com .el-input__inner {
	background: #fff !important;
	border: 1px solid #dee0e3;
	border-radius: 8px;
	width: 100%;
	height: 42px;
	border-radius: 8px;
}
.searchInput-com .el-input__icon {
	line-height: 100%;
	font-weight: bold;
	color: #909399;
	margin-left: 6px;
	font-size: 16px;
}

.searchInput-com input::-webkit-input-placeholder {
	/* Chrome, Opera, Safari */
	font-size: 14px;
	color: #999;
	font-weight: 500;
}

.searchInput-com input:-moz-placeholder {
	/* Firefox 18- */
	font-size: 14px;
	color: #999;
	opacity: 1; /* 修复老版本Firefox的不透明度问题 */
	font-weight: 500;
}

.searchInput-com input::-moz-placeholder {
	/* Firefox 19+ */
	font-size: 14px;
	color: #999;
	opacity: 1; /* 修复Firefox的不透明度问题 */
	font-weight: 500;
}

.searchInput-com input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	font-size: 14px;
	color: #999;
	font-weight: 500;
}
</style>
