<template>
	<div class="notificationContent_card" @click="handleToDetail">
		<!-- 系统通知 -->
		<subType_system :item="item" v-if="item.systemType === 'system'" />
		<!-- 商品通知 -->
		<subType_shop v-if="item.systemType === 'shop'" :content="item" />
		<!-- 支付通知 -->
		<subType_wallet v-if="item.systemType === 'wallet'" :content="item" />
		<!-- mcn通知 -->
		<subType_mcn v-if="item.systemType === 'mcn'" :content="item" />
	</div>
</template>
<script>
import subType_system from './subType_system.vue';
import subType_wallet from './subType_wallet.vue';
import subType_shop from './subType_shop.vue';
import subType_mcn from './subType_mcn.vue';
import URL from '@/utils/baseUrl';
export default {
	props: ['item'],
	components: { subType_system, subType_wallet, subType_shop, subType_mcn },
	data() {
		return {};
	},

	methods: {
		handleToDetail() {
			switch (this.item.systemType) {
				case 'system':
					this.handleSystemDetail();
					break;
				case 'shop':
					this.$emit('toDownload');
					break;
				case 'wallet':
					this.$emit('toDownload');
					break;
				case 'mcn':
					this.$emit('toDownload');
					break;
			}
		},
		handleSystemDetail() {
			switch (this.item.type) {
				case 'bail':
					if (!["bailPayWarn"].includes(this.item.content.type)) return;
					window.open(
						`${URL.ADMIN_URL}/user_content/authentication?token=${this.$store.state.token}&roleIdentifier=article`,
					);
					break;
				case 'report':
					if (['reportPass','reportRefuse'].includes(this.item.content.type)) return;
					window.open(
						`${URL.ADMIN_URL}/content-manage/cm-works?token=${this.$store.state.token}&roleIdentifier=article`,
					);
					break;
				case 'personalAuth':
					window.open(
						`${URL.ADMIN_URL}/user_content/authentication?token=${this.$store.state.token}&roleIdentifier=article`,
					);
					break;
				case 'account':
					window.open(
						`${URL.ADMIN_URL}/user_content/account_security?token=${this.$store.state.token}&roleIdentifier=article`,
					);
					break;
				case 'industryApply':
					window.open(
						`${URL.ADMIN_URL}/user_content/role_settling?token=${this.$store.state.token}&roleIdentifier=article`,
					);
					break;
				case "roleAuth":
					window.open(
						`${URL.ADMIN_URL}/user_content/roleAuth?token=${this.$store.state.token}&roleIdentifier=article`,
					);
					break;
				default:
					this.$emit('toDownload');
					break;
			}
		},
	},
};
</script>
<style lang="scss">
.notificationContent_card {
	background: #ffffff;
	border-radius: 8px;
	padding: 16px;
	box-sizing: border-box;
}
</style>
