<template>
	<div :class="[{ rightMsg: itMe }, 'chat-msg-item']" @click="messageItemClick">
		<div class="chat-msg-item-time" v-if="isShowTime">
			{{ messageCreateTime }}
		</div>
		<!-- 消息提示 -->

		<div
			class="chat-msg-item-time"
			v-if="
				item.messageType == 'command' ||
				(item.messageType == 'voiceCall' && item.sessionType == 'group') ||
				item.messageType == 'msg'
			"
		>
			<div v-if="calcMommand">
				{{ item.body.content }}
			</div>
		</div>

		<div
			class="chat-msg-item-content"
			v-else
			v-show="!(item.body.err && $store.state.userInfo.id != item.sender.id)"
		>
			<div class="userAvatar" @click="handleToUserDetail(item)">
				<el-avatar
					class="userAvatar-item"
					:src="item.sender.avatar"
				></el-avatar>
			</div>

			<el-popover
				popper-class="messageMenu"
				placement="bottom"
				trigger="manual"
				v-model="visible"
				disabled
			>
				<div
					class="messageMenuBox"
					@mouseenter="handleMouseEnter"
					@mouseleave="handleMouseLeave"
				>
					<div @click="messageMenuClick('撤回')">
						<i class="icon-sohu-chehui"></i>
						<p>撤回</p>
					</div>
					<!-- <div @click="messageMenuClick('回复')">
                                <i class="icon-sohu-xiaoxi1"></i>
                                <p>回复</p>
                              </div>
                              <div @click="messageMenuClick('删除')">
                                <i class="icon-sohu-shanchu"></i>
                                <p>删除</p>
                              </div> -->
				</div>
				<div
					slot="reference"
					class="messageBody"
					@mouseenter="handleMouseEnter"
					@mouseleave="handleMouseLeave"
					ref="messageBodyRef"
				>
					<el-tooltip effect="dark" :content="item.body.err" placement="top">
						<i
							class="el-icon-warning"
							v-if="item.body.err"
							@click="errorClick"
						></i>
					</el-tooltip>
					<!-- 群聊显示昵称和角色 -->
					<div
						class="senderName"
						v-if="
							[
								'group',
								'groupTask',
								'groupTaskCustom',
								'groupForm',
								'groupFormCustom',
								'groupFromGeneral',
							].includes(item.sessionType)
						"
					>
						<!-- {{ userSendName }} -->
						{{ formatNickName(item, userSendName) }}

						<span
							class="roleTag"
							:class="[
								{ leader: roleName == '群主' },
								{ admin: roleName == '管理员' },
							]"
							v-if="roleName"
						>
							{{ roleName }}
						</span>
					</div>
					<!-- 图片 -->
					<div
						v-if="item.messageType == 'photo'"
						class="photo"
						@contextmenu.prevent.stop="handleMessage"
					>
						<el-image
							@load="imageLoaded"
							:src="item.body.content"
							:preview-src-list="[item.body.content]"
						>
						</el-image>
					</div>
					<!-- 视频 -->
					<div
						v-else-if="item.messageType == 'video'"
						class="video"
						@click="videoView"
						@contextmenu.prevent.stop="handleMessage"
					>
						<video :src="item.body.content"></video>
						<i class="icon-sohu-bofang"></i>
					</div>
					<!-- 语音 -->
					<div
						v-else-if="item.messageType == 'voice'"
						:class="[
							{ smallVoice: item.body.duration > 10 },
							{ mediumVoice: item.body.duration > 50 },
							'voice',
						]"
						@click="playAudio"
					>
						<i class="icon-sohu-yuyinbofang playing" v-if="!playing"></i>
						<el-image
							class="playing"
							v-else
							:src="require('@/assets/images/chat/playing.gif')"
						></el-image>
						<span class="duration"> {{ item.body.duration }}" </span>
					</div>
					<!-- 分享卡片 -->
					<div v-else-if="item.messageType == 'share'" class="share">
						<!--    task("task", "任务"),
                                            taskGuide("taskGuide", "任务咨询"),
                                            good("good", "商品"),
                                            article("article", "图文"),
                                            video("video", "视频"),
                                            person("person", "分享名片"),
                                            invite("invite", "邀请"),
                                            goodWindow("goodWindow", "商品橱窗"),
                                            answer("answer", "回答"),
                                            question("question", "问题"), -->
						<!-- 任务 -->
						<!--						v-if="['taskGuide'].includes(item?.share?.type)"-->
						<div v-if="false" class="share_taskGuide" @click="shareCardClick">
							<div class="share_taskGuide_content">
								<el-image
									:src="require('@/assets/images/chat/taskImg.png')"
								></el-image>
								<div class="right_taskGuide_content">
									<div class="title line-2">
										{{ item.share.title }}
									</div>
									<div class="price">
										<span>￥</span>
										{{ item.share.amount }}
									</div>
								</div>
							</div>
							<div class="share_taskGuide_user">
								来自{{ item.sender.name }}的分享
							</div>
						</div>

						<div
							v-else-if="['task', 'playlet'].includes(item?.share?.type)"
							class="share_video"
							@click="shareCardClick"
							@contextmenu.prevent.stop="handleMessage"
						>
							<div class="content_box">
								<div class="content_text">
									<div class="content_title line-2">
										<span v-if="item?.share?.type == 'task'">
											{{ item.share.name }}邀请您加入任务“{{
												item.share.title
											}}”快乐赚钱吧
										</span>
										<span v-else>快来看看作品「{{ item.share.title }}」</span>
									</div>
									<div class="content_userData">
										<el-avatar :size="20" :src="item.share.avatar"></el-avatar>
										<div class="username line-1">{{ item.share.name }}</div>
									</div>
								</div>
								<el-image
									:src="
										item?.share?.type == 'task'
											? require('@/assets/images/chat/taskShare.png')
											: item.share.shareParam
											? JSON.parse(item.share.shareParam).videoThumbnail
											: require('@/assets/images/logo_x2.png')
									"
									fit="cover"
								></el-image>
							</div>
							<div class="share_title">狐少少</div>
						</div>
						<!--流量商单-->
						<div
							v-else-if="['flowTask', 'taskGuide'].includes(item?.share?.type)"
							class="share-flow"
							@click="shareCardClick"
						>
							<el-image
								class="cover"
								:src="require('@/assets/images/task-bgc.png')"
							></el-image>
							<div class="info-view">
								<div class="title">{{ item.share.title }}</div>
								<div class="price">赚 ¥{{ item.share.amount }}</div>
							</div>
						</div>
						<!-- 视频 -->
						<div
							v-else-if="['Video', 'article'].includes(item?.share?.type)"
							class="share-video"
							@click="shareCardClick"
						>
							<el-image
								style="width: 100%; height: 100%"
								:src="item.share.coverImage"
							></el-image>
							<div class="desc">
								<div class="value">{{ item.share.title }}</div>
							</div>
							<i
								class="icon icon-sohu-bofang"
								v-if="['Video'].includes(item?.share?.type)"
							></i>
						</div>
						<!-- 名片 -->
						<div
							v-else-if="item?.share?.type == 'person'"
							class="share_person"
							@click="shareCardClick"
							@contextmenu.prevent.stop="handleMessage"
						>
							<div class="userData">
								<el-image
									class="leftImg"
									:src="item.share.avatar"
									fit="cover"
								></el-image>
								<div class="username line-1">
									{{ item.share.name }}
								</div>
							</div>
							<div class="share_user">个人名片</div>
						</div>

						<!-- 商品 -->
						<div
							v-else-if="['good'].includes(item?.share?.type)"
							class="share_video"
							@click="shareCardClick"
						>
							<div class="content_box">
								<div class="content_text">
									<div class="content_title line-2">
										快来看看商品「{{ item.share.title }}」
									</div>
									<div class="content_userData">
										<el-avatar
											:size="20"
											:src="item.share.shareUserAvatar"
										></el-avatar>
										<div class="username line-1">
											{{ item.share.shareUserName }}
										</div>
									</div>
								</div>
								<el-image :src="item.share.coverImage" fit="cover"></el-image>
							</div>
							<div class="share_title">狐少少</div>
						</div>

						<!-- 邀请 -->
						<div
							v-else-if="['invite'].includes(item?.share?.type)"
							class="share_video"
							@click="shareCardClick"
						>
							<div class="content_box">
								<div class="content_text">
									<div class="content_title line-2">
										{{ item.share.shareUserName }}邀请您加入狐少少一起做任务赚钱
									</div>
									<div class="content_userData">
										<el-avatar
											:size="20"
											:src="item.share.shareUserAvatar"
										></el-avatar>
										<div class="username line-1">
											{{ item.share.shareUserName }}
										</div>
									</div>
								</div>
								<el-image
									:src="require('@/assets/images/chat/inviteShare.png')"
									fit="cover"
								></el-image>
							</div>
							<div class="share_title">狐少少</div>
						</div>

						<!-- 未处理的类型 -->
						<div class="chat-msg-text" v-html="content" v-else></div>
					</div>

					<!-- 关于通话  开始-------------------------------------- -->
					<div
						v-else-if="
							item.messageType == 'videoCall' && item.sessionType != 'group'
						"
					>
						<!-- 通话类型  视频 -->
						<div class="conversation">
							<div v-if="!itMe">
								<img
									style="width: 30px; margin-right: 7px"
									:src="videoImagesNoMe"
									alt=""
								/>
							</div>
							<span v-if="item?.mediaCall?.type == 'cancel'">
								{{ itMe ? '已取消' : '对方已取消' }}
							</span>
							<span v-if="item?.mediaCall?.type == 'finish'">
								通话时长：{{ formatTime(item.body.duration) }}
							</span>
							<span v-if="item?.mediaCall?.type == 'refuse'">
								{{ itMe ? '对方已拒绝' : '已拒绝' }}
							</span>
							<span v-if="item?.mediaCall?.type == 'busy'">对方忙线中</span>
							<span
								v-if="
									item?.mediaCall?.type == 'timeOut' ||
									item?.mediaCall?.type == 'timeout'
								"
							>
								{{ itMe ? '对方无应答' : '未应答' }}
							</span>
							<div v-if="itMe">
								<img
									style="width: 30px; margin-left: 7px; margin-top: -2px"
									:src="videoImages"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div
						class="conversation"
						v-else-if="
							item.messageType == 'voiceCall' && item.sessionType != 'group'
						"
					>
						<!-- 通话类型   -->
						<div v-if="!itMe">
							<img
								style="width: 30px; margin-right: 7px"
								:src="voiceCallImages"
								alt=""
							/>
						</div>
						<span v-if="item?.mediaCall?.type == 'cancel'">
							{{ itMe ? '已取消' : '对方已取消' }}
						</span>
						<span v-if="item?.mediaCall?.type == 'finish'">
							通话时长：{{ formatTime(item.body.duration) }}
						</span>
						<span v-if="item?.mediaCall?.type == 'refuse'">
							{{ itMe ? '对方已拒绝' : '已拒绝' }}
						</span>
						<span v-if="item?.mediaCall?.type == 'busy'">对方忙线中</span>
						<span
							v-if="
								item?.mediaCall?.type == 'timeOut' ||
								item?.mediaCall?.type == 'timeout'
							"
						>
							{{ itMe ? '对方无应答' : '未应答' }}
						</span>
						<div v-if="itMe">
							<img
								style="width: 30px; margin-left: 7px"
								:src="voiceCallImages"
								alt=""
							/>
						</div>
					</div>
					<!-- 关于通话  结束-------------------------------------- -->
					<!-- 文件 -->
					<div
						v-else-if="['file'].includes(item.messageType)"
						class="file-card"
						@click="handleToFileDetail"
						@contextmenu.prevent.stop="handleMessage"
					>
						<div class="info-view">
							<div class="title">{{ item.file.fileName }}</div>
							<div class="size">
								{{ util.fileSizeFilter(item.file.fileSize) }}
							</div>
						</div>
						<el-image :src="fileIconFilter(item.file.fileName)"></el-image>

						<div
							class="handle-view"
							v-if="!item.body.err && item.file.status !== 'expired'"
						>
							<i
								class="el-icon-loading"
								v-if="item.file.status && item.file.status === 'loading'"
							></i>
							<i
								class="icon-sohu-xiazai1"
								v-else
								@click.stop="handleDownload(item)"
							></i>
						</div>
						<div class="file-tip" v-if="item.file.status === 'expired'">
							文件已过期或已被清理
						</div>
					</div>
					<div
						class="chat-msg-text"
						v-html="content"
						@contextmenu.prevent.stop="handleMessage"
						v-else
					></div>
				</div>
			</el-popover>
		</div>
		<!-- 个人名片弹窗 -->
		<businessCardDialog ref="businessCardDialogRef"></businessCardDialog>
		<!--下载App-->
		<download_dialog v-model="downloadShow" />
	</div>
</template>
<script>
import baseUrl from '@/utils/baseUrl';
import util from '@/utils/util';
import { MessageEnum } from '@/views/chat/enum/message';
import { CommandEnum } from '@/views/chat/enum/command';
import { MediaCallEnum } from '@/views/chat/enum/mediaCall';
import BusinessCardDialog from '@/views/chat/components/user/businessCardDialog.vue';
import ForwardDialog from '@/views/chat/components/chat/forwardDialog.vue';
import Download_dialog from '@/views/systemNotification/components/download_dialog.vue';

export default {
	components: { BusinessCardDialog, ForwardDialog, Download_dialog },
	props: [
		'item',
		'prev',
		'detail',
		'role',
		'itemInfo',
		'userList',
		'isTaskChat',
	],
	data() {
		return {
			audio: '',
			playing: false,
			visible: false,
			timer: null,
			activeChatId: '',
			// 视频
			videoImages:
				'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/12/30/8ca44331aac642cf80acad0bedd398ce_52x52.png',
			videoImagesNoMe:
				'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/12/30/9872d32e14c342579bd1009216160bd4_52x52.png',
			// 语音
			voiceCallImages:
				'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/12/30/9c27f4afac4643a4baf95849accb6df0_52x51.png',
			downloadShow: false,
		};
	},
	computed: {
		util() {
			return util;
		},
		itMe() {
			return this.item.sender.id == this.$store.state.userInfo.id;
		},
		// 消息类容
		content() {
			let str = '';
			if (this.item.messageType == 'text') {
				var httpReg =
					/(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi;
				str = this.item.body.content.replace(httpReg, function (httpText) {
					let url = '';
					if (!httpText.includes('http')) {
						url = '//' + httpText;
					} else {
						url = httpText;
					}
					return "<a href='" + url + "' target='_blank'>" + httpText + '</a>';
				});
			} else {
				str = '[该消息暂未支持，请在App内查看]';
			}
			return str;
		},
		roleName() {
			switch (this.item.sender.role) {
				case 'group_leader':
					return '群主';
				case 'group_admin':
					return '管理员';
			}
		},

		// 消息时间
		messageCreateTime() {
			return this.$util.toTimeText(this.item.createTime, false);
		},

		// 是否显示时间
		isShowTime() {
			if (this.prev) {
				return this.item.createTime - this.prev.createTime > 1000 * 60 * 5;
			} else {
				return false;
			}
		},
		userInfo() {
			return this.$store.state.userInfo;
		},

		userSendName() {
			let name;
			this.userList.forEach((item) => {
				if (item.userId == this.item.sender.id) {
					name =
						item.groupUserNickName ||
						item.alias ||
						item.nickName ||
						item.userName;
				}
			});
			return name;
		},
		calcMommand() {
			return (
				![CommandEnum.groupCall, CommandEnum.groupRoomRefresh].includes(
					this.item.commandType,
				) &&
				![MediaCallEnum.leave].includes(
					this.item.mediaCall ? this.item?.mediaCall?.type : '',
				)
			);
		},
	},
	beforeDestroy() {
		if (this.audio) {
			this.playing = false;
			this.audio.pause();
			this.audio = null;
		}
	},
	methods: {
		formatTime(seconds) {
			const minutes = Math.floor(seconds / 60);
			const remainingSeconds = seconds % 60;
			return `${minutes < 10 ? '0' : ''}${minutes}:${
				remainingSeconds < 10 ? '0' : ''
			}${remainingSeconds}`;
		},
		messageItemClick() {
			this.$emit('messageItemClick', this.item);
		},
		//操作消息
		handleMessage(event) {
			console.log(this.item, '右键');
			const isHandleList = [
				MessageEnum.file,
				MessageEnum.text,
				MessageEnum.share,
				MessageEnum.photo,
				MessageEnum.video,
			];
			//为不可操作类型 则不进行下一步
			if (!isHandleList.includes(this.item.messageType)) return;
			const list = [];
			//是否超过两分钟
			const isTwoOvertime = this.isLessThanTwoMinutesAgo(this.item.createTime);
			//是否超过24小时
			const isDayOvertime = this.isLessThanOneDayAgo(this.item.createTime);
			//是否为自己发送
			const isMe = this.item.sender.id === this.userInfo.id;
			//是否为群主消息
			const isMsgLeader = this.item.sender.role === 'group_leader';
			//是否为管理员消息
			const isMsgAdmin = this.item.sender.role === 'group_admin';
			//个人是否可撤回消息
			const isUserRecall = isTwoOvertime && isMe;
			//群主是否可撤回消息
			const isLeaderRecall =
				isDayOvertime && ['group_leader'].includes(this.role);
			//管理员可撤回消息
			const isAdminRecall =
				isDayOvertime &&
				['group_admin'].includes(this.role) &&
				!isMsgLeader &&
				(isMe || !isMsgAdmin);
			//是否为发送失败消息
			const isErrMsg = !!this.item.body.err;
			//为文字消息则可复制
			if ([MessageEnum.text].includes(this.item.messageType))
				list.push({
					title: '复制',
					event: 'copy',
					icon: 'icon-sohu-fuzhi2',
				});
			//为发送失败则不可转发
			if (!isErrMsg)
				list.push({
					title: '转发',
					event: 'forward',
					icon: 'icon-sohu-zhuanfa1',
				});
			list.push({ title: '删除', event: 'delete', icon: 'icon-sohu-shanchu2' });
			//未超过撤回时间限制 且 不是发送失败消息 则 可以撤回
			if ((isLeaderRecall || isAdminRecall || isUserRecall) && !isErrMsg)
				list.push({
					title: '撤回',
					event: 'recall',
					icon: 'icon-sohu-chehui',
				});
			const x = event.pageX + 'px';
			const y = event.pageY + 'px';
			this.$emit(
				'contextmenu',
				{
					message: this.item,
					x,
					y,
				},
				list,
			);
		},

		isLessThanTwoMinutesAgo(timestamp) {
			// 获取当前时间的时间戳（毫秒）
			const now = Date.now();
			// 计算当前时间与给定时间戳之间的差值（毫秒）
			const diff = now - timestamp;
			// 将两分钟转换为毫秒
			const twoMinutesInMs = 2 * 60 * 1000;
			// 判断差值是否小于两分钟
			return diff < twoMinutesInMs;
		},

		isLessThanOneDayAgo(timestamp) {
			// 获取当前时间的时间戳（毫秒）
			const now = Date.now();
			// 计算当前时间与给定时间戳之间的差值（毫秒）
			const diff = now - timestamp;
			// 将24小时转换为毫秒
			const oneDayInMs = 24 * 60 * 60 * 1000;
			// 判断差值是否小于24小时
			return diff < oneDayInMs;
		},

		//转发操作
		forwardOperate() {
			this.$emit('forward', this.item);
		},

		/**
		 * 前往文件详情
		 */
		handleToFileDetail() {
			if (this.item.file.status === 'expired')
				return this.$message.info('文件已过期不可预览');
			const strList = this.item.file.fileName.split('.');
			const fileType = strList[strList.length - 1];
			console.log(fileType, 'fileType');
			const testFileSrc = {
				doc: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/8e4262249ea14fdb8fcf0f38664e9312_0x0.doc',
				docx: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/7d9c3e7a2379423fbd68cf0bbf29ebaa_0x0.docx',
				xls: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/d03cc27c758841839af03dcb368028e8_0x0.xls',
				xlsx: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/abc87d28df9644de8e0330fcbc75cac8_0x0.xlsx',
				ppt: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/4c72c8b65ec5463ebacaf8f666ebf1e0_0x0.ppt',
				pptx: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/c8200200591f40fb819773448eead496_0x0.pptx',
				pdf: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/c1615ea1e926433990c87bd563e3791d_0x0.pdf',
			};
			const filePreviewUrl =
				'https://view.officeapps.live.com/op/view.aspx?spm=a2c6h.12873639.article-detail.4.18756556S0kKlX&src=';
			const regex = /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/;
			if (regex.test(this.item.body.content)) {
				window.open(
					fileType === 'pdf'
						? this.item.body.content
						: filePreviewUrl + this.item.body.content,
				);
			} else {
				window.open(
					fileType === 'pdf'
						? testFileSrc[fileType]
						: filePreviewUrl + testFileSrc[fileType],
				);
			}
		},
		/**
		 * 操作下载文件
		 * @param model
		 */
		handleDownload(model) {
			const headers = new Headers({
				Authorization: `Bearer ${this.$store.state.token}`,
				TenantId: process.env.VUE_APP_TENANT,
			});
			fetch(
				`${baseUrl.BASE_URL}/resource/oss/download/url?pathUrl=${model.body.content}`,
				{
					method: 'get',
					headers,
				},
			)
				.then(async (response) => {
					// 检查HTTP响应状态码
					if (response.status === 200) {
						const blob = await response.blob();
						if (blob.type === 'application/octet-stream') {
							return blob;
						} else {
							throw new Error('File is not available or has expired.');
						}
					} else {
						throw new Error('File is not available or has expired.');
					}
				})
				.then((blob) => {
					// 进行文件完整性检查（例如哈希值验证）
					// 如果文件损坏或过期，抛出异常
					// 否则，执行下载操作
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = model.file.fileName;
					link.target = '_blank';
					link.click();
				})
				.catch((error) => {
					console.log(error, 'catch');
					this.$set(this.item.file, 'status', 'expired');
					console.log(this.item);
				});
		},
		/**
		 * 文件图标过滤器
		 * @param fileName
		 * @returns {string}
		 */
		fileIconFilter(fileName) {
			if (!fileName) return '';
			const strList = fileName.split('.');
			const fileType = '.' + strList[strList.length - 1];
			if (['.doc', '.docx'].includes(fileType)) {
				return require('@/assets/images/chat/file-word.png');
			} else if (['.xls', '.xlsx'].includes(fileType)) {
				return require('@/assets/images/chat/file-xlsx.png');
			} else if (['.ppt', '.pptx'].includes(fileType)) {
				return require('@/assets/images/chat/file-ppt.png');
			} else if (['.pdf'].includes(fileType)) {
				return require('@/assets/images/chat/file-pdf.png');
			}
		},
		/**
		 * 操作前往用户详情
		 * @param model
		 */
		handleToUserDetail(model) {
			let itemData = {};
			this.userList.forEach((item) => {
				if (item.userId == model.sender.id) {
					itemData = item;
				}
			});

			console.log('itemData: ', itemData);
			if (this.isTaskChat && !this.detail.meFriend && !this.detail.himFriend)
				if (this.isTaskChat && !this.detail.meFriend && !this.detail.himFriend)
					return;
			if (this.itMe) return;

			let sessionType = this.detail.sessionType == 'group';
			// 接单方
			let flag = false;
			if (this.detail.specialRole == 'taskRece' && !sessionType) {
				flag = true;
			}
			// 发单方
			if (this.detail.specialRole == 'taskPublish' && !sessionType) {
				if (itemData.specialRole == 'taskRece') {
					flag = true;
				}
			}
			// 普通人
			if (!this.detail.specialRole && !sessionType) {
				if (itemData.permissionType != 'group_leader') {
					flag = true;
				}
			}

			console.log('flag: ', flag);
			// 打码成员不可点击头像
			const formattedName = this.formatNickName(model, this.userSendName);

			console.log('formattedName: ', formattedName);
			const isEncrypted = formattedName.includes('**');
			console.log('isEncrypted: ', isEncrypted);

			if (flag || isEncrypted) {
				return;
			}

			if (this.isMyinGroup) {
				const isGroup = ['group', 'groupTask'].includes(
					this.detail.sessionType,
				); //是否为群聊
				const isAuth = ['group_leader', 'group_admin'].includes(this.role); //是否为管理员 或 群主
				const isAddFriend = this.detail.addFriend; //是否可进行相互添加好友
				if (isGroup && isAddFriend && !isAuth) {
					this.$message.info('当前群聊已设置禁止添加好友');
				} else {
					this.$refs.businessCardDialogRef.open(model.sender.id);
				}
			} else {
				this.$refs.businessCardDialogRef.open(model.sender.id);
			}
		},

		//  处理名称
		formatNickName(item, name) {
			// 普通群groupType ：group
			if (!name || this.detail.groupType == 'group') return name;

			// 字符长度是否大于1
			if (name.length <= 1) return nameStr;

			// 当前角色是 发单方  taskPublish/ 接单方taskRece
			let specialRole = this.detail.specialRole;
			// 是否是自己
			let isAdmin = item.userId == this.userInfo.id;

			let nameStr = name;
			// 发单方视角:
			// 所有成员全部打码，接单方昵称不打码
			if (specialRole == 'taskPublish') {
				if (this.groupRole == 'subGroup') {
					if (item.specialRole != 'taskRece' && !isAdmin) {
						nameStr = name.charAt(0) + '**';
					}
				}
			}
			// 接单方 自己和发单方不打码 群主不打码
			if (specialRole == 'taskRece') {
				if (
					!isAdmin &&
					item.specialRole != 'taskPublish' &&
					this.groupRole != 'subLeader'
				) {
					nameStr = name.charAt(0) + '**';
				}
			}
			// 普通成员  除自己和群主之外的所有成员昵称均打码，显示为“首字符+**”
			if (!specialRole) {
				if (item.permissionType != 'group_leader' && !isAdmin) {
					nameStr = name.charAt(0) + '**';
				}
			}
			return nameStr;
		},
		// 图片加载完成
		imageLoaded() {
			this.$emit('imageLoad');
		},

		// 视频预览
		videoView() {
			window.open(this.item.body.content);
		},

		// 语音播放
		playAudio() {
			if (!this.audio) {
				this.audio = new Audio(this.item.body.content);
			}
			//监听语音
			this.audio.addEventListener('ended', () => {
				this.playing = false;
				this.audio.pause();
				console.log('Audio ended');
			});
			if (!this.playing) {
				this.playing = true;
				this.audio.play();
			}
		},

		// 鼠标右键事件
		showMenu() {
			if (this.itMe) {
				clearTimeout(this.timer);
				this.visible = true;
			}
		},

		// 鼠标移入菜单
		handleMouseEnter() {
			if (this.visible) {
				clearTimeout(this.timer);
			}
		},

		// 鼠标移出菜单
		handleMouseLeave() {
			if (this.visible) {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.visible = false;
				}, 1000);
			}
		},

		// 点击菜单
		messageMenuClick(type) {
			clearTimeout(this.timer);
			this.visible = false;
		},

		// 分享卡片
		shareCardClick() {
			// if (1 === 1) return this.$emit("forward", this.item);
			let link;
			switch (this.item?.share?.type) {
				case 'task':
					break;
				case 'taskGuide':
					link = this.$router.resolve({
						path: '/makeMoney/taskDetails',
						query: {
							taskNumber: this.item.share.content,
							source: '任务广场',
						},
					});
					break;
				case 'flowTask':
					this.downloadShow = true;
					break;
				case 'Video':
					link = this.$router.resolve({
						path: '/videoShare',
						query: {
							id: this.item.share.id,
						},
					});
					break;
				case 'article':
					link = this.$router.resolve({
						path: '/imageTextDetail',
						query: {
							id: this.item.share.id,
						},
					});
					break;
				case 'person':
					if (this.item.share.id === this.userInfo.id) return;
					this.$refs.businessCardDialogRef.open(this.item.share.id);
					break;
				case 'playlet':
					link = this.$router.resolve({
						path: '/shortPlayDetail',
						query: {
							episodeRelevance: this.item.share.episodeRelevance,
						},
					});
					break;
			}
			link && window.open(link.href, '_blank');
		},

		// 消息报错，重新发送
		errorClick() {
			let sendParams = {};
			switch (this.item.messageType) {
				case 'share':
					// sendParams.shareType = this.item.share.type;
					sendParams.shareId = this.item.share.id;
					// sendParams.shareParam = this.item.share.shareParam;
					sendParams.share = {
						amount: this.item.share.amount,
						content: this.item.share.content,
						id: this.item.share.id,
						shareUserId: this.$store.state.userInfo.id,
						title: this.item.share.title,
						type: this.item?.share?.type,
						userId: this.item.share.userId,
					};
					break;
			}
			this.$emit('errorClick', {
				localId: this.item.localId,
				messageType: this.item.messageType,
				content: this.item.body.content,
				...sendParams,
			});
		},
	},
};
</script>
<style lang="scss">
.chat-msg-item {
	margin-top: 24px;

	.chat-msg-item-time {
		text-align: center;
		margin-bottom: 20px;
		color: #bbb;
		font-size: 14px;
	}

	.chat-msg-item-content {
		display: flex;

		.userAvatar {
			width: 44px;
			height: 44px;
			margin-right: 8px;
			// margin-left: 20px;
			margin-left: 0;
			cursor: pointer;

			.userAvatar-item {
				width: 44px;
				height: 44px;
				border-radius: 8px;
			}
		}

		.messageBody {
			position: relative;

			.el-icon-warning {
				position: absolute;
				left: -5px;
				top: 50%;
				transform: translate(-100%, -50%);
				color: #f56c6c;
				font-size: 24px;
				cursor: pointer;

				&:hover {
					opacity: 0.8;
				}
			}

			.senderName {
				color: #999999;
				font-size: 12px;
				margin-bottom: 5px;

				.roleTag {
					background: #d9e1ff;
					color: #4787f0;
					font-size: 12px;
					padding: 1px 8px;
					border-radius: 2.86px;
					margin-left: 7px;
					line-height: 18px;
					display: inline-block;

					&.leader {
						opacity: 1;
						background: rgba(0, 127, 255, 0.1);
						color: #007fff;
					}

					&.admin {
						background: rgba(242, 127, 0, 0.1);
						color: #007fff;
					}
				}
			}

			.file-card {
				background: #f3f3f3;
				border-radius: 4px;
				padding: 12px 14px 14px 12px;
				box-sizing: border-box;
				width: 290px;
				height: 84px;
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				position: relative;

				.info-view {
					width: 190px;

					.title {
						color: #1a1a1a;
						font-size: 14px;
						line-height: 18px;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}

					.size {
						color: #999999;
						font-size: 10px;
						line-height: 16px;
						margin-top: 7px;
					}
				}

				.el-image {
					width: 56px;
					height: 56px;
				}

				.handle-view {
					position: absolute;
					top: 50%;
					right: -27px;
					transform: translate(0, -50%);
					font-size: 20px;

					.icon-sohu-xiazai1 {
						color: #999999;
					}
				}

				.file-tip {
					position: absolute;
					bottom: -16px;
					font-size: 12px;
					color: red;
				}
			}

			.chat-msg-text {
				border-radius: 4px;
				padding: 12px 14px;
				color: $text-color;
				font-size: 14px;
				box-sizing: border-box;
				background-color: #f3f3f3;
				line-height: 20px;
				max-width: 290px;
				display: inline-block;
				text-wrap: wrap;
				white-space: pre-wrap;
				word-break: break-all;
				word-wrap: break-word;

				a {
					color: #4787f0;
					text-decoration: underline;

					&:hover {
						opacity: 0.8;
					}
				}
			}

			.photo {
				.el-image {
					border-radius: 5px;
					overflow: hidden;

					img {
						max-width: 240px;
						max-height: 180px;
						width: auto;
						height: auto;
						object-fit: fill;
					}
				}
			}

			.video {
				width: 135px;
				height: 180px;
				cursor: pointer;
				position: relative;

				video {
					width: 100%;
					height: 100%;
					border-radius: 5px;
					object-fit: cover;
				}

				i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: #fff;
					opacity: 0.8;
					font-size: 60px;
				}
			}

			.voice {
				background: #fff;
				border-radius: 4px;
				display: flex;
				align-items: center;
				padding: 10px 14px;
				color: #1a1a1a;
				font-size: 12px;
				cursor: pointer;

				i {
					font-size: 16px;
				}

				.duration {
					margin: 0 5px;
				}

				&.smallVoice {
					width: 146px;
				}

				&.mediumVoice {
					width: 246px;
				}
			}

			.share {
				cursor: pointer;

				&:hover {
					opacity: 0.8;
				}

				.share_taskGuide {
					background: #f3f3f3;
					border-radius: 4px;
					padding: 12px;
					box-sizing: border-box;
					width: 295px;
					cursor: pointer;

					.share_taskGuide_content {
						margin-bottom: 12px;
						display: flex;

						.el-image {
							border-radius: 5px;
							min-width: 64px;
							height: 64px;
							margin-right: 12px;
						}

						.right_taskGuide_content {
							display: flex;
							flex-direction: column;
							justify-content: space-between;

							.title {
								color: #1a1a1a;
								font-size: 14px;
								margin-bottom: 5px;
								line-height: 20px;
							}

							.price {
								color: #f6685d;
								font-weight: 600;
								font-size: 20px;

								span {
									font-size: 12px;
								}
							}
						}
					}

					.share_taskGuide_user {
						border-top: 1px solid #d8d8d8;
						color: #969696;
						font-size: 12px;
						padding-top: 8px;
					}
				}

				.share-video {
					width: 122px;
					height: 164px;
					border-radius: 4px;
					overflow: hidden;
					position: relative;
					.icon {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: 28px;
						color: #fff;
					}
					.desc {
						position: absolute;
						bottom: 0;
						left: 0;
						color: #fff;
						font-size: 12px;
						padding: 8px;
						box-sizing: border-box;
						.value {
							width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2; /*3表示只显示3行*/
							/* autoprefixer: off */
							-webkit-box-orient: vertical;
							/* autoprefixer: on */
						}
					}
				}

				.share-flow {
					width: 290px;
					height: 100px;
					background-color: #f3f3f3;
					border-radius: 4px;
					padding: 10px;
					box-sizing: border-box;
					display: flex;
					.cover {
						width: 80px;
						height: 80px;
						margin-right: 10px;
					}
					.info-view {
						flex: 1;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						padding: 5px 0;
						box-sizing: border-box;
						.title {
							font-size: 16px;
							color: #222;
							line-height: 22px;
							overflow: hidden; //超出盒子宽度内容，便溢出隐藏
							text-overflow: ellipsis; //用...省略号显示
							display: -webkit-box; //将对象作为弹性伸缩盒子模型显示
							-webkit-box-orient: vertical; //从上到下垂直排列子元素
							-webkit-line-clamp: 2; //显示的行数
						}
						.price {
							margin-top: 7px;
							line-height: 22px;
							font-size: 16px;
							color: #f94040;
						}
					}
				}

				.share_video {
					background: #f3f3f3;
					border-radius: 4px;
					width: 295px;
					height: 121px;
					padding: 12px;
					box-sizing: border-box;

					.content_box {
						display: flex;
						justify-content: space-between;

						.content_text {
							display: flex;
							flex-direction: column;
							justify-content: space-between;

							.content_title {
								font-size: 13px;
								color: #3d3d3d;
								line-height: 18px;
								margin-right: 50px;
							}

							.content_userData {
								display: flex;
								align-items: center;

								.username {
									color: rgba(0, 0, 0, 0.6);
									font-size: 10px;
									margin-left: 5px;
								}
							}
						}

						.el-image {
							border-radius: 5px;
							min-width: 64px;
							height: 64px;
						}
					}

					.share_title {
						border-top: 1px solid #d8d8d8;
						color: #969696;
						font-size: 12px;
						padding-top: 8px;
						margin-top: 14px;
					}
				}

				.share_person {
					background: #f3f3f3;
					border-radius: 4px;
					width: 295px;
					height: 121px;
					padding: 12px;
					box-sizing: border-box;

					.userData {
						display: flex;
						align-items: center;
						flex: 1;

						.leftImg {
							border-radius: 5px;
							min-width: 64px;
							height: 64px;
							margin-right: 12px;
						}

						.username {
							color: #1a1a1a;
							font-size: 14px;
						}
					}

					.share_user {
						border-top: 1px solid #d8d8d8;
						color: #969696;
						font-size: 12px;
						padding-top: 8px;
						margin-top: 14px;
					}
				}
			}

			.conversation {
				display: flex;
				border-radius: 4px;
				padding: 3px 12px;
				color: #1a1a1a;
				font-size: 14px;
				box-sizing: border-box;
				background-color: #f3f3f3;
				align-items: center;
				line-height: 30px;
			}
		}
	}

	&.rightMsg {
		.chat-msg-item-content {
			justify-content: flex-end;
			position: relative;

			.userAvatar {
				order: 1;
				margin-left: 8px;
				// margin-right: 20px;
				margin-right: 0;
			}

			.messageBody {
				position: relative;

				.senderName {
					display: none;
				}

				.chat-msg-text {
					background: #c8e8ff;
					cursor: pointer;
					color: $text-color;
					padding: 12px 14px;
					line-height: 20px;
				}

				.conversation {
					background: #c8e8ff;
					cursor: pointer;
					color: #222222;
					padding: 8px 14px;
					line-height: 17px;
				}

				.file-card {
					.handle-view {
						left: -27px;
					}
				}

				.voice {
					justify-content: flex-end;
					background: #c8e8ff;
					color: #222222;

					.playing {
						order: 1;
						transform: scaleX(-1);
					}
				}
			}
		}
	}
}

.messageMenu {
	padding: 16px 16px 12px !important;
	width: 40px;
	min-width: 40px !important;
	border-radius: 10px !important;

	.messageMenuBox {
		display: grid;
		column-gap: 24px;
		grid-template-columns: repeat(1, 1fr);
		text-align: center;
		color: #333333;

		div {
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}

		i {
			font-size: 16px;
		}

		p {
			font-size: 13px;
		}
	}
}
</style>
