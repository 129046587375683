<template>
	<dialogBox
		top="5vh"
		appendToBody
		v-model="dialogVisible"
		:showBottomBtn="true"
		class="GroupSettingsDialog"
	>
		<div slot="header_title" style="display: flex; align-items: center">
			群设置
		</div>
		<div>
			<div class="userAvatarList">
				<div
					class="userData"
					v-for="item in myGroupRole"
					:key="item.id"
					@click="handleToUserDetail(item)"
				>
					<el-avatar class="useravatar-img" :src="item.userAvatar"></el-avatar>
					<div class="userData_name line-1">
						{{
							formatNickName(
								item,
								item.alias ||
								item.groupUserNickName ||
									item.nickName ||
									item.userName,
							)
						}}
					</div>
				</div>
				<div
					v-if="details.groupType != 'groupForm'"
					class="setGroupMember"
					@click="setGroupMember('邀请进群')"
				>
					<i class="icon-sohu-jiahao"></i>
				</div>
				<div
					class="setGroupMember"
					@click="setGroupMember('移除群成员')"
					v-if="
						['leader', 'admin', 'subLeader', 'subAdmin'].includes(groupRole) &&
						details.groupType != 'groupForm'
					"
				>
					<i class="icon-sohu-jianhao"></i>
				</div>
			</div>
			<div class="viewMore" @click="viewMoreClick" v-if="userList.length >= 11">
				查看更多群成员 <i class="icon-sohu-xiayiyeqianjinchakangengduo"></i>
			</div>
			<div class="setList">
				<div
					v-for="(item, index) of setList"
					:key="item.name"
					class="setCell"
					@click="rowSetClick(item)"
					v-if="
						item.roles.includes(groupRole) &&
						item.groupType.includes(details.groupType)
					"
					:style="{ 'padding-bottom': item.children ? 0 : '16px' }"
				>
					<template v-if="item.children">
						<div
							class="setCell_item"
							:class="{ active: item.active }"
							@click="onGroupChildren(item, index)"
						>
							<div class="setCell_label">
								{{ item.name }}
							</div>
							<div class="setCell_value flex">
								<div v-if="item.value">{{ item.value }}</div>
								<i
									class="icon-sohu-xiayiyeqianjinchakangengduo"
									:class="{ active: item.active }"
								></i>
							</div>
						</div>
						<div
							class="setCell_item_box"
							:style="{ height: item.childrenHeight }"
						>
							<div
								class="serCell_item_child_box"
								@click="rowSetClick(children)"
								v-if="
									children.roles.includes(groupRole) &&
									!(
										children.name == '设置管理员' &&
										groupRole == 'subLeader' &&
										details?.planVo?.state == 'Execute'
									)
								"
								v-for="(children, childrenInx) in item.children"
							>
								<div class="icon-sohu-line"></div>
								<div class="setCell_items_box">
									<div class="setCell_label">
										{{ children.name }}
										<span
											class="setCell_label_submit"
											v-if="children.name === '进群审批'"
										>
											开启后，需群主或管理员审批后才能进群
										</span>
										<span
											class="setCell_label_submit"
											v-if="children.name === '进群绑定好友关系'"
										>
											进群绑定好友关系
										</span>
									</div>
									<div class="setCell_value">
										<div v-if="['进群审批'].includes(children.name)">
											<el-switch
												:disabled="groupRole === 'user'"
												v-model="children.value"
												active-color="#13ce66"
												@change="groupSwitchChange(children)"
												class="groupSetSwitch"
											></el-switch>
										</div>
										<div v-else>
											<div v-if="children.value">{{ children.value }}</div>
											<i class="icon-sohu-xiayiyeqianjinchakangengduo"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
					<template v-else>
						<div class="setCell_label">
							{{ item.name }}
							<span
								class="setCell_label_submit"
								v-if="item.name === '禁止群成员互加好友'"
							>
								开启后，群成员不可相互添加好友
							</span>
						</div>

						<div class="setCell_value flex">
							<div
								v-if="['群聊名称', '我在本群的昵称'].includes(item.name)"
								class="groupName line-1"
							>
								{{ item.value }}
							</div>

							<div v-if="item.name == '群头像'">
								<FileUpload
									:fileTypes="['.jpg', '.jpeg', '.png']"
									:disabled="groupRole == 'user'"
									@success="fileUploadSuccess"
								>
									<el-image
										class="logo"
										fit="cover"
										:src="details.logo"
									></el-image>
								</FileUpload>
							</div>

							<div v-if="item.name == '群二维码'" class="qrCode">
								<i class="icon-sohu-erweima"></i>
							</div>

							<div class="text-#FF6C27" v-if="item.name == '进群口令'">
								复制
							</div>

							<div v-if="item.name == '进群链接'">设置进群链接</div>

							<div v-if="item.name == '群公告' && !item.value">未设置</div>
							<div v-if="item.name == '防骚扰设置'">未设置</div>
							<div
								v-if="['进群审批', '禁止群成员互加好友'].includes(item.name)"
							>
								<el-switch
									:disabled="groupRole == 'user'"
									v-model="item.value"
									active-color="#13ce66"
									@change="groupSwitchChange(item)"
									class="groupSetSwitch"
								></el-switch>
							</div>
							<div v-if="['置顶聊天'].includes(item.name)">
								<el-switch
									v-model="asTop"
									active-color="#13ce66"
									@change="groupSwitchChange(item)"
									class="groupSetSwitch"
								></el-switch>
							</div>

							<i
								class="icon-sohu-xiayiyeqianjinchakangengduo"
								v-if="
									![
										'进群审批',
										'禁止群成员互加好友',
										'置顶聊天',
										'进群口令',
									].includes(item.name)
								"
							></i>
						</div>

						<div
							v-if="['群公告'].includes(item.name) && item.value"
							class="groupNotice line-3"
							v-html="item.value"
						></div>
					</template>
				</div>
			</div>
		</div>
		<div slot="bottom_btn" class="bottom_btn" style="padding-top: 0">
			<el-button
				class="button confirm"
				@click="DissolveGroup"
				v-if="groupRole == 'leader' || groupRole == 'subLeader'"
				>解散群聊
			</el-button>
			<el-button class="button confirm" @click="ExitGroup" v-else
				>退出群聊
			</el-button>
		</div>
		<!-- 修改群名称 -->
		<ChangeGroupName ref="ChangeGroupNameRef"></ChangeGroupName>

		<!-- 群二维码 -->
		<GroupQRCode ref="GroupQRCodeRef"></GroupQRCode>

		<!-- 群关联-->
		<GroupAssociationDialog
			ref="GroupAssociationDialogRef"
			:chatUserMsg="chatUserMsg"
		></GroupAssociationDialog>

		<!--	设置管理员	-->
		<set-admin ref="setAdminRef"></set-admin>

		<!-- 防骚扰设置 -->
		<AntiHarassmentMeasures
			ref="AntiHarassmentMeasuresRef"
		></AntiHarassmentMeasures>

		<!-- 群禁言. -->
		<GroupBanSettingDialog
			ref="GroupBanSettingDialogRef"
		></GroupBanSettingDialog>

		<!--	群内禁言	-->
		<GroupProhibitionDialog
			ref="GroupProhibitionDialogRef"
		></GroupProhibitionDialog>

		<!-- 选择成员列表 -->
		<SelectMembersDialog ref="SelectMembersDialogRef"></SelectMembersDialog>

		<!-- 查看所有群成员 -->
		<GroupMembersDialog ref="GroupMembersDialogRef"></GroupMembersDialog>

		<!-- 设置渠道 -->
		<SetLinkDialog ref="SetLinkDialogRef"></SetLinkDialog>

		<!-- 群公告 -->
		<GroupAnnouncementDialog
			ref="GroupAnnouncementDialogRef"
			@success="GroupAnnouncementSuccess"
		>
		</GroupAnnouncementDialog>

		<ChangeGroupMyName ref="changChangeGroupMyNameRef" />

		<!-- 进群绑定好友关系 -->
		<BindFriendsDialog ref="BindFriendsDialogRef"></BindFriendsDialog>

		<!-- 个人名片弹窗 -->
		<businessCardDialog ref="businessCardDialogRef" @update="handleUserUpdate"></businessCardDialog>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import { sendWSPush } from '@/utils/websocket';
import BusinessCardDialog from '@/views/chat/components/user/businessCardDialog.vue';
import SetAdmin from '@/views/chat/components/group/setting/setAdmin.vue';
import GroupAssociationDialog from '@/views/chat/components/group/setting/groupAssociationDialog.vue';
import ChangeGroupName from '@/views/chat/components/group/setting/changeGroupName.vue';
import FileUpload from '@/views/chat/components/fileUpload.vue';
import GroupQRCode from '@/views/chat/components/group/setting/groupQRCode.vue';
import GroupProhibitionDialog from '@/views/chat/components/group/setting/groupProhibitionDialog.vue';
import SelectMembersDialog from '@/views/chat/components/group/setting/selectMembersDialog.vue';
import GroupMembersDialog from '@/views/chat/components/group/GroupMembersDialog.vue';
import SetLinkDialog from '@/views/chat/components/group/setting/setLinkDialog.vue';
import GroupAnnouncementDialog from '@/views/chat/components/group/setting/groupAnnouncementDialog.vue';
import BindFriendsDialog from '@/views/chat/components/group/setting/bindFriendsDialog.vue';
import AntiHarassmentMeasures from '@/views/chat/components/group/setting/antiHarassmentMeasures.vue';
import GroupBanSettingDialog from '@/views/chat/components/group/setting/groupBanSettingDialog.vue';
import ChangeGroupMyName from '@/views/chat/components/group/setting/changeGroupMyName.vue';

export default {
	components: {
		BusinessCardDialog,
		SetAdmin,
		GroupAssociationDialog,
		dialogBox,
		ChangeGroupName,
		FileUpload,
		GroupQRCode,
		GroupProhibitionDialog,
		SelectMembersDialog,
		GroupMembersDialog,
		SetLinkDialog,
		GroupAnnouncementDialog,
		BindFriendsDialog,
		AntiHarassmentMeasures,
		GroupBanSettingDialog,
		ChangeGroupMyName,
	},
	props: ['chatUserMsgActive', 'chatUserMsg', 'nweChatUserMsgActive'], //im会话消息对象
	data() {
		return {
			dialogVisible: false,
			GroupSetHeight: '0',
			groupSetAggregate: '1',
			details: {},
			setList: [
				{
					name: '群聊名称',
					key: 'name',
					value: '',
					roles: [
						'user',
						'leader',
						'subLeader',
						'subAdmin',
						'admin',
						'subGroup',
					],
					groupType: ['normal', 'subGroup', 'groupTask', 'groupForm', 'group'],
				},
				// {
				// 	name: '群头像',
				// 	roles: ['user', 'leader', 'admin', 'subGroup'],
				// 	groupType: ['normal', 'subGroup', 'groupTask', 'groupForm'],
				// },
				{
					name: '群二维码',
					roles: [
						'user',
						'leader',
						'admin',
						'subLeader',
						'subAdmin',
						'subGroup',
					],
					groupType: ['normal', 'subGroup', 'groupTask', 'group'],
				},
				{
					name: '进群口令',
					roles: [
						'user',
						'leader',
						'admin',
						'subLeader',
						'subAdmin',
						'subGroup',
					],
					groupType: ['normal', 'subGroup', 'groupTask', 'group'],
				},

				{
					name: '进群设置',
					roles: ['leader', 'admin', 'subLeader'],
					groupType: ['normal', 'subGroup', 'groupTask', 'group'],
					active: false,
					childrenHeight: '0px',
					children: [
						{
							name: '进群链接',
							roles: ['leader', 'admin', 'subLeader'],
							groupType: ['normal', 'groupTask', 'groupForm', 'group'],
						},
						{
							name: '进群审批',
							key: 'needConfirm',
							value: false,
							roles: ['leader', 'admin', 'subLeader'],
							groupType: [
								'normal',
								'subGroup',
								'groupTask',
								'groupForm',
								'group',
							],
						},
						{
							name: '进群绑定好友关系',
							roles: ['leader', 'admin'],
							groupType: [
								'normal',
								'subGroup',
								'groupTask',
								'groupForm',
								'group',
							],
						},
					],
				},
				{
					name: '群公告',
					key: 'groupNotice',
					value: '',
					roles: [
						'user',
						'leader',
						'admin',
						'subGroup',
						'subLeader',
						'subAdmin',
					],
					groupType: ['normal', 'groupTask', 'groupForm', 'subGroup', 'group'],
				},
				{
					name: '我在本群的昵称',
					key: 'groupUserName',
					value: '',
					roles: ['user', 'leader', 'admin'],
					groupType: ['normal', 'group'],
				},
				{
					name: '成员管理',
					roles: ['leader', 'admin', 'subAdmin'],
					groupType: ['normal', 'subGroup', 'groupTask', 'group'],
					active: false,
					childrenHeight: '0px',
					children: [
						{
							name: '防骚扰设置',
							roles: ['leader', 'admin', 'subGroup', 'subLeader', 'subAdmin'],
							groupType: [
								'normal',
								'subGroup',
								'groupTask',
								'groupForm',
								'group',
							],
						},
						{
							name: '设置管理员',
							roles: ['leader', 'subGroup', 'subLeader'],
							groupType: ['normal', 'subGroup', 'group'],
						},
					],
				},
				{
					name: '禁止群成员互加好友',
					key: 'addFriend',
					value: false,
					roles: ['leader', 'admin', 'subAdmin'],
					groupType: ['normal', 'subGroup', 'groupTask', 'group'],
				},
				{
					name: '置顶聊天',
					key: 'asTop',
					value: false,
					roles: [
						'user',
						'leader',
						'admin',
						'subGroup',
						'subLeader',
						'subAdmin',
					],
					groupType: ['normal', 'subGroup', 'groupTask', 'groupForm', 'group'],
				},
				{
					name: '群内禁言',
					roles: ['leader', 'admin', 'subLeader', 'subAdmin'],
					groupType: ['normal', 'subGroup', 'groupTask', 'groupForm', 'group'],
				},
			],
			userList: [],
			inviteCalculate: '',
			currentUser: {},
			isShowGroupSet: false,
			activeMsgList: {},
			// asTop:false,
		};
	},
	provide() {
		return {
			getDetails: this.open,
		};
	},
	inject: {
		imGroupSubList: { value: 'imGroupSubList', default: null },
	},
	computed: {
		myGroupRole() {
			let list = JSON.parse(JSON.stringify(this.userList));
			if (
				['leader', 'admin', 'subAdmin', 'subLeader'].includes(this.groupRole)
			) {
				return list.splice(0, 10);
			} else {
				return list.splice(0, 11);
			}
		},
		// 当前群角色
		groupRole() {
			let role = 'user';
			// 是否是群主
			if (this.$store.state.userInfo.id == this.details.userId) {
				role = 'leader';
			}
			let isGroupAdmin = this.userList.some((item) => {
				if (item.userId == this.$store.state.userInfo.id) {
					this.currentUser = item;
				}
				return (
					item.permissionType == 'group_admin' &&
					item.userId == this.$store.state.userInfo.id
				);
			});
			// 是否是管理员
			if (isGroupAdmin) {
				role = 'admin';
			}
			//子群得身份
			if (this.details.groupType == 'subGroup' && this.details.pid) {
				role = 'subGroup';
				if (isGroupAdmin) role = 'subAdmin';
				if (this.userInfo.id == this.details.userId) role = 'subLeader';
			}
			return role;
		},
		userInfo() {
			return this.$store.state.userInfo;
		},
		// 禁止解散群聊
		disabledFn() {
			let flag = false;
			if (
				(this.groupRole == 'leader' || this.groupRole == 'subLeader') &&
				(this.details?.planVo?.state == 'Execute' ||
					this.details?.planVo?.state == 'WaitApproveSettle')
			) {
				flag = true;
			}
			return flag;
		},
	},
	// 当前是否置顶
	watch: {
		'nweChatUserMsgActive.asTopTime': {
			handler(newVal) {
				this.asTop = newVal ? true : false;
			},
			immediate: true,
			deep: true,
		},
		'chatUserMsgActive.asTopTime': {
			handler(newVal) {
				this.asTop = newVal ? true : false;
			},
			immediate: true,
			deep: true,
		},
	},

	methods: {
		/**
		 * 操作群用户信息更新
		 */
		handleUserUpdate() {
			this.getUserList();
		},
		/**
		 * 前往用户个人名片
		 * @param model
		 */
		handleToUserDetail(model) {
			console.log('model: ', model);
			// 当前角色是 发单方taskPublish/ 接单方taskRece
			let specialRole = this.details.specialRole;
			// 是否是普通群聊
			let sessionType = this.details.sessionType == 'group';
			const isAuth = ['group_leader', 'group_admin'].includes(
				this.currentUser.permissionType,
			);
			// 是否是群主
			const isGroupManager = ['leader', 'subLeader'].includes(this.groupRole);

			// 打码成员不可点击头像
			const formattedName = this.formatNickName(
				model,
				model.groupUserNickName ||
					model.alias ||
					model.nickName ||
					model.userName,
			);
			const isEncrypted = formattedName.includes('**');

			// 不是普通群聊
			if (!sessionType) {
				//接单方  发单方不可点击
				if (specialRole == 'taskRece') {
					if (model.specialRole == 'taskPublish') return;
				}
				// 当为发单方时候  所有头像均不可点击
				if (specialRole == 'taskPublish') {
					if (model.specialRole == 'taskRece') return;
				}

				if (isEncrypted) return;
			}

			if (model.userId === this.userInfo.id) return;

			if (isAuth || !this.details.addFriend || this.groupRole == 'leader') {
				this.$refs.businessCardDialogRef.open(model.userId);
			} else {
				this.$message.info('当前群聊已设置禁止添加好友');
			}
		},

		/**
		 * 操作子集展开
		 * @param item
		 * @param index
		 */
		onGroupChildren(item, index) {
			console.log(item, index, 'onGroupChildren');
			this.setList[index].active = !this.setList[index].active;
			let heightPx = [];
			this.setList.map((item, itemIndex) => {
				if (
					item.children &&
					['成员管理', '进群设置'].includes(item.name) &&
					index == itemIndex
				) {
					let num = 0;
					item.children.map((itemChild) => {
						// 只有当不满足条件时才增加计数
						if (
							!(
								itemChild.name == '设置管理员' &&
								this.groupRole == 'subLeader' &&
								this.details?.planVo?.state == 'Execute'
							) &&
							itemChild.roles.includes(this.groupRole)
						) {
							num += 1;
						}
					});

					heightPx[index] = num * 55;
				}
			});
			if (this.setList[index].active) {
				this.setList[index].childrenHeight = heightPx[index] + 'px';
			} else {
				this.setList[index].childrenHeight = '0px';
			}
		},
		async open(id) {
			//每次打开窗口初始化状态
			this.setList.map((item, itemIndex) => {
				if (item.children && ['成员管理', '进群设置'].includes(item.name)) {
					this.setList[itemIndex].childrenHeight = '0px';
					this.setList[itemIndex].active = false;
				}
			});
			this.dialogVisible = true;
			const res = await this.$http.imGroupDetails(id);
			this.details = JSON.parse(JSON.stringify(res.data));
			if (this.details.pid) {
				this.details.groupType = 'subGroup';
			}
			this.details.copyGroupType = res.data.groupType;
			console.log('this.details: ', this.details);
			this.isShowGroupSet = false;
			await this.getUserList();
			const res1 = await this.$http.imGroupGencode(id);
			this.inviteCalculate = res1.data.inviteCalculate;
			this.getSetListChild(this.setList, res);
		},
		getSetListChild(arr, res) {
			arr.forEach((item) => {
				if (item.children?.length) {
					this.getSetListChild(item.children, res);
				} else {
					if (item.childrenHeight) item.childrenHeight = '0px';
					item.active = false;
					item.key && (item.value = res.data[item.key]);
					if (item.key === 'groupUserName') {
						const myInfo = this.userList.find(
							(v) => v.userId === this.userInfo.id,
						);
						if (myInfo.groupUserNickName) {
							item.value = myInfo.groupUserNickName;
						} else if (myInfo.nickName) {
							item.value = myInfo.nickName;
						} else {
							item.value = myInfo.userName;
						}
					}
				}
			});
		},
		// 查看更多群成员按钮
		viewMoreClick() {
			this.$refs.GroupMembersDialogRef.open(
				this.details,
				['leader'].includes(this.groupRole),
				this.userList,
			);
		},

		// 群设置
		rowSetClick(val) {
			if (val.children) return;
			// 需要管理权限的设置
			if (
				['leader', 'admin', 'subLeader', 'subAdmin'].includes(this.groupRole)
			) {
				switch (val.name) {
					case '群聊名称':
						this.$refs.ChangeGroupNameRef.open(this.details.id);
						break;
					case '群关联':
						this.$refs.GroupAssociationDialogRef.open(this.details.id);
						break;
					case '设置管理员':
						this.$refs.setAdminRef.open(this.details.id);
						break;
					case '群内禁言':
						this.$refs.GroupProhibitionDialogRef.open(this.details.id);
						break;
					case '进群链接':
						this.$refs.SetLinkDialogRef.open(
							this.details,
							this.inviteCalculate,
						);
						break;
					case '进群绑定好友关系':
						this.$refs.BindFriendsDialogRef.open(this.details);
						break;
					case '防骚扰设置':
						this.$refs.AntiHarassmentMeasuresRef.open(this.details);
						break;
					case '群禁言':
						this.$refs.GroupBanSettingDialogRef.open(this.details);
						break;
				}
			}
			switch (val.name) {
				case '群二维码':
					this.$refs.GroupQRCodeRef.open(this.details.id);
					break;
				case '进群口令':
					this.copyFn();
					break;
				case '我在本群的昵称':
					this.$refs.changChangeGroupMyNameRef.open(this.details.id);
					break;
				case '群公告':
					if (this.groupRole != 'user' && this.groupRole != 'subGroup') {
						this.$refs.GroupAnnouncementDialogRef.open(
							this.details.id,
							this.groupRole,
						);
					}
					break;
			}
		},
		// 复制群口令
		copyFn() {
			let url = this.details.groupWord;
			this.$copyText(url).then((e) => {
				this.$message({
					message: '进群口令复制成功，快去分享好友吧!',
					type: 'success',
				});
			});
		},

		// 选择头像
		async fileUploadSuccess(file) {
			console.log(file.url);
			this.details.logo = file.url;
			const res = await this.$http.editImGroup({
				...this.details,
			});
			if (res.code == 200) {
				this.$message.success('修改成功');
				this.$store.dispatch('getUserMessageList');
			}
		},

		// 	操作Switch
		async groupSwitchChange(val) {
			let res;
			switch (val.name) {
				case '进群审批':
					res = await this.$http.imGroupSwitch(this.details.id);
					break;
				case '禁止群成员互加好友':
					res = await this.$http.updateIsAddFriend(this.details.id);
					break;
				case '置顶聊天':
					res = await this.$http.chatMessageAsTop(
						this.chatUserMsgActive.sessionType,
						this.chatUserMsgActive.receiver.id,
					);
					if (res.code == 200) {
						if (!this.asTop) {
							this.$message.info('取消置顶');
						} else {
							this.$message.success('置顶成功');
						}
						this.$store.dispatch('getUserMessageList');
					}
					break;
			}
			if (res.code === 200 && val.name != '置顶聊天') {
				this.$message.success('状态修改成功');
			}
		},

		// 获取群成员列表
		async getUserList() {
			const res = await this.$http.imGroupUserAll({
				groupId: this.details.id,
			});
			this.userList = res.data;
		},

		// 设置群成员
		setGroupMember(title) {
			this.$refs.SelectMembersDialogRef.open({
				title: title,
				id: this.details.id,
				pid: this.details.pid,
				details: this.details,
			});
		},

		// 解散群按钮
		DissolveGroup() {
			if (this.disabledFn) {
				this.$message({
					message: '商单进行中，禁止解散群聊',
					type: 'warning',
				});
				return;
			}
			this.$confirmDialog({
				content: '是否解散群？',
				beforeClose: async (state, close) => {
					if (state == 'confirm') {
						const res = await this.$http.imGroupDismiss(this.details.id);
						if (res.code == 200) {
							this.dialogVisible = false;
							this.$message.success('解散成功');
							this.$store.dispatch('getUserMessageList');
							sendWSPush({
								localId: this.$util.getUuid(),
								receiverId: this.details.id,
								sessionType: 'group',
								messageType: 'command',
								commandType: 'ungroup',
								content: '解散了群聊',
							});
							close();
						}
					}
				},
			});
		},

		// 退出群聊
		ExitGroup() {
			this.$confirmDialog({
				title: '是否退出群聊？',
				content: '是否退出群聊？',
				beforeClose: async (state, close) => {
					if (state == 'confirm') {
						const res = await this.$http.imGroupUserRemoveGroup(
							this.details.id,
						);
						await this.$http.deleteImChatMessageOuterList(
							this.chatUserMsgActive.id,
						);
						if (res.code == 200) {
							this.dialogVisible = false;
							this.$message.success('退出成功');
							this.$store.dispatch('getUserMessageList');
						}

						close();
					}
				},
			});
		},

		// 群公告修改成功
		GroupAnnouncementSuccess() {
			this.$message.success('修改成功');
			sendWSPush({
				localId: this.$util.getUuid(),
				receiverId: this.details.id,
				sessionType: 'group',
				messageType: 'command',
				commandType: 'groupNotice',
				content: `${this.currentUser.userName}修改了群公告`,
			});
			//群公告修改成功消息
			sendWSPush({
				localId: this.$util.getUuid(),
				receiverId: this.details.id,
				sessionType: 'group',
				messageType: 'text',
				atIds: 0,
				content: `【群公告】\n${this.$refs.GroupAnnouncementDialogRef.details.groupNotice}\n@所有人`,
			});
			this.dialogVisible = false;
		},
		//  处理名称
		formatNickName(item, name) {
			// 普通群groupType ：group
			if (!name || this.details.groupType == 'group') return name;

			// 字符长度是否大于1
			if (name.length <= 1) return nameStr;

			// 当前角色是 发单方  taskPublish/ 接单方taskRece
			let specialRole = this.details.specialRole;
			// 是否是自己
			let isAdmin = item.userId == this.userInfo.id;

			let nameStr = name;
			// 发单方视角:
			// 所有成员全部打码，接单方昵称不打码
			if (specialRole == 'taskPublish') {
				if (this.groupRole == 'subGroup') {
					if (item.specialRole != 'taskRece' && !isAdmin) {
						nameStr = name.charAt(0) + '**';
					}
				}
			}
			// 接单方 自己和发单方不打码 群主不打码
			if (specialRole == 'taskRece') {
				if (
					!isAdmin &&
					item.specialRole != 'taskPublish' &&
					this.groupRole != 'subLeader'
				) {
					nameStr = name.charAt(0) + '**';
				}
			}
			// 普通成员  除自己和群主之外的所有成员昵称均打码，显示为“首字符+**”
			if (!specialRole) {
				if (item.permissionType != 'group_leader' && !isAdmin) {
					nameStr = name.charAt(0) + '**';
				}
			}
			return nameStr;
		},
	},
};
</script>
<style lang="scss" scoped>
.hidden-cell {
	display: none;
}
@keyframes collapse {
	from {
		height: auto;
	}

	to {
		height: 0;
	}
}

@keyframes nocollapse {
	from {
		height: 0;
	}

	to {
		height: auto;
	}
}

.GroupSettingsDialog .dialogBox.el-dialog .el-dialog__body {
	// max-height: 800px;
	max-height: calc(100vh - 134px - 100px);
	overflow-y: scroll;
	overflow-x: hidden;
}

.GroupSettingsDialog .dialogBox.el-dialog .el-dialog__body::-webkit-scrollbar {
	display: none;
}

/* 自定义过渡效果 */
.fade-enter-active,
.fade-leave-active {
	transition: all 0.5s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.GroupSettingsDialog {
	.userAvatarList {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		// grid-column-gap: 14px;
		grid-column-gap: 20px;
		grid-row-gap: 12px;
		margin-bottom: 16px;
		margin-top: 26px;
		// max-height: 150px;
		max-height: 158px;
		// height:158px;
		overflow: hidden;

		.userData {
			text-align: center;

			.useravatar-img {
				width: 48px;
				height: 48px;
				border-radius: 8px;
			}

			.el-avatar {
				width: 48px;
				height: 48px;
				margin-bottom: 4px;
			}

			.userData_name {
				color: #787878;
				font-size: 14px;
				line-height: 22px;
				padding-bottom: 2px;
				margin-top: -2px;
			}
		}

		.setGroupMember {
			border: 1.36px dashed #b2b2b2;
			width: 48px;
			height: 48px;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			i {
				font-size: 24px;
				color: #b2b2b2;
			}

			&:hover {
				opacity: 0.8;
			}
		}
	}

	.viewMore {
		margin: 20px 0;
		color: rgba(0, 0, 0, 0.6);
		text-align: center;
		line-height: 20px;
		font-size: 14px;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}

	.setList {
		.setCell {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			border-top: 1px solid #e7e7e7;
			padding: 16px 0;
			cursor: pointer;

			.setCell_item {
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: space-between;
				padding-bottom: 16px;
				flex-wrap: wrap;
				cursor: pointer;

				.icon-sohu-xiayiyeqianjinchakangengduo {
					transition: all 0.4s ease-in-out;
				}

				.icon-sohu-xiayiyeqianjinchakangengduo.active {
					transform: rotate(90deg);
				}
			}

			.setCell_item.active {
				// padding-bottom: 16px;
			}

			.setCell_item_box {
				width: 100%;
				overflow: hidden;
				transition: all 0.3s ease-in;

				.serCell_item_child_box {
					display: flex;
					width: calc(100% - 20px);
					align-items: center;
					justify-content: space-between;
					margin-left: 20px;
					padding: 16px 0;
					border-top: 1px solid #e7e7e7;
					cursor: pointer;
					position: relative;
				}

				// .serCell_item_child_box:last-of-type{
				// 	padding-top:16px;
				// 	padding-bottom: 0;
				// }
				.icon-sohu-line {
					display: inline-block;
					width: 11px;
					height: 1px;
					background: #acacac;
					position: absolute;
					// top: 50%;
					left: -20px;
					transform: translate(0, -50%);
					// margin-right: 8px;
				}

				.setCell_items_box {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;

					.setCell_label {
						display: flex;
						align-items: center;
						color: $text-color;
					}
				}
			}

			&:last-child {
				border-bottom: 1px solid #e7e7e7;
			}

			&:hover {
				opacity: 0.8;
			}

			.setCell_label {
				color: $text-color;
				font-size: 14px;
				line-height: 22px;

				.setCell_label_submit {
					color: rgba(0, 0, 0, 0.26);
					font-size: 12px;
					margin-left: 10px;
				}
			}

			.setCell_value {
				color: #999999;
				font-size: 14px;
				display: flex;
				align-items: center;
				position: relative;

				.groupSetSwitch {
					// width: 44px;
					width: 38px;
					height: 28px;
				}

				.groupName {
					max-width: 300px;
				}

				.logo {
					width: 30px;
					height: 30px;
					position: absolute;
					right: 18px;
					top: -8px;
				}

				.qrCode {
					i {
						font-size: 20px;
						color: rgba(0, 0, 0, 0.6);
					}
				}
			}

			.groupNotice {
				font-size: 13px;
				color: #a8acb0;
				line-height: 18px;
				margin-top: 8px;
				padding-right: 18px;
				white-space: pre-wrap;
				flex-basis: 100%;
			}
		}
	}
}
</style>
