import Vue from 'vue';
import Vuex from 'vuex';
import { createSocket, socketClose } from '@/utils/websocket.js';
import api from '@/utils/api';
import VueEmit from '@/utils/VueEmit';
import util from '@/utils/util.js';
Vue.use(Vuex);

export default new Vuex.Store({
   state: {
      message: '',
      token: localStorage.token,
      userInfo: {},
      siteId: localStorage.siteId || 11,
      messageList: [],
      site: {},
      roleList: [],
      gameIframeReload: false,
      unReadNum: 0,
      communicateData: null,
      chatShow: false
   },
   getters: {
      communicateDataInfo (state) {
         return state.communicateData
      },
      isChatShow (state) {
         return state.chatShow;
      },
      isGameIframeReload (state) {
         return state.gameIframeReload;
      },
      isLogin (state) {
         return !!state.token;
      },
      isMobile () {
         let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
         );
         return flag != null;
      },
      imei (state) {
         return state.userInfo.id ? state.userInfo.id : new Date().getTime();
      },
   },
   mutations: {
      setChatShow (state, data) {
         state.chatShow = data;
      },
      setCommunicate (state, data) {
         state.communicateData = data;
      },
      setGameIframeReload (state, data) {
         state.gameIframeReload = data;
      },
      setMessage (state, data) {
         state.message = data;
      },
      setToken (state, data) {
         state.token = data;
         localStorage.token = data;
      },
      setUserInfo (state, data) {
         state.userInfo = data;
      },
      setSite (state, data) {
         state.site = data;
      },
      setSiteId (state, data) {
         state.siteId = data;
         localStorage.siteId = data;
      },
      setMessageList (state, data) {
         state.messageList = data || [];
      },
      setUnReadNum (state, data) {
         state.unReadNum = data || 0;
      }
   },
   actions: {
      // 初始化
      async init (store) {
         if (store.getters.isLogin) {
            await store.dispatch('getUserMessageList');
            createSocket(store);
            await store.dispatch('getUserInfo');
         } else {
            await store.dispatch('startAiRec');
         }
      },

      //启动智能模型推送
      async startAiRec (store) {
         let aiRec = localStorage.getItem('aiRec');
         if (aiRec == null) {
            aiRec = await util.getBrowserFingerprint();
            localStorage.setItem('aiRec', aiRec);
         }
         await api.aiNoLogin({ imei: aiRec });
      },

      // 获取用户信息
      async getUserInfo (store) {
         const res = await api.getUserProfile();
         const roleRes = await api.getRoleList();
         store.state.userInfo = res.data;
         store.state.roleList = roleRes.data;
         localStorage.setItem('userInfo', JSON.stringify(res.data));
      },

      //获取消息列表
      async getUserMessageList (store) {
         const res = await api.imChatMessageOuterList({ pageSize: 100 });
         store.commit('setMessageList', res.data);
      },

      //退出登录
      logOut (store) {
         store.commit('setMessageList', []);
         store.commit('setToken', '');
         store.commit('setUserInfo', {});
         store.commit('roleList', []);
         localStorage.removeItem('userInfo');
         socketClose();
      },

      // 立即沟通
      async communicateImmediately (store, value) {
         await store.dispatch('getUserMessageList')
         let messageList = store.state.messageList;
         const res = await api.getUserProfile({
            userId: value.userId,
         });
         let data = {
            receiver: {
               avatar: res.data.userAvatar,
               id: value.userId,
               name: res.data.nickName,
            },
            sessionType: 'single',
            userName: res.data.nickName,
            userAvatar: res.data.userAvatar,
            shareId: value.shareId,
            shareType: value.shareType,
         };
         let arr = store.state.messageList.filter((item) => item.sessionType == 'single')
         if (
            arr.every(
               (item) => item.receiver.id != value.userId
            )
         ) {
            messageList.unshift(data);
         }
         store.state.communicateData = data;
         console.log('便利所有的单聊天', arr)
         VueEmit.$emit('showChat', data);
      },
      // 打开未在外消息列表单聊
      async creatToSingleRoom (store, value) {
         let messageList = store.state.messageList;
         let data = {
            receiver: {
               avatar: value.userAvatar,
               id: value.friendId,
               name: value.alias || value.nickName || value.userName,
            },
            sessionType: "single",
            userName: value.nickName || value.userName,
            userAvatar: value.userAvatar,
            // shareId: value.shareId,
            // shareType: value.shareType,
         };
         let arr = store.state.messageList.filter((item) => item.sessionType == 'single')
         if (
            arr.every(
               (item) => item.receiver.id != value.friendId
            )
         ) {
            messageList.unshift(data);
         }
         // VueEmit.$emit('showChat', data);
      },

      // 打开未在外消息列表群聊
      async creatToGroupRoom (store, value) {
         let messageList = store.state.messageList;
         let data = {
            receiver: {
               avatar: value.logo,
               id: value.id,
               name: value.name,
            },
            sessionType: value.groupType,
            userName: value.name,
            userAvatar: value.logo,
         };
         let arr = store.state.messageList.filter((item) => item.sessionType != 'single')
         if (
            arr.every(
               (item) => item.receiver.id != value.id
            )
         ) {
            messageList.unshift(data);
         }
         console.log('消息列表的改变', messageList, data)
         // VueEmit.$emit('showChat', data);
      },

   },
   modules: {},
});
