<template>
	<dialogBox :showBottomBtn="groupRole != 'user'" appendToBody v-model="dialogVisible" class="GroupAnnouncementDialog"
		@confirm="confirmClick" width="428px" :confirmDisabled="!details.groupNotice">
		<div slot="header_title" style="display: flex; align-items: center">
			<!-- “{{ details.name }}”的群公告 -->
			群公告
		</div>

		<div class="content" v-html="details.groupNotice" v-if="groupRole == 'user'"></div>
		<div v-else class="content">
			<commonInput :placeholder="`群公告模版\n${template}`" v-model="details.groupNotice" type="textarea" :rows="10"
				:maxlength="1000" autofocus></commonInput>
			<div class="template" v-if="!details.groupNotice" @click="details.groupNotice = template">
				使用模板
			</div>
		</div>
		<div class="bottom_info" v-if="groupRole == 'user'">
			————<span> 仅管理员可编辑 </span>————
		</div>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import CommonInput from "@/views/chat/components/common/commonInput.vue";
export default {
	components: { dialogBox , CommonInput },
	data() {
		return {
			dialogVisible: false,
			details: {},
			groupRole: '',
			template:
				'1.本群提倡平等礼貌交流，鼓励群友多发言、多互动\n2.禁止发布广告、恶意剧屏及谩骂等不良消息\n3.不遵守规定、言论不当的成员会被踢出群聊',
		};
	},
	inject: ['getDetails'],
	methods: {
		open(id, groupRole) {
			this.dialogVisible = true;
			this.groupRole = groupRole;
			this.getAppFriendsDetails(id);
		},

		// 详情
		async getAppFriendsDetails(id) {
			const res = await this.$http.imGroupDetails(id);
			this.details = res.data;
			console.log(res);
		},

		// 确定按钮
		async confirmClick(close) {
			const res = await this.$http.editImGroup({
				...this.details,
			});
			close();
			this.getDetails(this.details.id);
			if (res.code == 200) {
				this.$emit('success');
			}
		},
	},
};
</script>
<style lang="scss">
.GroupAnnouncementDialog {
	.content {
		margin-top: 20px;
		position: relative;

		.el-input__inner,
		.el-textarea__inner {
			background: #fff;
			border: 0 solid #000;
		}

		font-size: 14px;
		color: #3d3d3d;
		line-height: 24px;
		white-space: pre-wrap;

		.template {
			color: #3d3d3d;
			font-size: 14px;
			line-height: 24px;
			position: absolute;
			right: 0;
			top: 0;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	.bottom_info {
		color: #3d3d3d;
		font-size: 14px;
		text-align: center;
		margin-top: 119px;
		color: #c5c3c3;

		span {
			margin: 0 30px;
			color: #3d3d3d;
		}
	}
}
</style>
