//好友相关
const FriendCommandEnum = {
    //收到好友申请
    addFriendApply: "addFriendApply",
    //通过好友申请
    addFriendPass: "addFriendPass",
}
//群相关
const GroupCommandEnum = {
    //群限制相互添加好友
    groupAddFriend: "groupAddFriend",
    //进群欢迎语有人进入群聊
    groupGreet: "groupGreet",
    //修改群名称
    groupName: "groupName",
    //修改群logo
    groupLogo: "groupLogo",
    //修改群公告
    groupNotice: "groupNotice",
    //审核开关
    groupApprove: "groupApprove",
    //解散群聊
    ungroup: "ungroup",
    //创建子群
    createSubgroup: "createSubgroup",
    //解散子群
    subgroupDissolve: "subgroupDissolve",
    // 创建群聊(非子群)
    createGroup: "createGroup",
    // 管理员发送变化
    groupAdministrator: "groupAdministrator",
    // 群组禁言发生变化
    groupForbid: "groupForbid",
    //群组禁用成员发生变化
    groupMemberForbid: "groupMemberForbid",
    //群通话 开始/结束
    groupCall: "groupCall",
    //群通话人数变化
    groupRoomRefresh: "groupRoomRefresh",
    //群正在通话成员
    getVoiceMemberList: "getVoiceMemberList",
    //群组分时段禁言状态变化
    groupForbidTime: "groupForbidTime",
    //聊天错误提示
    chatLimitErrTip: "chatLimitErrTip",
    //删除与某人聊天记录(只删除自己和某人的外层)
    deleteChatMessage: "deleteChatMessage",
    //主动退群
    quitGroup: "quitGroup",
    //被动退群
    kickGroup: "kickGroup",
    //进群
    joinGroup: "joinGroup",
    //第一次和商家对话
    merchantWelcome: "merchantWelcome",
    //用户信息发生改变
    userInfoUpdate: "userInfoUpdate"
}
//聊天枚举类型
const chatCommandEnum = {
    //撤回
    recall: "recall",
    //系统撤回
    systemRecall:"systemRecall"
}
//命令类型
export const CommandEnum = {
    ...FriendCommandEnum,
    ...GroupCommandEnum,
    ...chatCommandEnum
}