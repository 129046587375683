<template>
	<main-base-view title="我的群组" :loading="loading" @close="$emit('close')">
		<div class="group-view">
			<div class="group-list">
				<div class="list-view" v-if="list.length">
					<div
						:class="['item', item.id === groupActive ? 'active' : '']"
						v-for="(item, index) in list"
						:key="item.id"
						@click="handleGroupChange(item)"
					>
						<el-image class="avatar" :src="item.logo"></el-image>
						<div class="name">{{ item.name }}（{{ item.groupUserNum }}）</div>
					</div>
				</div>
				<!--                <div class="group-count" v-if="list.length">-->
				<!--                    共{{ list.length }}个群组-->
				<!--                </div>-->
				<div class="empty" v-if="!list.length && !loading">
					<common-empty content=" " />
				</div>
			</div>
			<!--            <div class="group-detail">-->
			<!--                <template v-if="list.length">-->
			<!--                    <div class="group-member">-->
			<!--                        <div class="item" v-for="(item,index) in groupMemberList" :key="item.id"-->
			<!--                             @click="handleShowMember(item)">-->
			<!--                            <el-image class="avatar" :src="item.userAvatar"></el-image>-->
			<!--                            <div class="name">{{ groupUserNameFilter(item) }}</div>-->
			<!--                        </div>-->
			<!--                        <div class="item" @click="handleShowMore" v-if="allGroupMemberList.length >= 15">-->
			<!--                            <div class="btn">-->
			<!--                                <i class="icon-sohu-gengduo"></i>-->
			<!--                            </div>-->
			<!--                        </div>-->
			<!--                    </div>-->
			<!--                    <div class="handle-view">-->
			<!--                        <div class="btn" @click="handleToChat">发消息</div>-->
			<!--                    </div>-->
			<!--                </template>-->
			<!--            </div>-->
		</div>

		<group-members-dialog ref="groupMembersDialogRef" @toChat="handleToChat" />
		<!-- 个人名片弹窗 -->
		<businessCardDialog ref="businessCardDialogRef"></businessCardDialog>
	</main-base-view>
</template>

<script>
import BusinessCardDialog from '@/views/chat/components/user/businessCardDialog.vue';
import GroupMembersDialog from '@/views/chat/components/group/GroupMembersDialog.vue';
import MainBaseView from '@/views/chat/components/friend/base/mainBaseView.vue';
import CommonEmpty from '@/views/chat/components/common/commonEmpty.vue';
export default {
	name: 'meGroupView',
	components: {
		BusinessCardDialog,
		GroupMembersDialog,
		MainBaseView,
		CommonEmpty,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	watch: {
		list: {
			handler(val) {
				this.loading = false;
				if (!val.length) return;
				if (val.length) this.groupActive = val[0].id;
			},
			deep: true,
			// immediate: true
		},
		// async groupActive(val) {
		// 	if (val) {
		//
		// 	}
		// },
	},
	computed: {
		userInfo() {
			return this.$store.state.userInfo;
		},
		isGroupLeader() {
			const model = this.allGroupMemberList.find(
				(v) => v.userId === this.userInfo.id,
			);
			if (model) {
				return ['group_leader'].includes(model.permissionType);
			} else {
				return false;
			}
		},
	},
	data() {
		return {
			groupActive: 0,
			groupDetail: {},
			groupMemberList: [],
			allGroupMemberList: [],
			loading: true,
		};
	},
	methods: {
		/**
		 * 操作显示成员信息
		 * @param model
		 */
		handleShowMember(model) {
			if (model.userId === this.userInfo.id) return;
			if (this.isGroupLeader || !this.groupDetail.addFriend) {
				this.$refs.businessCardDialogRef.open(model.userId);
			} else {
				this.$message.info('当前群聊已设置禁止添加好友');
			}
		},
		/**
		 * 展示更多群成员
		 */
		handleShowMore() {},
		/**,
		 * 操作前往聊天
		 */
		handleToChat() {
			this.$emit('toChat', {
				...this.groupDetail,
				id: this.groupDetail.id,
				sessionType: 'group',
			});
		},
		/**
		 * 处理群聊名称展示
		 * @param model
		 * @returns {string}
		 */
		groupUserNameFilter(model) {
			return (
				model.groupUserNickName ||
				model.alias ||
				model.nickName ||
				model.userName
			);
		},
		/**
		 * 操作群聊切换
		 * @param model
		 */
		async handleGroupChange(model) {
			this.groupActive = model.id;
			await this.getGroupDetail();
			await this.getGroupMemberList();
			this.$refs.groupMembersDialogRef.open(
				this.groupDetail,
				this.isGroupLeader,
				this.allGroupMemberList,
				true,
			);
		},
		/**
		 * 获取群聊详情数据
		 * @returns {Promise<void>}
		 */
		async getGroupDetail() {
			const res = await this.$http.imGroupDetails(this.groupActive);
			if (res.code === 200) {
				this.groupDetail = res.data;
			}
		},
		/**
		 * 获取群成员数据
		 * @returns {Promise<void>}
		 */
		async getGroupMemberList() {
			const res = await this.$http.imGroupUserAll({
				groupId: this.groupActive,
			});
			if (res.code === 200) {
				this.allGroupMemberList = res.data.map((v) => v);
				this.groupMemberList = res.data.splice(0, 14);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.group-view {
	width: 100%;
	height: 100%;
	display: flex;

	.group-list {
		width: 100%;
		height: 100%;
		display: flex;
		border-right: 1px solid #e7e7e7;
		flex-direction: column;

		.list-view {
			flex: 1;
			padding: 14px 10px 0 10px;
			box-sizing: border-box;
			overflow-y: auto;

			.item {
				width: 100%;
				height: 74px;
				border-radius: 8px;
				display: flex;
				align-items: center;
				cursor: pointer;
				padding-left: 20px;
				box-sizing: border-box;

				//&.active {
				//  background-color: #F3F3F3;
				//}

				.avatar {
					width: 48px;
					height: 48px;
					border-radius: 8px;
					margin-right: 15px;
				}

				.name {
					color: #222;
					font-size: 16px;
					line-height: 22px;
					max-width: 400px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}

		.group-count {
			font-size: 12px;
			color: #999;
			line-height: 17px;
			margin: 20px 0;
			text-align: center;
		}
	}

	.group-detail {
		width: 50%;
		height: 100%;

		.group-member {
			width: 100%;
			height: fit-content;
			padding: 40px 40px 0 40px;
			box-sizing: border-box;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 10px;

			.item {
				display: flex;
				flex-direction: column;
				align-items: center;

				.btn {
					width: 44px;
					height: 44px;
					border: 1px dashed #b2b2b2;
					border-radius: 8px;
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 30px;
					color: #b2b2b2;
				}

				.avatar {
					width: 44px;
					height: 44px;
					border-radius: 8px;
				}

				.name {
					max-width: 44px;
					color: #999999;
					font-size: 14px;
					line-height: 22px;
					margin-top: 6px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}

		.handle-view {
			display: flex;
			justify-content: center;
			margin-top: 76px;

			.btn {
				width: 100px;
				height: 40px;
				background-color: $theme-color;
				font-size: 16px;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
				cursor: pointer;
			}
		}
	}

	.empty {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
