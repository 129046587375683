<!-- 商单 -->
<template>
	<div>
		<dialogBox
			appendToBody
			v-model="dialogVisible"
			class="ApplicationDialog"
			@confirm="confirmClick"
			title="商单进度详情"
			:showBottomBtn="false"
		>
			<div class="text-16px flex items-center justify-between mt-9px">
				<div
					@click="goOrderDetail"
					class="text-#3D3D3D lh-36px max-w-350px truncate cursor-pointer"
				>
					{{ details?.planVo?.taskTitle }}
				</div>
				<i
					@click="goOrderDetail"
					class="el-icon-arrow-right text-#ACACAC font-600 cursor-pointer"
				></i>
			</div>
			<div
				class="flex mt-5px justify-between w-full pb-18px"
				style="border-bottom: 1px solid #e7e7e7"
			>
				<div class="text-#F94040 text-24px font-500">
					¥{{ details?.planVo?.fullAmount }}
				</div>
				<el-tag size="small" :type="stateList[details?.planVo?.state]?.type">
					{{ stateList[details?.planVo?.state]?.text }}
				</el-tag>
			</div>

			<!-- 客户群 -->
			<div
				v-if="
					['groupFormCustom', 'groupTaskCustom'].includes(details?.groupType)
				"
			>
				<div class="flex justify-between">
					<div class="text-16px text-#222222 mt-20px h-36px lh-36px">
						接单方：{{ details?.planVo?.taskReceUserName }}
					</div>
					<div
						v-if="details?.planVo?.state == 'WaitApproveSettle'"
						class="text-12px text-#999999 mt-20px h-36px lh-36px"
					>
						申请时间：{{ details?.planVo?.busyTaskUpdateTime }}
					</div>
				</div>

				<div
					style="background: rgba(255, 108, 39, 0.05)"
					class="h-60px b-rd-8px mt-10px flex items-center text-#FF6C27 px-38px pt-19px pb-23px"
					:class="textObj.button ? 'justify-between' : 'justify-around'"
				>
					<div class="flex flex-col items-center justify-center">
						<div class="lh-24px text-24px">
							<!-- 执行中 是 达标人数  standNum  Execute-->
							<!-- 审核中 是 交付人数  deliveryNum-->
							{{ details?.planVo?.standNum }}
							<span class="text-12px">人</span>
						</div>
						<div class="text-14px">
							{{
								details?.planVo?.state == 'Execute' ? '达标人数' : '交付人数'
							}}
						</div>
					</div>

					<div class="flex flex-col justify-center items-center">
						<div class="lh-24px text-24px">
							{{ details?.planVo?.standAmount }}
							<span class="text-12px">¥</span>
						</div>
						<div class="text-14px">预计金额</div>
					</div>

					<div
						@click="settlementFn"
						style="
							background: linear-gradient(293deg, #ff6c27 0%, #ffad55 100%);
						"
						v-if="settlementObjFn.button"
						class="w-74px h-32px b-rd-6px text-#fff text-14px flex items-center justify-center cursor-pointer"
					>
						{{ settlementObjFn.buttonText }}
					</div>
				</div>

				<div
					class="text-16px flex justify-between text-#222222 mt-30px h-36px lh-36px"
				>
					<div>
						群成员

						<el-tooltip
							content="达标说明：接该商单的所有人的群聊，最先满足交付人数的群成员为达标"
							placement="top"
							effect="light"
							popper-class="custom-tooltip-popper"
							class="custom-tooltip"
						>
							<i
								class="el-icon-warning-outline text-#999999 text-16px ml-4px"
							></i>
						</el-tooltip>
					</div>
					<div class="text-#999999 text-12px">
						数据更新至{{ details?.planVo?.groupUserUpdateTime }}
					</div>
				</div>

				<div class="mt-10px b-rd-8px h-full">
					<el-table :data="groupUserList" :row-class-name="tableRowClassName">
						<el-table-column prop="avatar" align="center" label="头像">
							<template #default="scope">
								<img
									class="w-32px h-32px b-rd-8px"
									:src="scope.row.avatar"
									alt=""
								/>
							</template>
						</el-table-column>
						<el-table-column prop="nickName" align="center" label="昵称">
							<template #default="scope">
								<div class="text-14px">
									{{ formatNickName(scope.row.nickName) }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="isInGroup" align="center" label="在群状态">
							<template #default="scope">
								<div class="text-14px">
									<span class="text-#222222" v-if="scope.row.isInGroup">
										在群
									</span>
									<span class="text-#999999" v-else>退群</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column
							prop="completeState"
							align="center"
							label="是否达标"
						>
							<template #default="scope">
								<div class="text-14px">
									<span class="text-#FF6C27" v-if="scope.row.completeState">
										已达标
									</span>
									<span class="text-#222222" v-else>未达标</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>

			<!-- 主群 -->
			<div
				v-if="
					['group', 'groupgroupTask', 'groupForm'].includes(details?.groupType)
				"
			>
				<div class="text-16px text-#222222 lh-36px mt-22px">商单进度</div>
				<div
					class="mt-10px text-#FF6C27 text-14px flex items-center justify-between"
				>
					<div>已达标人数：{{ details?.planVo?.standNum }}</div>
					<div>期望人数：{{ details?.planVo?.deliveryStandard }}</div>
				</div>
				<div class="mt-12px">
					<el-progress
						:show-text="false"
						:percentage="
							(details?.planVo?.standNum / details?.planVo?.deliveryStandard) *
								100 >
							100
								? 100
								: (details?.planVo?.standNum /
										details?.planVo?.deliveryStandard) *
								  100
						"
					></el-progress>
				</div>
				<div
					style="border-bottom: 1px solid #e7e7e7"
					class="flex items-center justify-between mt-14px text-14px pb-30px"
				>
					<div class="text-#222222">达标交付人数</div>
					<div class="text-#999999">
						已交付{{ details?.planVo?.deliveryNum }} ｜ 待结算
						<!-- 达标人数 - 已经交付了人数 -->
						{{ details?.planVo?.standNum - details?.planVo?.deliveryNum }}
					</div>
				</div>

				<div class="mt-20px flex items-center">
					<div class="lh-36px text-#3D3D3D text-16px">
						群数据（{{ num }}
						/
						{{ details?.planVo?.receiveNum }}）
					</div>
					<el-tooltip
						content="已完成/已接单"
						placement="top"
						effect="light"
						popper-class="custom-tooltip-popper"
						class="custom-tooltip"
					>
						<i
							class="el-icon-warning-outline text-#999999 text-16px ml-4px"
						></i>
					</el-tooltip>
				</div>

				<div class="flex items-center">
					<div class="lh-36px text-#3D3D3D text-16px">
						群进度 <span class="text-#999999 text-14px">群人数 / 达标人数</span>
					</div>
					<el-tooltip
						content="满足在群时长的人数"
						placement="top"
						effect="light"
						popper-class="custom-tooltip-popper"
						class="custom-tooltip"
					>
						<i
							class="el-icon-warning-outline text-#999999 text-16px ml-4px"
						></i>
					</el-tooltip>
				</div>

				<div class="mt-10px">
					<div class="mb-20px" v-for="(item, index) in groupListL" :key="index">
						<div class="flex h-44px items-center">
							<img class="w-44px h-44px mr-10px" :src="item.logo" alt="" />
							<div
								class="text-16px text-#222222 lh-36px flex items-center w-218px"
							>
								<span class="max-w-150px truncate inline-block">
									{{ item.name }}
								</span>
							</div>
							<div class="flex flex-1 items-center justify-between">
								<div class="ml-22px lh-36px flex items-center text-16px">
									{{ item?.groupUserNum }} /
									<span
										class="ml-4px"
										:style="{
											color:
												item.planVo.state == 'Execute'
													? '#00B42A'
													: stateList[item?.planVo?.state]?.color,
										}"
									>
										{{ item?.planVo.standNum }}
									</span>
								</div>
								<div
									class="text-16px text-#FF6C27 ml-30px"
									:style="{ color: stateList[item?.planVo?.state]?.color }"
								>
									{{ stateList[item?.planVo?.state]?.text }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</dialogBox>
		<accountDialog
			ref="accountDialogRef"
			@settleSuccess="handleSettleSuccess"
		/>
	</div>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import accountDialog from './account.vue';
import URL from '@/utils/baseUrl';
export default {
	components: { dialogBox, accountDialog },
	data() {
		return {
			dialogVisible: false,
			tableData: [],
			details: {}, //传过来的数据详情
			stateList: {
				Edit: {
					text: '草稿',
					type: 'info',
					color: '#999999',
				},
				WaitPay: {
					text: '待支付',
					type: 'info',
					color: '#999999',
				},
				WaitApprove: {
					text: '待审核',
					type: 'warning',
					color: '#999999',
				},
				WaitSplit: {
					text: '待拆单',
					type: 'info',
					color: '#FF6C27',
				},
				Pass: {
					text: '已通过  ',
					type: 'success',
					color: '#FF6C27',
				},
				Cancel: {
					text: '已取消',
					type: 'info',
					color: '#999999',
				},
				WaitSplitPass: {
					text: '已通过且未拆单状态',
					type: 'success',
					color: '#FF6C27',
				},
				SplitPass: {
					text: '已通过且是拆单状态',
					type: 'success',
					color: '#FF6C27',
				},
				Refuse: {
					text: '审核拒绝',
					type: 'danger',
					color: '#F56C6C',
				},
				ReceiveTask: {
					text: '已接单',
					type: 'warning',
					color: '#FF6C27',
				},
				Execute: {
					text: '执行中',
					type: 'warning',
					color: '#222222',
				},
				WaitApproveSettle: {
					text: '结算申核中',
					type: 'success',
					color: '#00B42A',
				},
				WaitSettle: {
					text: '待结算',
					type: '',
					color: '#00B42A',
				},
				OverSettle: {
					text: '已结算',
					type: 'success',
					color: '#00B42A',
				},
				Error: {
					text: '商单异常',
					type: 'danger',
					color: '#F56C6C',
				},
				Delete: {
					text: '删除',
					type: 'danger',
					color: '#F56C6C',
				},
				WaitReceive: {
					text: '待接单',
					type: '',
					color: '#999999',
				},
				OffShelf: {
					text: '下架',
					type: 'danger',
					color: '#F56C6C',
				},
				OnShelf: {
					text: '上架',
					type: '',
					color: '#222222',
				},
				Over: {
					text: '已终止',
					type: 'danger',
					color: '#F56C6C',
				},
				CompelOff: {
					text: '强制下架',
					type: 'danger',
					color: '#F56C6C',
				},
			},
			groupListL: [],
			groupUserList: [],
			taskNumber: '',
			textObj: {},
			num: 0,
		};
	},
	computed: {
		settlementObjFn() {
			let textObj = {
				button: false, //按钮是否出现
				buttonText: '', //按钮文字
			};
			// 发单方
			if (this.details.specialRole == 'taskPublish') {
				if (this.details?.planVo?.state == 'Execute') {
					textObj.buttonText = '去结算';
					textObj.button = true;
				}

				if (this.details?.planVo?.state == 'WaitApproveSettle') {
					textObj.buttonText = '去审核';
					textObj.button = true;
				}
			}

			// 接单方
			if (this.details.specialRole == 'taskRece') {
				if (this.details?.planVo?.state == 'Execute') {
					textObj.buttonText = '申请结算';
					textObj.button = true;
				}
				if (this.details?.planVo?.state == 'WaitApproveSettle') {
					textObj.button = false;
				}
			}
			return textObj;
		},
	},

	methods: {
		// 打开
		open(details) {
			this.dialogVisible = true;
			this.details = details;
			const groupExtObj = JSON.parse(details.groupExt);
			this.taskNumber = groupExtObj.taskNumber;
			// 客户群
			if (['groupFormCustom', 'groupTaskCustom'].includes(details.groupType)) {
				this.getListGroupUser(details);
			} else {
				// 主群
				this.getListGroup(details);
			}
		},

		tableRowClassName({ row, rowIndex }) {
			if ((rowIndex + 1) % 2 === 1) {
				return 'warning-row';
			} else if ((rowIndex + 2) % 2 === 1) {
				return 'success-row';
			}
			return '';
		},
		confirmClick() {},
		// 获取群人员列表
		async getListGroupUser(data) {
			let params = {
				groupId: data.receiver.id,
			};
			const res = await this.$http.getListGroupUserApi(params);
			this.groupUserList = res.data;
		},

		// 获取群列表
		async getListGroup(data) {
			this.num = 0;
			let params = {
				groupId: data.receiver.id,
			};
			const res = await this.$http.getListGroupApi(params);
			res.data.forEach((item) => {
				if (item.planVo.state == 'OverSettle') {
					this.num++;
				}
			});
			this.groupListL = res.data;
		},
		// 处理名字
		formatNickName(name) {
			if (!name) return '';
			if (name.length <= 1) return name;
			return name.charAt(0) + '**';
		},

		// 去商单详情
		goOrderDetail() {
			// 主群
			if (this.details.sessionType == 'groupForm') {
				window.open(
					`${URL.ADMIN_URL}/taskManage/tm-myTask?token=${this.$store.state.token}&roleIdentifier=provider`,
				);
			} else {
				// 客户群
				const route = this.$router.resolve({
					path: '/makeMoney/taskDetails',
					query: {
						taskNumber: this.taskNumber,
					},
				});
				window.open(route.href, '_blank', 'noopener,noreferrer');
				this.dialogVisible = false;
			}
		},
		// 结算
		settlementFn() {
			// 发单方
			if (this.details.specialRole == 'taskPublish') {
				window.open(
					`${URL.ADMIN_URL}/taskManage/tm-myTask?token=${this.$store.state.token}&roleIdentifier=provider`,
				);
			}
			// 接单方
			if (this.details.specialRole == 'taskRece') {
				// 申请结算;
				if (this.details?.planVo?.state == 'Execute') {
					this.$refs.accountDialogRef.open(this.details);
				}
			}
		},
		// 调父组件更新状态
		handleSettleSuccess() {
			this.$emit('orderUpdata');
			this.dialogVisible = false;
		},
	},
};
</script>
<style scoped>
:deep(.el-table .warning-row) {
	background-color: #fafafa !important;
}

:deep(.el-table .success-row) {
	background-color: #fff7f4 !important;
}

:deep(.el-table thead th) {
	background-color: #ffefe8 !important;
	color: #222222 !important;
}

:deep(.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf) {
	border: none !important;
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
	border: none !important;
}

::v-deep .el-table {
	border-radius: 8px !important;
}

:deep(.el-table),
:deep(.el-table tr),
:deep(.el-table th),
:deep(.el-table td) {
	border: none !important;
}

/* 移除表格单元格的默认背景色 */
:deep(.el-table td),
:deep(.el-table th.is-leaf) {
	background-color: transparent;
}

/* 移除表格的外边框 */
:deep(.el-table::before) {
	display: none;
}

/* 移除最后一行的底部边框 */
:deep(.el-table::after) {
	display: none;
}
:deep(.el-progress-bar__outer) {
	height: 10px !important;
}
:deep(.el-progress-bar__inner) {
	background: linear-gradient(272deg, #ff6c27 0%, #ffad55 100%);
	height: 10px;
}
</style>

<style>
.el-tooltip__popper {
	border: none !important;
	padding: 8px 12px !important;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
}

/* 处理箭头样式 */
.el-tooltip__popper .popper__arrow {
	transform: rotate(45deg) !important;
	width: 8px !important;
	height: 8px !important;
	border: none !important;
	background: white !important;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}

/* 移除箭头的伪元素 */
.el-tooltip__popper .popper__arrow::after {
	display: none !important;
}

/* 调整箭头位置 */
.el-tooltip__popper[x-placement^='top'] .popper__arrow {
	bottom: -4px !important;
}

.el-tooltip__popper[x-placement^='bottom'] .popper__arrow {
	top: -4px !important;
}

.el-tooltip__popper[x-placement^='left'] .popper__arrow {
	right: -4px !important;
}

.el-tooltip__popper[x-placement^='right'] .popper__arrow {
	left: -4px !important;
}
</style>
