<template>
	<transition name="el-zoom-in-top">
		<div
			class="dropdown"
			v-if="show"
			@click.prevent.stop="show = false"
			@contextmenu.prevent.stop="show = false"
		>
			<div class="menu-list" :style="menuStyle" v-if="show">
				<div
					class="item"
					v-for="(item, index) in list"
					:key="item.event"
					@click="$emit('handleEvent', item.event, subData)"
				>
					<i v-if="item.icon" :class="['icon', item.icon]"></i>
					<div class="title">{{ item.title }}</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'dropdown',
	data() {
		return {
			show: false,
			menuStyle: {},
			subData: null, //附加数据
			list: [],
		};
	},
	methods: {
		open(model, list, data = null) {
         console.log('list: ', list);
			this.show = true;
			delete model.width;
			this.menuStyle = { ...model };
			this.list = list;
			if (data) this.subData = { ...data };
		},
	},
};
</script>

<style lang="scss" scoped>
.dropdown {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;

	.menu-list {
		width: fit-content;
		position: relative;
		background-color: #fff;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		padding: 10px 0;
		box-sizing: border-box;

		.item {
			flex: 1;
			display: flex;
			align-items: center;
			padding: 0 26px;
			box-sizing: border-box;
			font-size: 14px;
			color: #222222;
			cursor: pointer;

			.icon {
				font-size: 16px;
				margin-right: 10px;
			}

			.title {
				white-space: nowrap;
			}

			&:hover {
				color: $theme-color;
			}
		}
	}
}
</style>
