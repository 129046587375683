<template>
	<div class="q-header">
		<div class="leftBox">
			<el-link :underline="false" href="/">
				<el-image
					style="width: 36px; height: 36px"
					:src="require('@/assets/images/logo.png')"
				></el-image>
			</el-link>
			<div @click.stop="inputClick">
				<el-input
					ref="searchRef"
					v-model="searchInput"
					placeholder="请输入内容"
					@keyup.enter.native="handleSearchResult(searchInput)"
				>
					<div
						slot="suffix"
						@click.stop="handleSearchResult(searchInput)"
						class="searchBox"
					>
						<i class="icon-sohu-sousuo"> </i>
					</div>
				</el-input>
			</div>

			<div class="inputDiv" v-if="isSearch">
				<div class="card_header">
					<div class="leftTitle">猜你想要</div>
					<div class="switchBtn" @click.stop="switchClick">
						<i class="icon-sohu-huanyihuan"></i>
						换一批
					</div>
				</div>
				<div class="guess-want">
					<p
						class="guess-item line-1"
						v-for="item in hotWordList"
						:key="item.id"
						@click="handleSearchResult(item.keyword)"
					>
						{{ item.keyword }}
					</p>
				</div>
			</div>
		</div>

		<div class="rightBox">
			<!-- <div class="investmentFlow" @click.stop="goFlow">
				去投流
			</div> -->
			<div
				class="siteBox"
				@click.stop="siteDialogClick({ key: 'siteDialogShow' })"
			>
				{{ whetherAll ? '全部' : siteList?.name }}
				<i class="icon-sohu-xiajiantou"></i>
			</div>
			<!--头部菜单-->
			<div class="topNavList">
				<div
					class="topNav_item"
					v-for="item of topNavList"
					:key="item.name"
					@click.stop="topNavClick(item)"
				>
					<svg class="icon" aria-hidden="true">
						<use :xlink:href="`#${item.icon}`"></use>
					</svg>
					<span>{{ item.name }}</span>
					<el-badge v-if="showPoint(item)" is-dot></el-badge>
				</div>
			</div>
			<div
				class="userBox"
				@click.stop="topNavClick({ key: 'informationShow' })"
				v-if="$store.getters.isLogin"
			>
				<el-avatar
					:size="36"
					:src="$store.state.userInfo?.userAvatar"
					class="avatar"
					fit="fill"
				></el-avatar>
			</div>
			<el-button v-else class="loginBtn" @click="loginDialogClick"
				>登录
			</el-button>
		</div>
		<!-- 下载App弹框 -->
		<transition name="el-zoom-in-top">
			<div
				class="downloadAppBox"
				@click="topNavDialog.downloadAppShow = false"
				v-if="topNavDialog.downloadAppShow"
			>
				<div class="downloadAppMainBox" @click.stop="">
					<div class="title">下载APP</div>
					<div class="introduce">有趣内容 轻松赚钱 实时消息</div>
					<img
						class="img"
						src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/04/12/217100dfc1d34ab8905d4d6359341365_400x400.png"
					/>
					<div class="sweep">微信扫一扫</div>
				</div>
			</div>
		</transition>
		<!-- 业务合作 -->
		<transition name="el-zoom-in-top">
			<div
				class="businessCooperationBox"
				@click="topNavDialog.businessShow = false"
				v-if="topNavDialog.businessShow"
			>
				<div class="main" @click.stop="">
					<div
						class="item"
						@click.stop="businessClick(v)"
						v-for="v of businessList"
						:key="v.value"
					>
						{{ v.adminState ? v.adminTitle : v.title }}
					</div>
				</div>
			</div>
		</transition>
		<!-- 站点 -->
		<transition name="el-zoom-in-top">
			<div
				class="siteDialogBox"
				@click.stop=""
				v-if="topNavDialog.siteDialogShow"
			>
				<siteDialog :show="topNavDialog.siteDialogShow"></siteDialog>
			</div>
		</transition>
		<!-- 个人中心 -->
		<transition name="el-zoom-in-top" v-if="topNavDialog.informationShow">
			<div class="personalCenterBox">
				<div class="to-center" @click="$router.push('/my')">
					<span>个人中心</span>
					<i class="icon-sohu-xiayiyeqianjinchakangengduo"></i>
				</div>
				<div class="avatra-view">
					<el-avatar
						:size="62"
						:src="$store.state.userInfo?.userAvatar"
						class="avatar"
						fit="fill "
					></el-avatar>
					<div class="nick-name">{{ $store.state.userInfo.nickName }}</div>
				</div>
				<div class="workbench" v-if="roleList.length">
					<div class="title">
						<img src="../../assets/images/home/MCN-icon.png" alt="" />
						<span>我的工作台</span>
					</div>
					<div class="list">
						<div
							class="item"
							v-for="(item, index) in roleList"
							:key="item.id"
							@click="handleToRoleAdmin(item)"
						>
							<span>{{ item.name }}</span>
							<i class="icon-sohu-xiayiyeqianjinchakangengduo"></i>
						</div>
					</div>
				</div>
				<div class="menu-list">
					<div
						class="item"
						v-if="accountState?.bailState != 'Paid' && isSplitOrder"
					>
						<div class="menu" @click="gotoLink">
							<i class="el-icon-coin"></i>
							<div class="title">服务商保证金</div>
						</div>
					</div>
					<div class="item" v-for="(item, index) in personalList" :key="index">
						<div class="line-view" v-if="index === 3">
							<div class="line"></div>
						</div>
						<div class="menu" @click="changePersonal(item, index)">
							<i :class="[item.icon]"></i>
							<div class="title">{{ item.title }}</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<!-- 聊天 -->
		<transition name="el-zoom-in-top">
			<div
				@click.stop=""
				class="chatDialog"
				v-show="topNavDialog.chatDialogShow"
			>
				<chatBox
					:show="topNavDialog.chatDialogShow"
					@close="topNavDialog.chatDialogShow = false"
				></chatBox>
			</div>
		</transition>
		<!-- 系统通知 -->
		<transition name="el-zoom-in-top">
			<div
				@click.stop=""
				class="systemNotificationDialog"
				v-show="topNavDialog.systemNotificationDialog"
			>
				<systemNotification :system-list="systemOuterList"></systemNotification>
			</div>
		</transition>
		<!-- 申请加入群聊弹窗 -->
		<ApplyJoinGroupDialog ref="ApplyJoinGroupDialogRef"></ApplyJoinGroupDialog>
		<!-- 跳转 -->
		<el-dialog
			title="缴纳保证金"
			:visible.sync="dialogVisible"
			width="480px"
			:before-close="handleClose"
			:modal="false"
		>
			<div class="dialog_bond">
				<img
					style="width: 112px; height: 112px"
					src="@/assets/images/link.png"
					alt=""
				/>
				<div
					style="
						font-size: 16px;
						font-weight: 600;
						line-height: 24px;
						text-align: center;
						letter-spacing: 0em;
						margin-top: 15px;
					"
				>
					国际服务团队为您定制专属品牌出海方案助您轻松开拓海外市场
				</div>
				<el-button
					style="background: #ff6c27; border-color: #ff6c27; margin-top: 15px"
					type="primary"
					@click="gotoLink"
				>
					现在就去
				</el-button>
			</div>
		</el-dialog>
		<guide-view ref="guideViewRef" />
	</div>
</template>
<script>
import myBMap from '@/utils/map';
import chatBox from '@/views/chat/index.vue';
import URL from '@/utils/baseUrl';
import systemNotification from '@/views/systemNotification/index';
import VueEmit from '@/utils/VueEmit';
import siteDialog from './../../components/siteDialog.vue';
import ApplyJoinGroupDialog from '@/views/chat/components/group/setting/ApplyJoinGroupDialog.vue';
import GuideView from '@/components/guideView/index.vue';
export default {
	components: {
		chatBox,
		systemNotification,
		siteDialog,
		ApplyJoinGroupDialog,
		GuideView,
	},
	data() {
		return {
			siteList: {},
			searchInput: '',
			hotWordPageNum: 1,
			hotWordList: [],
			hotWordTotal: 0,
			isSearch: false,
			whetherAll: true, //是否展示全部
			topNavList: [
				{
					name: 'AI工具',
					icon: 'icon-sohu-AIgongju',
					login: true,
					key: '',
				},
				{
					name: '业务合作',
					icon: 'icon-sohu-yewuhezuo',
					login: false,
					key: 'businessShow',
				},
				{
					name: '下载APP',
					icon: 'icon-sohu-xiazai',
					login: false,
					key: 'downloadAppShow',
				},
				// {
				// 	name: '任务',
				// 	icon: 'icon-sohu-renwu',
				// 	login: true,
				// 	key: '',
				// },
				{
					name: '通知',
					icon: 'icon-sohu-tongzhi',
					login: true,
					key: 'systemNotificationDialog',
				},
				{
					name: '消息',
					icon: 'icon-sohu-xiaoxi',
					login: true,
					key: 'chatDialogShow',
				},
				{
					name: '发布作品',
					icon: 'icon-sohu-fabu',
					login: true,
					key: '',
				},
			],
			businessList: [
				{
					title: '企业主入驻',
					adminTitle: '代理管理后台',
					adminState: false,
					value: 'agent',
					url: `/entry/entryIndex?entryId=${
						process.env.VUE_APP_FLAG == 'pro' ? 3 : 17
					}`,
					login: false,
				},
				{
					title: 'MCN入驻',
					adminTitle: 'MCN管理后台',
					adminState: false,
					value: 'mcn',
					url: '/entry/entryIndex?entryId=1',
					login: false,
				},
			],
			topNavDialog: {
				businessShow: false,
				downloadAppShow: false,
				siteDialogShow: false,
				systemNotificationDialog: false,
				chatDialogShow: false,
				informationShow: false,
			},
			personalList: [
				{
					title: '结算中心',
					icon: 'icon-sohu-jiesuanzhongxin',
					path: '/makeMoney/cashOut',
				},
				{
					title: '任务订单',
					icon: 'icon-sohu-renwudingdan',
					path: '/makeMoney/mineOrders',
				},
				{
					title: '充值狐币',
					icon: 'icon-sohu-chongzhihubi',
				},
				{
					title: '退出登录',
					icon: 'icon-sohu-tuichudenglu',
				},
			],
			systemOuterList: [], //系统列表外层
			accountState: {}, //支付状态获取
			dialogVisible: false,
			systemReadCount: 0,
			systemReadPolling: null,
		};
	},
	computed: {
		roleList() {
			return this.$store.state.roleList;
		},
		// 显示消息未读
		isShowMessageDot() {
			return this.$store.state.messageList.some((item) => item.unreadCount);
		},
		//是否登录
		isLogin() {
			return this.$store.getters.isLogin;
		},
		// 是否包含拆单角色
		isSplitOrder() {
			return this.$store.state.userInfo?.rolePermission?.includes('agent');
		},
	},
	watch: {
		isLogin: {
			handler(val) {
				if (val) {
					this.handleShowGuide();
					this.getPayAccount();
				} else {
					this.systemReadPolling = null;
					clearInterval(this.systemReadPolling);
				}
			},
		},
		topNavDialog: {
			handler(nVal) {
				if (nVal.systemNotificationDialog && nVal.chatDialogShow) {
					this.topNavDialog.systemNotificationDialog = false;
				}
			},
			deep: true,
			immediate: true,
		},
		'topNavDialog.chatDialogShow': {
			handler(nVal) {
				this.$store.commit('setChatShow', nVal);
			},
			deep: true,
		},
		$route(nVal, oVal) {
			if (oVal.path == '/searchResult' && oVal.path != nVal.path) {
				this.searchInput = '';
			}
		},
		async 'topNavDialog.systemNotificationDialog'(nVal) {
			if (nVal) {
				await this.handleGetSystemMsg();
			}
		},
	},
	mounted() {
		if (this.$route.query.code) {
			let flag = this.$route.query.code;
			let data = {
				loginType: 'WX_QR',
				code: flag,
			};
			this.$http.authWeixinAppLogin(data).then((res) => {
				if (res.code == 200) {
					if (res.data.flag) {
						//绑定过
						this.$store.commit('setToken', res.data.accessToken);
						window.localStorage.setItem('token', res.data.accessToken);
						this.$router.push({
							path: '/home',
						});
						window.location.reload();
					} else {
						// 无绑定
						this.$login({ uuid: res.data.uuid });
					}
				}
			});
		}
		// 站点
		this.siteList = JSON.parse(window.localStorage.getItem('siteList'));
		if (!this.siteList?.name) {
			this.getLocation();
		} else {
			// 全部
			this.whetherAll = JSON.parse(window.localStorage.getItem('whetherAll'));
		}
		this.getHotWordList();
		VueEmit.$on('hide', () => {
			Object.keys(this.topNavDialog).map((key) => {
				this.topNavDialog[key] = false;
			});
			this.isSearch = false;
		});
		VueEmit.$on('showChat', () => {
			this.topNavDialog.chatDialogShow = true;
		});
		if (this.$route.query.groupId) {
			this.$login().then(async () => {
				// 自动加群
				let openGroup = () => {
					this.topNavDialog.chatDialogShow = true;
					VueEmit.$emit('showChat', {
						receiver: {
							id: this.$route.query.groupId,
						},
						sessionType: 'group',
						userName: groupDetails.name,
						groupUserCount: groupDetails.groupUserNum,
					});
					if (
						this.$store.state.messageList.every(
							(item) => item.receiver.id != this.$route.query.groupId,
						)
					) {
						let messageList = this.$store.state.messageList;
						let data = {
							receiver: {
								id: groupDetails.id,
								name: groupDetails.name,
								avatar: groupDetails.logo,
							},
							sessionType: 'group',
							userName: groupDetails.name,
							userAvatar: groupDetails.logo,
						};
						messageList.unshift(data);
						this.$store.commit('setMessageList', messageList);
					}
					this.$router.replace({
						path: '/',
					});
				};
				const { data: groupDetails } = await this.$http.imGroupDetails(
					this.$route.query.groupId,
				);
				console.log('groupDetails:243256323124 ', groupDetails);
				const res = await this.$http.imGroupUserInGroup(
					this.$route.query.groupId,
				);

				if (res.data) {
					openGroup();
				} else {
					if (groupDetails) {
						if (groupDetails.needConfirm) {
							this.$refs.ApplyJoinGroupDialogRef.open(
								this.$route.query.groupId,
							);
						} else {
							await this.$http.imApplyGroup({
								groupId: this.$route.query.groupId,
								inviteUserId: this.$route.query.inviteId,
								inviteCalculate: this.$route.query.inviteCalculate,
								channelCode: this.$route.query.channelCode,
							});
							openGroup();
						}
					} else {
						this.$message.error('群已解散');
						this.$router.replace({
							path: '/',
						});
					}
				}
			});
		}
		if (this.isLogin) {
			this.getPayAccount();
			this.handleShowGuide();
		}
		console.log(
			'process.env.VUE_APP_FLAG:----- ',
			process.env.VUE_APP_FLAG == 'dev',
		);
	},
	methods: {
		/**
		 * 获取系统通知未读消息数量
		 */
		async getSystemReadNum() {
			const res = await this.$http.getSystemReadNum();
			if (res.code === 200) {
				this.systemReadCount = 0;
				res.data.forEach((item) => {
					this.systemReadCount += item.unReadCount;
				});
			}
		},
		showPoint(item) {
			if (['消息', '通知'].includes(item.name)) {
				switch (item.name) {
					case '消息':
						return this.isShowMessageDot;
					case '通知':
						return Boolean(this.systemReadCount);
				}
			}
		},
		/**
		 * 操作显示指引
		 */
		async handleShowGuide() {
			const res = await this.$http.userIsFirstLogin();
			this.systemReadPolling = null;
			clearInterval(this.systemReadPolling);
			await this.getSystemReadNum();
			if (!this.systemReadPolling) {
				this.systemReadPolling = setInterval(() => {
					this.getSystemReadNum();
				}, 5000);
			}
			if (res.code === 200) {
				console.log(res, '是否为首次登录呢?????');
				if (res.data) this.$refs.guideViewRef.open();
			}
		},
		/**
		 * 前往角色工作台
		 * @param model
		 */
		handleToRoleAdmin(model) {
			window.open(
				`${URL.ADMIN_URL}/index?token=${this.$store.state.token}&roleIdentifier=${model.roleKey}`,
			);
		},
		// 顶部功能
		topNavClick(val) {
			const topNavFun = () => {
				Object.keys(this.topNavDialog).map((key) => {
					if (key == val.key) {
						this.topNavDialog[val.key] = !this.topNavDialog[val.key];
					} else {
						this.topNavDialog[key] = false;
					}
				});
				switch (val.name) {
					case '发布作品':
						window.open(
							`${URL.ADMIN_URL}/publish-works/publishWorks?token=${this.$store.state.token}&roleIdentifier=article`,
						);
						break;
					case 'AI工具':
						this.$router.push('/makeMoney/AITools');
						this.$router.push('/makeMoney/AITools');
						break;
				}
			};
			if (val.login) {
				this.$login().then((res) => {
					topNavFun();
				});
			} else {
				topNavFun();
			}
		},
		//登录
		loginDialogClick() {
			this.$login();
		},
		//退出登录
		outLoginClick() {
			this.$confirmDialog({
				content: '确定退出登录？',
				beforeClose: async (action, close) => {
					if (action == 'confirm') {
						this.topNavDialog.informationShow = false;
						this.$store.dispatch('logOut');
						setTimeout(() => {
							// 获取当前路由路径
							const currentRoute = this.$route.path;
							// 如果当前在首页，则刷新页面
							if (currentRoute === '/home') {
								window.location.reload();
							} else {
								// 否则跳转到首页
								this.$router.push('/home');
							}
						}, 500);
						close();
					}
				},
			});
		},
		//业务合作
		businessClick(val) {
			if (val.login) {
				this.$login().then((res) => {
					if (['角色认证', '实名认证'].includes(val.title)) {
						window.open(
							`${URL.ADMIN_URL}${val.url}?token=${this.$store.state.token}&roleIdentifier=article`,
						);
					}
				});
			} else {
				let link = this.$router.resolve(`${val.url}`);
				window.open(link.href, '_blank');
			}
		},
		// 点击搜索框
		inputClick() {
			this.$refs.searchRef.focus();
			this.isSearch = true;
		},
		/**
		 * 获取系统通知外层列表
		 * @returns {Promise<void>}
		 */
		async handleGetSystemMsg() {
			const res = await this.$http.getSystemOuterList();
			if (res.code === 200) {
				this.systemOuterList = res.data.filter(
					(v) => v.type !== 'outerMerchant',
				);
			}
		},
		// 搜索结果
		handleSearchResult(val) {
			this.searchInput = val;
			this.$router.push({
				path: '/searchResult',
				query: {
					title: this.searchInput,
				},
			});
			this.isSearch = false;
		},
		// 获取热门推荐列表
		async getHotWordList() {
			let res = await this.$http.getHotWordList({
				pageNum: this.hotWordPageNum,
				pageSize: 10,
			});
			this.hotWordList = res.data;
			this.hotWordTotal = res.total;
		},
		// 换一批推荐
		switchClick() {
			if (this.hotWordPageNum < Math.ceil(this.hotWordTotal / 10)) {
				this.hotWordPageNum++;
			} else {
				this.hotWordPageNum = 1;
			}
			this.getHotWordList();
		},
		//点击站点
		siteDialogClick(val) {
			Object.keys(this.topNavDialog).map((key) => {
				if (key == val.key) {
					this.topNavDialog[val.key] = !this.topNavDialog[val.key];
				} else {
					this.topNavDialog[key] = false;
				}
			});
			// this.topNavDialog.siteDialogShow = true;
		},
		//个人中心选项切换
		changePersonal(item) {
			console.log('item: ', item);
			if (item.path) {
				const url = this.$router.resolve({
					path: item.path,
				});
				window.open(url.href, '_blank');
			}
			if (item.title == '充值狐币') {
				this.$foxCoin({
					episodeRelevance: this.$route.query.episodeRelevance,
					userId: this.$store.state.userInfo.id,
				});
			}
			if (item.title == '退出登录') {
				this.outLoginClick();
			}
		},
		// 去投流界面
		goFlow() {
			this.$login().then((res) => {
				this.$router.push({
					path: '/investmentFlow',
				});
			});
		},
		//获取当前位置
		getLocation() {
			//Toast("如长时间未获取办理区域请手动选择");
			myBMap.init().then(() => {
				let that = this;
				let geolocation = new BMap.Geolocation();
				// 创建百度地理位置实例，代替 navigator.geolocation
				geolocation.getCurrentPosition(function (e) {
					if (this.getStatus() == BMAP_STATUS_SUCCESS) {
						// 百度 geolocation 的经纬度属性不同，此处是 point.lat 而不是 coords.latitude
						let point = new BMap.Point(e.point.lng, e.point.lat);
						let gc = new BMap.Geocoder();
						gc.getLocation(point, function (rs) {
							let lat = rs.point.lat;
							let lng = rs.point.lng;
							that.$http
								.getLocationDetail({ location: lat + ',' + lng })
								.then((res) => {
									let val = JSON.parse(res.data);
									that.siteList = val;
									that.$store.commit('setSite', val);
									that.$store.commit('setSiteId', val.id);
									window.localStorage.setItem('siteList', JSON.stringify(val));
									window.localStorage.setItem('siteId', val.id);
									window.localStorage.setItem('whetherAll', 'true');
									that.whetherAll = true;
								});
						});
					} else {
						that.$message.error(`定位失败，请手动选择区域或重新定位`);
					}
				});
			});
		},

		// 获取认证状态
		getPayAccount() {
			this.$http.getPayAccount().then((res) => {
				if (res.code == 200) {
					this.accountState = res.data;
					console.log('this.accountState: ', this.accountState);
				}
			});
		},
		// 服务商保证金条转页面
		bondFn() {
			this.dialogVisible = true;
		},

		gotoLink() {
			window.open(
				`${URL.ADMIN_URL}/user_content/authentication?token=${this.$store.state.token}`,
			);
		},

		handleClose() {
			this.dialogVisible = false;
		},
	},
};
</script>
<style lang="scss">
.dialog_bond {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0 90px;
}
.q-header {
	padding: 18px;
	display: flex;
	justify-content: space-between;
	position: relative;
	z-index: 999;
	box-sizing: border-box;
	height: 72px;
	// box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
	.leftBox {
		display: flex;
		align-items: center;

		.el-input {
			height: 40px;
			width: 520px;
			margin-left: 44px;

			.searchBox {
				cursor: pointer;
				width: 44px;
				height: 40px;
				line-height: 40px;
				border-radius: 20px;
				font-size: 20px;
				color: #fff;
				right: 12px;
				background: $theme-color;

				&:hover {
					background-color: #ff9d30;
				}
			}

			.el-input__inner {
				border-radius: 25px;
				background: #f9faff;
				border: 0px solid #000;
			}
		}

		.inputDiv {
			position: absolute;
			left: 98px;
			top: 64px;
			width: 480px;
			height: 163px;
			border-radius: 16px;
			background: #fff;
			padding: 10px 20px;
			box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05),
				0px 4px 5px 0px rgba(0, 0, 0, 0.08),
				0px 2px 4px -1px rgba(0, 0, 0, 0.12);

			.card_header {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.leftTitle {
					color: rgba(0, 0, 0, 0.9);
					font-weight: 500;
					font-size: 14px;
					line-height: 22px;
					display: flex;
					align-items: center;
				}

				.switchBtn {
					height: 30px;
					border-radius: 91px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 10px;
					cursor: pointer;
					color: #777;
					font-size: 12px;
					font-weight: 500;
					line-height: 24px;
					background: #f8f8fc;

					i {
						margin-right: 6px;
					}

					&:hover {
						background: $theme-color !important;
						color: #fff;
					}
				}
			}

			.guess-want {
				display: grid;
				grid-template-columns: repeat(2, 155px);
				grid-row-gap: 4px;
				grid-column-gap: 50px;

				.guess-item {
					font-size: 12px;
					line-height: 22px;
					color: #777777;
					cursor: pointer;
					width: 155px;

					&:hover {
						color: $theme-color;
					}
				}
			}
		}
	}

	.rightBox {
		display: flex;

		.investmentFlow {
			width: 56px;
			height: 38px;
			text-align: center;
			line-height: 50px;
			color: #fff;
			font-size: 14px;
			font-weight: 500;
			margin-top: -5px;
			cursor: pointer;
			background: url('../../assets/images/InvestmentFlow/flow.png') no-repeat
				100% 100%;
		}

		.siteBox {
			background: #f9faff;
			display: flex;
			align-items: center;
			color: #333333;
			font-size: 14px;
			font-weight: 500;
			height: 36px;
			border-radius: 64px;
			margin-left: 39px;
			padding: 0 21px;

			i {
				font-size: 10px;
				margin-left: 4px;
			}

			cursor: pointer;

			&:hover {
				background: #fff0e9;
			}
		}

		.topNavList {
			display: flex;
			align-items: center;
			margin-left: 45px;

			.topNav_item {
				color: #333333;
				font-weight: 600;
				font-size: 16px;
				cursor: pointer;
				background: #fff;
				padding: 0 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 36px;
				border-radius: 24px;
				margin-left: 4px;
				position: relative;

				&:last-child {
					margin-right: 0;
				}

				.icon {
					font-size: 16px;
					margin-right: 4px;
				}

				&:hover {
					background: #f5f5f9;
				}

				.el-badge {
					position: absolute;
					top: 8px;
					right: 2px;

					.is-dot {
						background: $theme-color;
						width: 4.8px;
						height: 4.8px;
					}
				}
			}
		}

		.userBox {
			display: flex;
			align-items: center;
			color: #333333;
			font-size: 16px;
			font-weight: 500;
			line-height: 22px;
			margin-left: 33px;
			margin-right: 22px;
			cursor: pointer;

			.el-avatar {
				margin-right: 10px;
			}
		}

		.loginBtn {
			width: 64px;
			height: 36px;
			border-radius: 64px;
			background: $theme-color;
			font-size: 16px;
			font-weight: 500;
			color: #ffffff;
			box-shadow: none;
			border: none;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 5px;
			margin-right: 22px;
		}
	}

	.searchResult {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
		padding-top: 8px;
		border-top: 1px solid #e7e7e7;
		padding-left: 30px;

		.item {
			font-size: 14px;
			font-weight: normal;
			line-height: 22px;
			color: rgba(0, 0, 0, 0.9);
			margin-right: 40px;
			margin-bottom: 8px;
			cursor: pointer;

			&:hover {
				color: #ff6c27;
			}
		}

		.nationBox {
			display: flex;
			align-items: center;
			margin-bottom: 18px;

			.backIcon {
				width: 16px;
				height: 16px;
				margin-right: 9px;
				background: #f8f8fc;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;

				.icon-sohu-fanhui {
					font-size: 7px;
					color: #777777;
				}

				&:hover {
					background-color: #ff6c27;

					.icon-sohu-fanhui {
						color: #ffffff;
					}
				}
			}

			.nation {
				color: rgba(0, 0, 0, 0.9);
				font-size: 14px;
				font-weight: normal;
				line-height: 22px;
			}
		}

		.internationalMain {
			flex: 1;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.item {
				width: 60px;
				margin-right: 30px;
				margin-bottom: 8px;
				font-size: 12px;
				font-weight: normal;
				line-height: 22px;
				color: #777777;
				display: -webkit-box !important;
				overflow: hidden;
				text-overflow: ellipsis;
				word-break: break-all;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical !important;
				cursor: pointer;

				&:nth-child(4n + 4) {
					margin-right: 0px;
				}

				&:hover {
					color: #ff6c27;
				}
			}
		}
	}

	.chatDialog {
		position: absolute;
		top: 87px;
		right: 40px;
		z-index: 10;
		width: 880px;
		background-color: #ffffff;
		height: 720px;
		box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05),
			0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
		border-radius: 48px;
		overflow: hidden;
		z-index: 99999;
	}

	.systemNotificationDialog {
		position: absolute;
		top: 87px;
		right: 40px;
		width: 800px;
		background-color: #fff;
		height: 720px;
		box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05),
			0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
		border-radius: 20px;
		overflow: hidden;
		z-index: 99999;
	}

	.siteDialogBox {
		width: 510px;
		// height: 225px;

		position: absolute;
		top: 61px;
		right: 330px;
		border-radius: 12px;
		box-sizing: border-box;
		background-color: #fff;
		box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05),
			0px 8px 10px 1px rgba(0, 0, 0, 0.06), 0px 5px 5px -3px rgba(0, 0, 0, 0.04);
		overflow: hidden;
		z-index: 99999;
	}

	.downloadAppBox {
		position: fixed;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;

		.downloadAppMainBox {
			width: 203px;
			height: 232px;
			background-color: #fff;
			display: flex;
			align-items: center;
			flex-direction: column;
			position: fixed;
			top: 66px;
			right: 365px;
			z-index: 9999;
			border-radius: 6px;
			box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05),
				0px 8px 10px 1px rgba(0, 0, 0, 0.06),
				0px 5px 5px -3px rgba(0, 0, 0, 0.04);

			.title {
				font-size: 14px;
				font-weight: 500;
				line-height: 22px;
				color: rgba(0, 0, 0, 0.9);
				margin-bottom: 5px;
				margin-top: 13px;
			}

			.introduce {
				font-size: 12px;
				color: #999999;
				font-weight: normal;
				line-height: 24px;
				margin-bottom: 10px;
			}

			.img {
				width: 123px;
				height: 123px;
				margin-bottom: 5px;
			}

			.sweep {
				font-size: 12px;
				font-weight: normal;
				line-height: 24px;
				color: #999999;
			}
		}
	}

	.businessCooperationBox {
		position: absolute;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;

		.main {
			position: absolute;
			top: 61px;
			right: 450px;
			z-index: 9999;
			padding: 12px 24px;
			background: #ffffff;
			border-radius: 12px;
			box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05),
				0px 8px 10px 1px rgba(0, 0, 0, 0.06),
				0px 5px 5px -3px rgba(0, 0, 0, 0.04);

			.item {
				width: 104px;
				font-size: 14px;
				font-weight: normal;
				line-height: 22px;
				padding: 3px 8px;
				margin-bottom: 24px;
				cursor: pointer;

				&:hover {
					color: $theme-color;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.personalCenterBox {
		width: 300px;
		height: fit-content;
		position: absolute;
		background-color: #fff;
		top: 72px;
		right: 31px;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		padding: 0 12px;

		.to-center {
			float: right;
			margin-right: 4px;
			margin-top: 16px;
			cursor: pointer;

			span {
				font-size: 14px;
				color: #3d3d3d;
				line-height: 22px;
			}
		}

		.avatra-view {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.nick-name {
				line-height: 22px;
				color: rgba(0, 0, 0, 0.9);
				margin-top: 8px;
			}
		}

		.workbench {
			width: 100%;
			height: fit-content;
			padding: 16px 12px;
			background: linear-gradient(
				122deg,
				rgba(255, 108, 39, 0.16) 0%,
				rgba(255, 108, 39, 0.06) 78%
			);
			border-radius: 4px;
			margin-top: 30px;
			box-sizing: border-box;

			.title {
				display: flex;
				align-items: center;

				img {
					width: 16px;
					height: 16px;
					margin-right: 8px;
				}

				span {
					font-size: 14px;
					color: #3d3d3d;
				}
			}

			.list {
				margin-top: 10px;
				display: grid;
				gap: 8px;
				//justify-content: space-between;
				//align-items: center;

				.item {
					width: 100%;
					height: 32px;
					padding: 0 12px;
					background-color: #ffffff;
					border-radius: 4px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					box-sizing: border-box;
					cursor: pointer;

					img {
						width: 18px;
						height: 18px;
						margin-right: 8px;
					}

					span {
						font-size: 12px;
						line-height: 22px;
						color: #3d3d3d;
					}
				}
			}
		}

		.menu-list {
			width: 100%;
			margin-top: 15px;
			margin-bottom: 15px;

			.item {
				.line-view {
					width: 100%;
					height: 20px;
					display: flex;
					align-items: center;

					.line {
						width: 100%;
						height: 1px;
						background-color: #e7e7e9;
					}
				}

				.menu {
					height: 32px;
					padding: 0 13px;
					display: flex;
					align-items: center;
					cursor: pointer;

					&:hover {
						background-color: rgba(254, 108, 39, 0.06);

						i,
						.title {
							color: #ff6c27;
						}
					}

					i {
						font-size: 16px;
						margin-right: 12px;
					}

					.title {
						font-size: 14px;
						line-height: 22px;
					}
				}
			}
		}

		.nickName {
			padding: 10px 20px;
			width: 123px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 15px;
			font-weight: 500;
			color: rgba(0, 0, 0, 0.9);
			border-bottom: 0.5px solid #e7e7e7;
			text-align: center;
		}

		.personalItem {
			font-size: 14px;
			font-weight: 500;
			padding: 10px 34px;
			display: flex;
			align-items: center;

			.icon {
				font-size: 18px;
				margin-right: 18px;
			}

			cursor: pointer;

			&:hover {
				color: $theme-color;
			}
		}
	}
}
</style>
