<template>
	<dialog-box
		confirmText="新增禁言"
		showBottomBtn
		appendToBody
		v-model="dialogVisible"
		@confirm="addClick"
		class="GroupProhibitionDialog"
	>
		<div slot="header_title">
			<!-- <el-image
				style="margin-right: 8px;position: relative;top:6px"
				:src="require('@/assets/images/chat/GroupBan.png')"
			></el-image> -->
			群禁言
		</div>
		<div class="set_row">
			<div>
				全部禁言
				<span class="subtitle"> 开启后，仅群主和群管理员允许发言</span>
			</div>
			<el-switch
				@change="forbidChange"
				v-model="details.forbid"
				active-color="#13ce66"
			>
			</el-switch>
		</div>
		<div class="rowLine"></div>
		<div class="set_row">
            <div class="setRow_item">
                <div class="setRow_text">
                    按时间段禁言
					<span class="subtitle">开启后，该时间段仅群主和群管理员允许发言</span>
                </div>
                <el-switch 
                v-model="details.forbidTime" 
                @change="handleTimeBan"
                active-color="#13ce66"></el-switch>
            </div>
            
		</div>

        <div class="set_row" @click="openTime" style="margin: 12px 0;">
			<div class="set_row_times">
				<div class="setRow_title">禁言时间<span v-if="timeLength">({{timeLength}}个时间段)</span></div>
				<p v-if="timeStr"><span class="subtitle">{{ timeStr }}</span></p> 
			</div>
			<i class="icon-sohu-xiayiyeqianjinchakangengduo"></i>
		</div>

		<div class="rowLine"></div>
		<div class="set_row">
			<div>
				部分禁言人员
				<span class="subtitle"> （以下成员不允许发言）</span>
			</div>
			<el-button
				:disabled="!userList.length"
				type="text"
				size="mini"
				@click="UnlockingAll"
				>全部解禁</el-button
			>
		</div>
		<div class="userList">
			<AddressBook_row
				v-for="item of userList"
				:key="item.id"
				:item="item"
				type="群禁言"
			></AddressBook_row>
			<CommonEmpty content="暂无禁言" v-if="!userList.length"></CommonEmpty>
		</div>
		<!-- 选择时间段 -->
		<GroupBanTimeDialog @setTimeList="handleTimeList" ref="GroupBanTimeDialogRef"></GroupBanTimeDialog>
		<!-- 选择群对话框 -->
		<SelectMembersDialog ref="SelectMembersDialogRef"></SelectMembersDialog>
	</dialog-box>
</template>
<script>

import DialogBox from '@/components/dialogBox.vue';
import { dateFormat } from '@/utils/util.js'
import AddressBook_row from "@/views/chat/components/user/addressBook_row.vue";
import SelectMembersDialog from "@/views/chat/components/group/setting/selectMembersDialog.vue";
import GroupBanTimeDialog from "@/views/chat/components/group/setting/groupBanTimeDialog.vue";
import CommonEmpty from "@/views/chat/components/common/commonEmpty.vue"
export default {
	components: { DialogBox, AddressBook_row, SelectMembersDialog, GroupBanTimeDialog, CommonEmpty },
	data() {
		return {
			dialogVisible: false,
			userList: [],  //禁言用户列表
			details: {}, //IM详情
			id: '',
			timeStr:'', //时间段字符串
			timeLength:0,
		};
	},
	created() {},
	provide() {
		return {
			imGroupForbidList: this.getList,
		};
	},
	methods: {
		//获取时间段 字符串
		getTimeStr(data){
			this.timeStr = ''
			this.timeLength = data.length
			data.map((item)=>{
				if(item){
					this.timeStr = this.timeStr ? 
					`${this.timeStr}  ${item['startTime']}-${item['endTime']}`:
					`每天 ${item['startTime']}-${item['endTime']}`
				}
			})
		},
		handleTimeList(data){
			this.details.timeList = data
			this.getTimeStr(data)
		},
		async handleTimeBan(val){
			this.details.forbidTime = val
			var res1 = await this.$http.imGroupForbidTime(this.details.id);
			if(val && this.details.forbid){
				this.details.forbid = false
				var res = await this.$http.editImGroup(this.details);
			}		
			if (res?.code == 200 || res1?.code == 200) {
				this.$message.success('切换成功');
			}	
        },
		openTime(){
			//打开时间段弹窗
            this.$refs.GroupBanTimeDialogRef.open(this.details)
        },
		async open(id) {
			this.id = id;
			this.timeStr = ''
			this.dialogVisible = true;
			const res = await this.$http.imGroupDetails(this.id);
			this.details = res.data;
			if(this.details.timeList.length) this.getTimeStr(this.details.timeList)
			this.getList();
		},

		// 获取列表
		async getList() {
			const res = await this.$http.imGroupForbidUsers(this.id);
			this.userList = res.data || [];
			
		},

		// 切换禁言状态
		async forbidChange(val) {
			this.details.forbid = val
			if(val && this.details.forbidTime){
				this.details.forbidTime = false
				var res1 = await this.$http.imGroupForbidTime(this.details.id);
			}else{
				// this.details.forbidTime = true
			}
			var res = await this.$http.editImGroup(this.details);	
			if (res?.code == 200 || res1?.code == 200) {
				this.$message.success('切换成功');
			}
		},

		// 新增群成员
		addClick(close) {
			this.$refs.SelectMembersDialogRef.open({
				title: '新增禁言',
				id: this.details.id,
			});
			close(true);
		},

		// 全部解禁
		UnlockingAll() {
			this.$confirmDialog({
				content: '是否全部解禁？',
				beforeClose: async (state, close) => {
					if (state == 'confirm') {
						const res = await this.$http.imGroupBatchSetforbid({
							forbid: false,
							groupId: this.id,
							userIds: this.userList.map((item) => item.userId),
						});
						if (res.code == 200) {
							this.getList();
							this.$message.success('解除成功');
							close();
						}
					}
				},
			});
		},
	},
};
</script>
<style lang="scss">
.el-switch__core{
	width: 48px!important;
	// height: 28px;
}
.GroupProhibitionDialog {
	.set_row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #333333;
		flex-wrap: wrap;
		margin-bottom: 8px;
		font-size: 14px;
		.set_row_times{
			display: flex;
			align-items: center;
			width: 80%;
			// height: 48px;
			flex-wrap: wrap;
			.setRow_title{
				display: flex;
				align-items: center;
			}
			.setRow_title::before{
				content: '';
				display: inline-block;
				width: 11px;
				height: 1px;
				background: #ACACAC;
				margin-right: 8px;
			}
		}
		p{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 12px;
			padding-left: 18px;
			color: #999999;
			font-size: 12px;
			line-height: 14px;
		}
		.setRow_title{
			width: 100%;
		}
		.setRow_item{
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.subtitle {
			color: rgba(0, 0, 0, 0.26);
		}
		.el-button {
			padding: 0;
		}
		&:last-child {
			margin-top: 24px;
		}
		&:first-child {
			margin-top: 27px;
		}
	}
	.rowLine {
		margin: 16px 0;
		height: 1px;
		background-color: #e7e7e7;
	}
	.userList {
		margin-top: 24px;
	}
}
</style>
