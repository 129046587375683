<template>
	<dialogBox
		showBottomBtn
		appendToBody
		v-model="dialogVisible"
		class="ApplicationDialog"
		@confirm="confirmClick"
	>
		<div slot="header_title" style="display: flex; align-items: center">
			<!-- <el-image
				style="margin-right: 8px"
				:src="require('@/assets/images/chat/Profile.png')"
			></el-image> -->
			申请添加好友
		</div>
		<div class="userData">
			<el-avatar :src="details.userAvatar"></el-avatar>
			<div class="userName">{{ details.nickName }}</div>
		</div>
		<div class="inputBox">
			<div class="inputBox_title">验证消息</div>
			<commonInput
				:rows="6"
				:maxlength="128"
				type="textarea"
				placeholder="请输入"
				v-model="applicationFrom.applyMsg"
			></commonInput>
		</div>
		<div class="inputBox">
			<div class="inputBox_title">设置备注</div>
			<commonInput
				placeholder="请输入备注名称"
				v-model="applicationFrom.alias"
				:maxlength="16"
			></commonInput>
		</div>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import CommonInput from "@/views/chat/components/common/commonInput.vue";
export default {
	components: { dialogBox , CommonInput },
	data() {
		return {
			dialogVisible: false,
			details: {},
			applicationFrom: {
				applyMsg: '',
				alias: '',
			},
		};
	},
	methods: {
		// 打开
		open(id) {
			this.dialogVisible = true;
			this.applicationFrom = {
				applyMsg: '',
				alias: '',
			};
			this.getAppFriendsDetails(id);
		},

		// 好友详情
		async getAppFriendsDetails(id) {
			const res = await this.$http.appFriendsDetails(id);
			console.log(res);
			this.details = res.data;
		},

		// 确定按钮
		async confirmClick(close) {
			const res = await this.$http.addAppFriends({
				friendId: this.details.friendId,
				...this.applicationFrom,
				source: 'platform',
			});
			if (res.code == 200) {
				this.$message.success('发送成功');
				this.$emit('addFriendIng', true);
				close();
			}
		},
	},
};
</script>
<style lang="scss">
.ApplicationDialog {
	.userData {
		display: flex;
		align-items: center;
		margin: 24px 0;
		.el-avatar {
			width: 40px;
			height: 40px;
			margin-right: 10px;
		}
		.userName {
			color: #3d3d3d;
			font-size: 14px;
			font-weight: 500;
		}
	}
	.inputBox {
		margin-bottom: 24px;
		.inputBox_title {
			color: #333333;
			font-size: 14px;
			font-weight: 500;
			margin-bottom: 8px;
		}
	}
}
</style>
