
<template>
    <div class="avatar-grid">
        <el-avatar class="user-item-avatar"
         :class="[{'user-item-avatar-1' : displayedAvatars.length <=4 },{'user-item-avatar-2' : displayedAvatars.length > 4 }]" 
         v-for="item,index in displayedAvatars" :src="item.userAvatar"></el-avatar>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AvatarGrid',
    props: {
      avatars: {
        type: Array
      }
    },
    computed: {
      displayedAvatars() {
        // 如果头像数量少于9个，则直接返回；否则只取前9个
        return this.avatars.slice(0, 9);
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .avatar-grid {
    width: 44px;
    height: 44px;
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(14px, 1fr));
    // grid-auto-rows: 44px;
    // gap: 10px;
    display: flex;
    margin-right: 8px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    overflow: hidden;
    background: #F3F3F3;
    border-radius: 8px;
    .user-item-avatar{
        width: auto;
        height: auto;
        border-radius: 0;
    }
    .user-item-avatar-1{
        width: 21.5px;
        height: 21.5px;
    }
    .user-item-avatar-2{
        width: 14px;
        height: 14px;
    }
  }
  </style>