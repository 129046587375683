var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"searchInput-main"},[_c('el-input',{staticClass:"searchInput searchInput-com",attrs:{"placeholder":"搜索","prefix-icon":"icon-sohu-sousuo1","clearable":""},on:{"input":_vm.throttleSearchChange},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),_c('transition',{attrs:{"name":"el-zoom-top"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchUserGroup),expression:"searchUserGroup"}]},[_c('div',{staticClass:"search-user-group-close",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.searchUserGroup = false},"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();_vm.searchUserGroup = false}}}),_c('div',{staticClass:"search-user-group"},[_c('div',{staticClass:"search-list-scroll"},[_c('div',{staticClass:"search-list-scroll-box"},_vm._l((_vm.searchData),function(list,index){return _c('div',{key:index,staticClass:"search-list"},[(list.length)?_c('div',{staticClass:"search-list-title"},[_vm._v(" "+_vm._s(index == 0 ? '联系人' : '群聊')+" ")]):_vm._e(),_c('div',{staticClass:"search-items"},_vm._l((list),function(item,inx){return _c('div',{key:inx,staticClass:"search-item",on:{"click":function($event){return _vm.toChatRoom(item)}}},[_c('el-avatar',{staticClass:"search-item-img",attrs:{"icon":"el-icon-user-solid","size":"large","shape":"circle","src":item.userAvatar || item.logo,"fit":"fill"}}),(index == 0)?_c('div',{staticClass:"search-item-user"},[_c('p',{staticClass:"over-hidden",domProps:{"innerHTML":_vm._s(
													_vm.setActiveStr(
														item.alias || item.nickName || item.userName,
														_vm.searchInput,
													)
												)}}),(item.nickName)?_c('span',{staticClass:"over-hidden",domProps:{"innerHTML":_vm._s(
													_vm.setActiveStr(
														item.nickName || item.userName,
														_vm.searchInput,
														null,
														'nick',
													)
												)}}):_vm._e()]):_vm._e(),(index == 1)?_c('div',{staticClass:"search-item-user"},[_c('p',{domProps:{"innerHTML":_vm._s(
													_vm.setActiveStr(
														item.name,
														_vm.searchInput,
														`(${item.groupUserNum})`,
													)
												)}})]):_vm._e()],1)}),0)])}),0)]),(_vm.friendToal > 5 || _vm.groupToal > 5)?_c('div',{staticClass:"all-btn",on:{"click":_vm.allClick}},[_vm._v(" "+_vm._s(_vm.isAll ? '收起全部' : '展开全部')+" ")]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }