<template>
	<el-dialog
		:visible.sync="dialogVisible"
		width="680px"
		:show-close="false"
		append-to-body
		:modal-append-to-body="false"
		custom-class="dialogBox"
	>
		<div class="forward-view">
			<div class="user-list">
				<div class="search-view">
					<el-input
						v-model="searchValue"
						placeholder="搜索联系人"
						class="searchInput"
					>
						<i slot="suffix" class="icon-sohu-sousuo1"></i>
					</el-input>
				</div>
				<div class="list-view" v-if="computedList.length">
					<div
						class="item"
						v-for="(item, index) in computedList"
						:key="item.id"
						@click="handleCheck(index)"
					>
						<div class="info">
							<el-avatar :src="item.userAvatar"></el-avatar>
							<div class="name">{{ item.userName }}</div>
						</div>
						<CommonCheckBox
							:disabled="
								Boolean(
									limit && userList.filter((v) => v.check).length === limit,
								)
							"
							v-model="item.check"
						/>
					</div>
				</div>
				<CommonEmpty style="margin-top: 20px" :content="emptyTitle" v-else />
			</div>
			<div class="forward-list">
				<div class="title">
					{{ title }}
					{{
						title === '已选择'
							? `（${userList.filter((v) => v.check).length}）`
							: ''
					}}
				</div>
				<div class="select-list" v-if="userList.filter((v) => v.check).length">
					<div
						class="item"
						v-for="(item, index) in userList.filter((v) => v.check)"
					>
						<div class="info">
							<el-avatar :src="item.userAvatar"></el-avatar>
							<div class="name">{{ item.userName }}</div>
						</div>
						<i class="icon-sohu-guanbi2" @click="handleDelSelect(item)"></i>
					</div>
				</div>
				<div
					v-else
					style="
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: center;
					"
				>
					<CommonEmpty style="margin-top: 20px" content=" " />
				</div>
				<slot name="content-info"> </slot>
				<div class="handle-view">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button
						type="primary"
						:disabled="
							isEmpty ? false : !userList.filter((v) => v.check).length
						"
						@click="handleConfirm"
						>确认
					</el-button>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import CommonEmpty from '@/views/chat/components/common/commonEmpty.vue';
import CommonCheckBox from '@/views/chat/components/common/commonCheckBox.vue';

export default {
	name: 'selectMember',
	components: { CommonEmpty, CommonCheckBox },
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		//不传则不限制选中数量
		limit: {
			type: Number,
			default: 0,
		},
		title: {
			type: String,
			default: '',
		},
		emptyTitle: {
			type: String,
			default: ' ',
		},
		//是否允许空提交
		isEmpty: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dialogVisible: false,
			searchValue: '',
			userList: [],
		};
	},
	computed: {
		computedList() {
			if (this.searchValue) {
				return this.userList.filter((v) =>
					v.userName.includes(this.searchValue),
				);
			} else {
				return this.userList;
			}
		},
	},
	watch: {
		dialogVisible: {
			handler(val) {
				if (!val) {
					this.searchValue = '';
					this.userList.forEach((v) => (v.check = false));
				}
			},
		},
		list: {
			handler(val) {
				val.forEach((v) => {});
				this.userList = val.map((v) => ({ ...v, check: !!v.check }));
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		/**
		 * 操作check
		 * @param index
		 */
		handleCheck(index) {
			if (
				this.limit &&
				this.computedList.filter((v) => v.check).length === this.limit
			)
				return this.$message.info(`最多可转发${this.limit}个好友/群聊`);
			this.computedList[index].check = !this.computedList[index].check;
		},
		/**
		 * 打开弹窗
		 */
		open() {
			this.dialogVisible = true;
		},
		/**
		 * 关闭弹窗
		 */
		close() {
			this.dialogVisible = false;
		},
		/**
		 * 操作删除已选中
		 * @param model
		 */
		handleDelSelect(model) {
			const index = this.userList.findIndex((v) => v.id === model.id);
			this.userList[index].check = false;
		},
		/**
		 * 操作确认按钮
		 */
		handleConfirm() {
			const list = this.userList.filter((v) => v.check);
			this.$emit('confirm', list);
		},
	},
};
</script>
<style lang="scss" scoped>
.dialogBox {
	background: #ffffff;
	overflow: hidden;
	padding: 0 !important;

	&.el-dialog {
		border-radius: 16px;
		padding: 0 !important;

		.el-dialog__header {
			padding: 0;

			& > div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				color: $text-color;
				// font-size: 16px;
				font-size: 24px;
				font-weight: 500;

				.closeIcon {
					font-size: 32px;
					color: #333333;
					cursor: pointer;

					&:hover {
						opacity: 0.8;
					}
				}
			}
		}

		.el-dialog__body {
			padding: 0;

			.forward-view {
				width: 100%;
				height: 546px;
				display: flex;
				box-sizing: border-box;

				.user-list {
					width: 300px;
					height: 100%;
					display: flex;
					flex-direction: column;
					box-sizing: border-box;
					border-right: 1px solid #f3f3f3;
					padding-bottom: 32px;

					.search-view {
						padding: 0 32px;
						box-sizing: border-box;

						.searchInput {
							margin-top: 24px;
							border-radius: 4px;
							height: 36px;
							position: relative;
							box-sizing: border-box;

							.el-input__inner {
								border: 0 solid #000;
								background: #f3f3f3;
								padding-left: 10px;
								padding-right: 40px;

								&::placeholder {
									color: #999999;
									font-size: 14px;
								}
							}

							.el-input__suffix {
								.el-input__clear {
									position: absolute;
									right: 30px;
								}

								.icon-sohu-sousuo1 {
									right: 5px;
									position: absolute;
									top: 10px;
									font-size: 20px;
									color: #5d5d5d;
									cursor: pointer;

									&:hover {
										opacity: 0.8;
									}
								}
							}
						}
					}

					.list-view {
						flex: 1;
						width: 100%;
						margin-top: 24px;
						display: flex;
						flex-direction: column;
						gap: 24px;
						overflow-y: auto;

						.item {
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0 32px;
							cursor: pointer;

							i {
								cursor: pointer;
							}

							.info {
								display: flex;
								align-items: center;

								.el-avatar {
									width: 40px;
									height: 40px;
									margin-right: 10px;
									border-radius: 8px;
								}

								.name {
									max-width: 138px;
									white-space: nowrap;
									text-overflow: ellipsis;
									overflow: hidden;
								}
							}

							.el-checkbox {
								margin-right: 4px;
							}
						}
					}
				}

				.forward-list {
					flex: 1;
					height: 100%;
					padding: 32px 40px 32px 40px;
					box-sizing: border-box;
					display: flex;
					flex-direction: column;

					.title {
						color: #333;
						font-size: 20px;
					}

					.select-list {
						width: 100%;
						flex: 1;
						margin-top: 24px;
						display: flex;
						flex-direction: column;
						gap: 24px;
						overflow-y: auto;

						.item {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.info {
								display: flex;
								align-items: center;

								.el-avatar {
									width: 40px;
									height: 40px;
									margin-right: 10px;
									border-radius: 8px;
								}

								.name {
									max-width: 160px;
									white-space: nowrap;
									text-overflow: ellipsis;
									overflow: hidden;
								}
							}

							.icon-sohu-guanbi2 {
								color: #999;
								cursor: pointer;
								margin-right: 5px;
							}
						}
					}

					.forward-content {
						width: 100%;
						height: 78px;
						border-top: 1px solid #e7e7e7;
						margin-top: 5px;

						.content {
							font-size: 14px;
							color: #999;
							line-height: 22px;
							margin-top: 10px;
						}
					}

					.handle-view {
						display: flex;
						justify-content: center;
						align-items: center;
						gap: 16px;

						.el-button {
							width: 100px;
							height: 40px;
							border: none;
						}

						.el-button--default {
							background-color: #f3f3f3;
						}

						.el-button--primary {
							background-color: $theme-color;
							color: #fff;
							border: none;

							&:hover {
								opacity: 0.8;
							}

							&:active {
								border: 1px solid $theme-color;
								outline: 0;
							}
						}

						.el-button--primary.is-disabled,
						.el-button--primary.is-disabled:active,
						.el-button--primary.is-disabled:focus,
						.el-button--primary.is-disabled:hover {
							background-color: #f3f3f3;
							color: #999999;
							border: none;
						}
					}
				}
			}
		}
	}
}

::v-deep .el-dialog {
	padding: 0 !important;
}
</style>
