<template>
	<dialogBox
		width="550px"
		appendToBody
		v-model="dialogVisible"
		class="GroupMembersDialog"
	>
		<div
			slot="header_title"
			style="display: flex; align-items: center; color: #222222"
		>
			群成员({{ userList?.length }})
		</div>
		<div class="userAvatarList">
			<div
				class="userData"
				v-for="item of userList"
				:key="item.id"
				@click="handleToUserDetail(item)"
			>
				<el-avatar :src="item.userAvatar" class="user-avatar-img"></el-avatar>
				<div class="userData_name line-1">
					{{ item.alias || item.nickName || item.userName }}
				</div>
			</div>
			<div class="setGroupMember" @click="setGroupMember('邀请进群')">
				<i class="icon-sohu-jiahao"></i>
			</div>
			<div
				class="setGroupMember"
				@click="setGroupMember('移除群成员')"
				v-if="isGroupLeader"
			>
				<i class="icon-sohu-jianhao"></i>
			</div>
		</div>

		<div class="handle-view">
			<div class="send-btn" v-if="isSend" @click="handleSendBtn">发消息</div>
		</div>

		<!-- 选择成员列表 -->
		<SelectMembersDialog ref="SelectMembersDialogRef"></SelectMembersDialog>

		<!-- 个人名片弹窗 -->
		<businessCardDialog ref="businessCardDialogRef"></businessCardDialog>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import SelectMembersDialog from './setting/selectMembersDialog.vue';
import BusinessCardDialog from '@/views/chat/components/user/businessCardDialog.vue';

export default {
	components: {
		BusinessCardDialog,
		dialogBox,
		SelectMembersDialog,
	},
	data() {
		return {
			userList: [],
			dialogVisible: false,
			isGroupLeader: false,
			details: '',
			isSend: false,
		};
	},
	provide() {
		return {
			getGroupUserList: this.getUserList,
		};
	},
	inject: {
		getDetails: { value: 'getDetails', default: null },
	},
	methods: {
		handleSendBtn() {
			this.dialogVisible = false;
			this.$emit('toChat');
		},
		/**
		 * 操作前往用户详情
		 * @param model
		 */
		handleToUserDetail(model) {
			console.log(this.details, 'details');
			console.log(this.isGroupLeader, 'isGroupLeader');
			if (model.userId == this.$store.state.userInfo.id) return false;
			if (this.isGroupLeader || !this.details.addFriend) {
				this.$refs.businessCardDialogRef.open(model.userId);
			} else {
				this.$message.info('当前群聊已设置禁止添加好友');
			}
		},
		open(details, isGroupLeader, userList, isSend = false) {
			if (details) {
				this.isGroupLeader = isGroupLeader;
				this.details = details;
				this.userList = userList;
				this.isSend = isSend;
			}
			this.dialogVisible = true;
			this.getUserList();
		},

		// 获取群成员列表
		async getUserList() {
			const res = await this.$http.imGroupUserAll({
				groupId: this.details.id,
			});
			this.userList = res.data;
			if (this.getDetails) {
				this.getDetails(this.details.id);
			}
		},

		// 设置群成员
		setGroupMember(title) {
			this.$refs.SelectMembersDialogRef.open({
				title: title,
				id: this.details.id,
				pid: this.details.pid,
			});
		},
	},
};
</script>
<style lang="scss">
.GroupMembersDialog {
	.userAvatarList {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		// grid-column-gap: 14px;
		grid-column-gap: 20px;
		grid-row-gap: 12px;
		margin-bottom: 16px;
		margin-top: 26px;
		max-height: 650px;
		overflow-y: auto;

		.userData {
			text-align: center;
			.user-avatar-img {
				border-radius: 8px;
			}
			.el-avatar {
				width: 48px;
				height: 48px;
				margin-bottom: 4px;
			}

			.userData_name {
				color: #787878;
				font-size: 14px;
			}
		}

		.setGroupMember {
			border: 1.36px dashed #b2b2b2;
			width: 48px;
			height: 48px;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			i {
				font-size: 24px;
				color: #b2b2b2;
			}

			&:hover {
				opacity: 0.8;
			}
		}
	}
	.handle-view {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.send-btn {
			width: 100px;
			height: 40px;
			background-color: $theme-color;
			color: #fff;
			border-radius: 8px;
			text-align: center;
			line-height: 40px;
			cursor: pointer;
		}
	}
}
</style>
