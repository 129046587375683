<template>
    <main-base-view title="我的好友" :loading="loading" @close="$emit('close')">
        <div class="friend-view">
            <div class="friend-list">
                <div class="list-view" v-if="friendList.some(v=>v.value.length)">
                    <div class="item" :id="`item-${index}`" v-for="(item,index) in friendList" :key="item.key"
                         v-if="item.value.length">
                        <div class="key-view">
                            <div class="key">{{ item.key }}</div>
                        </div>
                        <div class="value-view">
                            <div
                                    :class="['value-item',friend.friendId === friendActive ? 'active' : '']"
                                    v-for="(friend) in item.value"
                                    :key="friend.friendId"
                                    @click="handleFriendChange(friend)"
                            >
                                <el-image class="avatar" :src="friend.userAvatar"></el-image>
                                <div class="name">{{ friend.displayName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pinyin-view">
                    <div class="item" v-for="(item,index) in friendList" :key="item.key"
                         @click="handleScrollToPinyin(item)">
                        {{ item.key }}
                    </div>
                </div>
<!--                <div class="friend-count" v-if="friendList.some(v=>v.value.length)">-->
<!--                    共{{ list.length + 1 }}个好友-->
<!--                </div>-->
                <div class="empty" v-if="!friendList.some(v=>v.value.length) && !loading">
                    <common-empty content=" "/>
                </div>
            </div>
<!--            <div class="friend-detail">-->
<!--                <template v-if="friendList.some(v=>v.value.length)">-->
<!--                    <div class="info-view">-->
<!--                        <div class="friend-info">-->
<!--                            <el-image class="avatar" :src="friendDetail.userAvatar"></el-image>-->
<!--                            <div class="name-view">-->
<!--                                <div class="name">{{ friendNameFilter() }}</div>-->
<!--                                <div class="nick-name" v-if="friendDetail.alias">-->
<!--                                    <span class="label">昵称：</span>-->
<!--                                    {{ friendDetail.nickName }}-->
<!--                                </div>-->
<!--                                <div class="id-view">ID：{{ friendDetail.userName }}</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="more-handle" v-if="!isMy">-->
<!--                            <i class="icon-sohu-gengduo" @click="handleShowDropdown"></i>-->
<!--                            <dropdown ref="dropdownRef" @handleEvent="handleDropdownEvent"/>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="set-view">-->
<!--                        <div class="item handle" @click="handleDropdownEvent('setLabel')" v-if="!isMy">-->
<!--                            <div class="label">备注：</div>-->
<!--                            <div :class="['value',friendDetail.alias ? '' : 'empty']">-->
<!--                                {{ friendDetail.alias || '未设置备注' }}-->
<!--                                <i class="icon-sohu-xiugai"></i>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="item" v-if="friendDetail.brief">-->
<!--                            <div class="label">个人简介：</div>-->
<!--                            <div class="value">{{ friendDetail.brief }}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="handle-view">-->
<!--                        <div class="btn" @click="handleToChat">发消息</div>-->
<!--                    </div>-->
<!--                </template>-->
<!--            </div>-->
        </div>
        <!--转发-->
        <forwardDialog :list="messsageList" ref="forwardDialogRef" :limit="9"/>
        <!-- 备注和标签设置弹窗 -->
        <setRemarksDialog ref="setRemarksDialogRef" @update="$emit('updateList')"></setRemarksDialog>
        <!--个人名片-->
        <business-card-dialog ref="businessCardDialogRef" @toChat="handleToChat" />
    </main-base-view>
</template>

<script>
import pinyin from "@/utils/pinyin"
import {friendBaseList} from "./data/friend";
import ForwardDialog from "@/views/chat/components/chat/forwardDialog.vue";
import SetRemarksDialog from "@/views/chat/components/user/setRemarksDialog.vue";
import MainBaseView from "@/views/chat/components/friend/base/mainBaseView.vue";
import Dropdown from "@/views/chat/components/common/dropdown.vue";
import CommonEmpty from "@/views/chat/components/common/commonEmpty.vue";
import BusinessCardDialog from "@/views/chat/components/user/businessCardDialog.vue";

export default {
    name: "meFriendView",
    components: {
        ForwardDialog,
        SetRemarksDialog,
        MainBaseView,
        Dropdown,
        CommonEmpty,
        BusinessCardDialog
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        list: {
            handler(val) {
                this.friendList.forEach(v => v.value = []);
                if (val.length === 0) {
                    const valueKey = pinyin.processString(this.userInfo.nickName || this.userInfo.userName);
                    const index = this.friendList.findIndex(friend => friend.key === valueKey);
                    this.$set(this.friendList[index].value,0,{
                        displayName: this.userInfo.nickName || this.userInfo.userName,
                        nickName: this.userInfo.nickName,
                        userName: this.userInfo.userName,
                        alias: "",
                        friendId: this.userInfo.id,
                        userAvatar: this.userInfo.userAvatar
                    })
                } else {
                    val.forEach(v => {
                        const valueKey = pinyin.processString(v.displayName);
                        const index = this.friendList.findIndex(friend => friend.key === valueKey);
                        this.friendList[index].value.push({...v})
                    })
                    const valueKey = pinyin.processString(this.userInfo.nickName || this.userInfo.userName);
                    const index = this.friendList.findIndex(friend => friend.key === valueKey);
                    this.friendList[index].value.unshift({
                        displayName: this.userInfo.nickName || this.userInfo.userName,
                        nickName: this.userInfo.nickName,
                        userName: this.userInfo.userName,
                        alias: "",
                        friendId: this.userInfo.id,
                        userAvatar: this.userInfo.userAvatar
                    })
                }
                if (!this.friendActive) {
                    this.friendActive = this.friendList.filter(v => v.value.length !== 0)[0].value[0].friendId;
                } else {
                    // this.getFriendDetail();
                    this.loading = false;
                }
            },
            deep: true,
        },
        friendActive(val) {
            // this.getFriendDetail();
            this.loading = false;
        }
    },
    computed: {
        messsageList() {
            return this.$store.state.messageList.map(v => ({...v, check: false}));
        },
        userInfo() {
            return this.$store.state.userInfo;
        },
        isMy() {
            return this.friendDetail.friendId === this.userInfo.id;
        }
    },
    data() {
        return {
            friendList: [...friendBaseList()],
            friendActive: 0,
            friendDetail: {},
            handleMenuShow: false,
            loading: true,
        }
    },
    methods: {
        /**
         * 操作前往聊天
         */
        handleToChat(model) {
            this.$emit('toChat', {
                ...model,
                id: model.friendId,
                sessionType: "single",
                friendId: model.friendId,
            })
        },
        /**
         * 菜单操作
         * @param model
         */
        handleDropdownEvent(model) {
            switch (model) {
                case "setLabel":
                    this.$refs.setRemarksDialogRef.open(this.friendActive);
                    break;
                case "recommend":
                    this.$refs.forwardDialogRef.open({
                        messageType: "share",
                        // sessionType: "single",
                        content: "个人名片",
                        share: {
                            type: "person",
                            id: this.friendDetail.friendId,
                            name: this.friendDetail.nickName || this.friendDetail.userName,
                            avatar: this.friendDetail.userAvatar
                        }
                    }, 'newMessage');
                    break;
                case "delete":
                    this.handleDeleteFriend();
                    break;
            }
        },
        /**
         * 操作删除好友
         */
        handleDeleteFriend() {
            this.$confirmDialog({
                title: "删除好友",
                content: '确定删除该好友？',
                confirmText: '确认',
                beforeClose: async (action, close) => {
                    console.log(action, "action");
                    if (action == 'confirm') {
                        const res = await this.$http.friendsDelete(this.friendActive);
                        if (res.code === 200) {
                            this.$message.success("删除成功");
                            this.$emit("updateList");
                        }
                    }
                },
            });
        },
        /**
         * 显示菜单
         */
        handleShowDropdown(event) {
            const width = "150px";
            const height = "160px";
            const top = event.pageY - event.offsetY + event.target.offsetHeight + "px";
            const left = event.pageX - event.offsetX + event.target.offsetWidth + "px";
            const list = [
                {
                    title: "设置备注和标签",
                    event: "setLabel"
                },
                {
                    title: "推荐给朋友",
                    event: "recommend"
                },
                {
                    title: "删除好友",
                    event: "delete"
                }
            ]
            this.$refs.dropdownRef.open({
                width,
                height,
                top,
                left: `calc(${left} - ${width})`
            }, list)
        },
        friendNameFilter() {
            return this.friendDetail.alias || this.friendDetail.nickName || this.friendDetail.userName;
        },
        /**
         * 操作好友切换
         * @param model
         */
        handleFriendChange(model) {
            // this.friendActive = model.friendId;
            this.$refs.businessCardDialogRef.open(model.friendId,true);
        },
        /**
         * 获取好友详情
         */
        async getFriendDetail() {
            const res = await this.$http.appFriendsDetails(this.friendActive);
            if (res.code === 200) {
                this.friendDetail = res.data;
                this.loading = false;
            }
        },
        /**
         * 操作滚轴前往指定拼音
         * @param model
         */
        handleScrollToPinyin(model) {
            const listDom = document.querySelector(".friend-list .list-view");
            const childDom = document.querySelector(`#item-${this.friendList.findIndex(v => v.key === model.key)}`)
            if (childDom) {
                listDom.scrollTo({
                    top: childDom.offsetTop,
                    behavior: "smooth"
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.friend-view {
	width: 100%;
	height: 100%;
	display: flex;

  .friend-list {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    padding-right: 30px;
    flex-direction: column;

		.list-view {
			height: 100%;
			overflow-y: auto;

			.item {
				width: 100%;
				height: fit-content;

				.key-view {
					padding: 0 30px;
					box-sizing: border-box;

					.key {
						width: 100%;
						height: 100%;
						line-height: 50px;
						font-size: 12px;
						color: #999;
						border-bottom: 1px solid #e7e7e7;
					}
				}

				.value-view {
					width: 100%;
					height: fit-content;
					padding: 0 10px;
					margin-top: 15px;
					box-sizing: border-box;

					.value-item {
						width: 100%;
						height: 74px;
						display: flex;
						align-items: center;
						padding-left: 20px;
						box-sizing: border-box;
						cursor: pointer;
						border-radius: 8px;

            //&.active {
            //  background-color: #F3F3F3;
            //}

						.avatar {
							width: 44px;
							height: 44px;
							border-radius: 8px;
							margin-right: 15px;
						}

						.name {
							font-size: 16px;
							color: #222222;
						}
					}
				}
			}
		}

		.friend-count {
			font-size: 12px;
			color: #999;
			line-height: 17px;
			margin: 20px 0;
			text-align: center;
		}

    .pinyin-view {
      width: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);

			.item {
				width: 12px;
				font-size: 12px;
				color: #999999;
				cursor: pointer;
				text-align: center;
			}
		}
	}

	.friend-detail {
		width: 50%;
		padding: 20px 20px 0 20px;
		box-sizing: border-box;

		.info-view {
			width: 100%;
			height: 66px;
			display: flex;
			justify-content: space-between;

			.friend-info {
				display: flex;
				align-items: center;

				.avatar {
					width: 66px;
					height: 66px;
					border-radius: 8px;
					margin-right: 14px;
				}

				.name-view {
					.name {
						color: #222;
						font-size: 16px;
						line-height: 22px;
					}

					.nick-name {
						color: #222;
						font-size: 12px;
						line-height: 17px;
						margin-top: 4px;

						.label {
							color: #999;
						}
					}

					.id-view {
						color: #999;
						font-size: 12px;
						line-height: 17px;
						margin-top: 5px;
					}
				}
			}

			.more-handle {
				font-size: 32px;
				cursor: pointer;
				position: relative;

				.handle-menu {
					position: absolute;
					top: 30px;
					right: 0;
					width: 150px;
					height: 160px;
					border-radius: 10px;
					background-color: #fff;
					box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
				}
			}
		}

		.set-view {
			width: 100%;
			margin-top: 31px;

			.item {
				height: 55px;
				border-top: 1px solid #e7e7e7;
				font-size: 14px;
				display: flex;
				align-items: center;

				&.handle {
					cursor: pointer;
				}

				.label {
					color: #666;
					white-space: nowrap;
				}

				.value {
					width: fit-content;

					&.empty {
						color: #999999;
					}

					i {
						color: #999999;
						font-size: 16px;
						vertical-align: center;
					}
				}
			}
		}

		.handle-view {
			display: flex;
			justify-content: center;
			margin-top: 60px;

			.btn {
				width: 100px;
				height: 40px;
				background-color: $theme-color;
				font-size: 16px;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
				cursor: pointer;
			}
		}
	}

	.empty {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
