var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chatSendBox",on:{"click":function($event){return _vm.closeRefBox()}}},[(_vm.showContentCard)?_c('div',{staticClass:"contentCard"},[(_vm.cardType == 'task')?_c('div',{staticClass:"taskCard"},[_c('div',{staticClass:"leftContent"},[_c('div',{staticClass:"close icon-sohu-quxiao",on:{"click":function($event){_vm.showContentCard = false}}}),_c('el-image',{staticClass:"left_img",attrs:{"src":require('@/assets/images/task-bgc.png')}}),_c('div',{staticClass:"taskCard_content"},[_c('div',{staticClass:"task_title line-2"},[_vm._v(" "+_vm._s(_vm.cardData.title)+" ")]),_c('div',{staticClass:"footer-view"},[_c('div',{staticClass:"task_price"},[_c('span',[_vm._v("赚")]),_vm._v(" "+_vm._s(_vm.cardData.fullAmount)+" ")]),_c('div',{staticClass:"send-task",on:{"click":_vm.sendShare}},[_vm._v("发送商单")])])])],1)]):_vm._e()]):_vm._e(),(
			[
				'group',
				'groupTask',
				'groupTaskCustom',
				'groupForm',
				'groupFormCustom',
			].includes(_vm.chatUserMsgActive.sessionType)
		)?_c('div',{staticClass:"operationRow"},[(_vm.isGroupLeader || _vm.isGroupAdmin)?_c('div',{staticClass:"operation_item",on:{"click":function($event){return _vm.operation_item_click('@所有人')}}},[_c('el-image',{attrs:{"src":require('@/assets/images/chat/@Everyone.png')}}),_vm._v(" @所有人 ")],1):_vm._e(),(
				(_vm.isGroupLeader || _vm.isGroupAdmin) &&
				_vm.chatUserMsgActive.sessionType != 'groupForm' &&
				_vm.details.needConfirm
			)?_c('div',{staticClass:"operation_item",on:{"click":function($event){return _vm.operation_item_click('进群审批')}}},[_c('el-image',{attrs:{"src":require('@/assets/images/chat/groupApproval.png')}}),_vm._v(" 进群审批 ")],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"send-content-area"},[_c('div',{class:[
				{ disabled: _vm.Prohibition },
				'send-btn-area',
				'send-btn-area-left',
			]},[_c('file-upload',{staticStyle:{"margin-right":"10px"},attrs:{"disabled":_vm.Prohibition,"fileMaxSize":50,"limit":9,"fileTypes":[
					'.doc',
					'.docx',
					'.xls',
					'.xlsx',
					'.ppt',
					'.pptx',
					'.pdf',
				]},on:{"beforeUpload":_vm.handleBeforeUpload,"uploadSuccess":_vm.handleUploadSuccess,"success":_vm.onImageSuccess}},[_c('i',{staticClass:"icon-sohu-wenjianjia iconfont"})]),_c('file-upload',{attrs:{"disabled":_vm.Prohibition,"fileMaxSize":50,"fileTypes":['.jpeg', '.png', '.jpg', '.webp', '.gif', '.mp4']},on:{"success":_vm.onImageSuccess}},[_c('i',{staticClass:"icon-sohu-tupian1 iconfont"})])],1),_c('div',{ref:"editBox",staticClass:"send-text-area",attrs:{"disabled":_vm.Prohibition,"placeholder":_vm.Prohibition
					? _vm.noSendText
					: '发送消息(按enter直接发送，shift+enter换行)',"contenteditable":!_vm.Prohibition},on:{"paste":function($event){$event.preventDefault();return _vm.onEditorPaste.apply(null, arguments)},"input":_vm.onEditorInput,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.onKeyDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.onKeyUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.sendClick.apply(null, arguments)}],"compositionstart":_vm.handleCompositionstart,"compositionend":_vm.handleCompositionend}}),_c('div',{class:[{ disabled: _vm.Prohibition }, 'send-btn-area']},[_c('div',{class:[{ confim: _vm.isSendMsg.length > 0 }, 'send-msg-btn'],on:{"click":function($event){return _vm.sendMessage('text')}}},[_c('i',{staticClass:"icon-sohu-a-fasong1 iconfont",staticStyle:{"font-size":"20px"}})])])]),_c('GroupApprovalDialog',{ref:"GroupApprovalDialogRef"}),_c('chatAtBox',{ref:"atBox",attrs:{"search-text":_vm.atSearchText,"members":_vm.userList},on:{"select":_vm.onAtSelect}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }