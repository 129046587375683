//消息类型
export const MessageEnum = {
    // 未知
    unknown: "",
    // 文本
    text: "text",
    // 图片
    photo: "photo",
    // 视频
    video: "video",
    // 语音
    voice: "voice",
    // 分享
    share: "share",
    // 语音通话
    voiceCall: "voiceCall",
    // 视频通话
    videoCall: "videoCall",
    //  voiceCallCancel  : "voiceCallCancel"    // 取消或拒绝
    //  videoCallCancel  : "videoCallCancel"    // 取消或拒绝
    //  voiceCallFinish  : "voiceCallFinish"    // 结束或完成
    //  videoCallFinish  : "videoCallFinish"    // 结束或完成
    //  mediaCallBusy  : "voiceCallBusy"        // 对方忙碌
    // 公告
    notice: "notice",
    // 提示
    tip: "tip",
    // 文件
    file: "file",
    // 命令
    command: "command",

}