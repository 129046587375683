<template>
    <DialogBox
        confirmText="新增禁言"
        showBottomBtn
        appendToBody
        v-model="dialogVisible"
        class="Group-Ban"
        @confirm="addClick"
    >
        <div slot="header_title">群禁言设置</div>

        <div class="setRow">
            <div class="setRow_item">
                <div class="setRow_text"><span>全部禁言</span><i>开启后，仅群主和群管理员允许发言</i></div>
                <el-switch
									class="switch-view"
									v-model="details.allBan"
									@change="handleAllBan"
									active-color="#13ce66"
								></el-switch>
            </div>
		</div>

        <div class="setRow">
            <div class="setRow_item">
                <div class="setRow_text">
                    按时间段禁言
                </div>
                <el-switch
									style="width: 38px;height: 28px;"
									v-model="details.timeBan"
									@change="handleTimeBan"
									active-color="#13ce66"
								></el-switch>
            </div>
            <p>开启后，该时间段仅群主和群管理员允许发言</p>
		</div>

        <div class="setRow">
			<div class="setRow_title">禁言时间(三个时间段)</div>
            <p @click="openTime">时间..........</p>
		</div>

        <div class="setRow-line"></div>

        <div class="setRow">
			<div class="setRow_item">
                <div><b>部分禁言成员</b><i>(以下成员不允许发言)</i></div>
                <div class="relieve_ban" @click="allNoBan">全部解禁</div>
            </div>
            <CommonEmpty
							height="200px"
              content="暂无禁言"
							v-if="1"
						></CommonEmpty>
		</div>
        <GroupBanTimeDialog ref="GroupBanTimeDialogRef"></GroupBanTimeDialog>
    </DialogBox>
</template>

<script>

import DialogBox from '@/components/dialogBox.vue';
import GroupBanTimeDialog from "@/views/chat/components/group/setting/groupBanTimeDialog.vue";
import CommonEmpty from "@/views/chat/components/common/commonEmpty.vue";
export default {
    components:{
        DialogBox,
        GroupBanTimeDialog,
				CommonEmpty
    },
    provide() {
		return {
			groupAdminList: this.open,
		};
	},
    data(){
        return{
            dialogVisible:false,
            details:{},
        }
    },
    methods:{
        openTime(){
            console.log('打开时间弹窗',this.$refs.GroupBanTimeDialogRef)
            this.$refs.GroupBanTimeDialogRef.open(this.details)
        },
        async open(id){
            this.dialogVisible = true
        },
        addClick(){
            console.log('提交设置',this.details)
        },
        handleTimeBan(){
            console.log('按时间段禁言')
        },
        handleAllBan(){
            console.log('全部禁言')
        },
        allNoBan(){
            console.log('全部解除禁言')
        }
    }
}
</script>

<style lang="scss">
.el-switch {
  width: 38px;
  height: 28px;
}
.Group-Ban{
    .setRow {
        margin-top: 23px;
        .setRow_title {
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
        }
        .setRow_item{
            width: 430px;
            height: 40px;
            // border-radius: 10px;
            // border: 1px solid #ccc;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 20px;
            .setRow_text_input{
                width: 80px;
                height: 30px;
                border-radius: 5px;
                .el-input{
                    width: 100%;
                    height: 100%;
                    .el-input__inner{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .setRow_text{
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

</style>