<template>
	<main-base-view title="标签" :loading="loading" @close="$emit('close')">
		<div class="label-view">
			<div class="label-list">
				<div class="handle-view" v-if="labelList.length < 50">
					<div class="add-btn" @click="handleShowSetLabel('add')">
						<i class="icon-sohu-jiahao"></i>
						<div class="value">新建标签</div>
					</div>
				</div>
				<div class="list-view" v-if="labelList.length">
					<div :class="['item',labelActive === item.id ? 'active' : '']" v-for="(item,index) in labelList"
							 :key="item.name" @click="handleLabelChange(item)">
						<div class="title">{{ item.name }}（{{ item.tagFriendCount || 0 }}）</div>
						<div class="item-handle" v-if="labelActive === item.id">
							<div class="btn edit">
								<i class="icon-sohu-xiugai" @click="handleShowSetLabel('edit',item)"></i>
							</div>
							<div class="btn del">
								<i class="icon-sohu-shanchu3" @click="handleDeleteLabel"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="empty" v-if="!list.length && !loading">
					<common-empty content=" " />
				</div>
				<div class="label-count" v-if="labelList.length">
					共{{ labelList.length }}个标签
				</div>
			</div>
			<!--            <div class="label-detail">-->
			<!--                <div class="detail-main" v-if="labelList.length">-->
			<!--                    <div class="handle-view">-->
			<!--                        <div class="edit-btn" @click="handleShowAddMember">-->
			<!--                            <i class="icon-sohu-jiahao"></i>-->
			<!--                            <div class="value">添加好友至该标签</div>-->
			<!--                        </div>-->
			<!--                    </div>-->
			<!--                    <div class="list-view" v-if="labelFriendList.length">-->
			<!--                        <div class="item" v-for="(item,index) in labelFriendList" :key="item.id">-->
			<!--                            <el-image class="avatar" :src="item.userAvatar"></el-image>-->
			<!--                            <div class="name">{{ friendNameFilter(item) }}</div>-->
			<!--                        </div>-->
			<!--                    </div>-->
			<!--                </div>-->
			<!--            </div>-->
		</div>
		<handle-label ref="handleLabelRef" :friendList="friendList" @confirm="handleLabelInfo" />
		<select-member ref="selectMemberRef" title="已选择" emptyTitle="全部好友已在该标签" :list="friendBook"
									 @confirm="handleAddFriendLabel" :isEmpty="true" />
	</main-base-view>
</template>

<script>
import HandleLabel from "@/views/chat/components/friend/dialog/handleLabel.vue";
import SelectMember from "@/views/chat/components/common/selectMember.vue";
import MainBaseView from "@/views/chat/components/friend/base/mainBaseView.vue";
import CommonEmpty from "@/views/chat/components/common/commonEmpty.vue";
import pinyin from "@/utils/pinyin";

export default {
	name: "labelView",
	components: {
		HandleLabel,
		SelectMember,
		MainBaseView,
		CommonEmpty,
	},
	props: {
		list: {
			type: Array,
			default: [],
		},
		friendList: {
			type: Array,
			default: [],
		},
	},
	watch: {
		friendList: {
			handler(val) {
				this.friendBook = [...val.filter(v => v.id)];
			},
			deep: true,
			immediate: true,
		},
		list: {
			handler(val) {
				this.loading = false;
				this.labelList = [...val];
				if (sessionStorage.labelActive) {
					this.labelActive = Number(sessionStorage.labelActive);
				} else {
					this.labelActive = this.labelList[0] ? this.labelList[0].id : 0;
				}
			},
			deep: true,
		},
		labelActive(val) {
			if (!val) return;
			sessionStorage.labelActive = val;
			this.getLabelFriendList();
		},
	},
	beforeDestroy() {
		sessionStorage.removeItem("labelActive");
	},
	data() {
		return {
			labelList: [], //标签列表
			labelActive: 0, //标签激活
			labelFriendList: [], //标签下的好友
			friendBook: [], //通讯录列表
			loading: true,
		};
	},
	methods: {
		/**
		 * 操作添加好友标签
		 */
		async handleAddFriendLabel(list) {
			const res = await this.$http.setFriendLabel({
				tagId: this.labelActive,
				name: this.labelList.find(v => this.labelActive === v.id).name,
				friendIds: list.map(v => v.id),
			});
			if (res.code === 200) {
				this.$message.success("标签操作好友成功");
				this.$refs.selectMemberRef.close();
				await this.getLabelFriendList();
			}
		},
		/**
		 * 好友名称处理
		 * @param model
		 * @returns {String}
		 */
		friendNameFilter(model) {
			return model.alias || model.nickName || model.userName;
		},
		/**
		 * 获取标签下用户列表
		 * @returns {Promise<void>}
		 */
		async getLabelFriendList() {
			const res = await this.$http.labelFriendList({ tagId: this.labelActive });
			if (res.code === 200) {
				this.labelFriendList = res.data.map(v => {
					const displayName = v.alias || v.nickName || v.userName;
					return {
						...v,
						keyValue: pinyin.processString(displayName),
					};
				}).sort((a, b) => {
					// 如果a的keyValue是'#'，那么它应该在b之后
					if (a.keyValue === "#") return 1;
					// 如果b的keyValue是'#'，那么它应该在a之后
					if (b.keyValue === "#") return -1;
					// 如果都不是'#'，那么按照正常的字典顺序排序
					return a.keyValue.localeCompare(b.keyValue);
				});
				this.labelList.forEach(v => {
					if (this.labelActive === v.id) v.tagFriendCount = res.data.length;
				});
			}
		},
		/**
		 * 操作标签
		 * @param model
		 * @returns {Promise<void>}
		 */
		async handleLabelInfo(model) {
			let res;
			let msg;
			switch (model.type) {
				case "add":
					res = await this.$http.setFriendLabel({
						name: model.name,
						friendIds: model.friendIds,
					});
					msg = "新建标签成功";
					break;
				case "edit":
					res = await this.$http.setFriendLabel({
						tagId: model.id,
						name: model.name,
						friendIds: model.friendIds,
					});
					msg = "编辑标签成功";
					break;
			}
			this.$refs.handleLabelRef.close();
			if (res.code === 200) {
				this.$message.success(msg);
				switch (model.type) {
					case "add":
						this.$emit("updateList");
						break;
					case "edit":
						this.$emit("updateList");
						this.getLabelFriendList();
						break;
				}
			}
		},
		/**
		 * 曹组标签切换
		 * @param model
		 */
		handleLabelChange(model) {
			if (this.labelActive === model.id) return;
			this.labelActive = model.id;
		},
		/**
		 * 操作删除标签
		 */
		handleDeleteLabel() {
			const model = this.labelList.find(v => v.id === this.labelActive);
			this.$confirmDialog({
				title: "删除标签",
				content: `确定删除${model.name}标签组？`,
				confirmText: "确认",
				beforeClose: async (action, close) => {
					if (action === "confirm") {
						const res = await this.$http.deleteLabel(this.labelActive);
						if (res.code === 200) {
							if (this.labelActive === Number(sessionStorage.labelActive)) sessionStorage.removeItem("labelActive");
							this.$message.success("删除成功");
							this.$emit("updateList");
							close();
						}
					}
				},
			});
		},
		/**
		 * 显示标签添加成员
		 */
		handleShowAddMember() {
			this.friendBook = [...this.friendList];
			this.friendBook = this.friendBook.map(v => ({
				...v,
				check: false,
				keyValue: pinyin.processString(v.displayName),
			})).sort((a, b) => {
				// 如果a的keyValue是'#'，那么它应该在b之后
				if (a.keyValue === "#") return 1;
				// 如果b的keyValue是'#'，那么它应该在a之后
				if (b.keyValue === "#") return -1;
				// 如果都不是'#'，那么按照正常的字典顺序排序
				return a.keyValue.localeCompare(b.keyValue);
			});
			this.friendBook.forEach((item, index) => {
				if (this.labelFriendList.find(v => item.id === v.id)) {
					this.$set(this.friendBook, index, { ...item, check: true });
				}
			});
			// this.friendBook = this.friendBook.filter(v => v.check === false);
			this.$refs.selectMemberRef.open();
		},
		/**
		 * 显示标签操作框
		 * @param type
		 * @param model
		 */
		handleShowSetLabel(type, model = null) {
			switch (type) {
				case "add":
					this.$refs.handleLabelRef.open({
						type,
					});
					break;
				case "edit":
					this.$refs.handleLabelRef.open({
						type,
						data: model ? { ...model, list: this.labelFriendList } : null,
					});
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.label-view {
  width: 100%;
  height: 100%;
  display: flex;

  .label-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #E7E7E7;
    box-sizing: border-box;

    .handle-view {
      width: 100%;
      padding: 30px 30px 0 30px;
      box-sizing: border-box;

      .add-btn {
        width: 270px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: rgba(255, 108, 39, 0.1);
        color: $theme-color;
        font-size: 14px;
        cursor: pointer;

        i {
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }

    .list-view {
      flex: 1;
      overflow-y: auto;
      padding: 20px 12px 0 12px;
      box-sizing: border-box;

      .item {
        width: 100%;
        height: 74px;
        font-size: 16px;
        color: #222;
        line-height: 74px;
        padding: 0 20px 0 17px;
        box-sizing: border-box;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.active {
          background-color: #F3F3F3;
        }

        .title {
          width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .item-handle {
          width: fit-content;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 10px;

          .btn {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;

            &.edit {
              color: #222222;
            }

            &.del {
              color: #F94040;
            }
          }
        }
      }
    }

    .label-count {
      font-size: 12px;
      color: #999;
      line-height: 17px;
      margin: 20px 0;
      text-align: center;
    }
  }

  .label-detail {
    width: 50%;

    .detail-main {
      height: 100%;
      display: flex;
      flex-direction: column;

      .handle-view {
        width: 100%;
        height: 72px;
        padding: 30px 30px 0 30px;
        box-sizing: border-box;

        .edit-btn {
          //width: 270px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background-color: rgba(255, 108, 39, 0.1);
          color: $theme-color;
          font-size: 14px;
          cursor: pointer;

          i {
            font-size: 12px;
            margin-right: 5px;
          }
        }
      }

      .list-view {
        flex: 1;
        box-sizing: border-box;
        overflow-y: auto;

        .item {
          display: flex;
          align-items: center;
          height: 74px;
          padding-left: 30px;
          box-sizing: border-box;

          .avatar {
            width: 44px;
            height: 44px;
            border-radius: 8px;
            margin-right: 15px;
          }

          .name {
            color: #222;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>