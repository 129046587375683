<template>
	<div class="guide-container" v-if="showGuide">
		<div class="step-one" v-if="stepValue === 1">
			<img class="notice" src="@/assets/images/guide/guide-notice.png">
			<img class="line" src="@/assets/images/guide/guide-vertical-line.png">
			<step-card :value="stepValue" title="通知" desc="点击可查看狐少少站内通知。" @skip="handleSkip"
								 @next="handleNext" />
		</div>
		<div class="step-two" v-if="stepValue === 2">
			<img class="message" src="@/assets/images/guide/guide-message.png">
			<img class="line" src="@/assets/images/guide/guide-vertical-line.png">
			<step-card :value="stepValue" title="消息" desc="可以接受和发送好友信息和群消息。" @skip="handleSkip"
								 @next="handleNext" />
		</div>
		<div class="step-three" v-if="stepValue === 3">
			<div class="avatar">
				<img src="@/assets/images/guide/guide-avatar.png">
			</div>
			<img class="person" src="@/assets/images/guide/guide-person.png">
			<img class="line" src="@/assets/images/guide/guide-cross-line.png">
			<step-card :value="stepValue" title="我的工作台" desc="点击可进入角色的后台。" @skip="handleSkip"
								 @next="handleNext" />
		</div>
	</div>
</template>

<script>
import stepCard from "@/components/guideView/stepCard.vue";

export default {
	name: "guideView",
	components: {
		stepCard,
	},
	data() {
		return {
			showGuide: false,
			stepValue: 1,
		};
	},
	methods: {
		open() {
			this.showGuide = true;
		},
		handleNext() {
			if (this.stepValue < 3) {
				this.stepValue++;
			} else {
				this.showGuide = false;
			}
		},
		handleSkip() {
			this.showGuide = false;
		},
	},
};
</script>

<style scoped>
.guide-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(15px);

    .step-one {
        position: absolute;
        top: 6px;
        right: 215px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .notice {
            width: 108px;
            height: 58px;
        }

        .line {
            width: 8px;
            height: 38px;
        }
    }

    .step-two {
        position: absolute;
        top: 6px;
        right: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .message {
            width: 108px;
            height: 58px;
        }

        .line {
            width: 8px;
            height: 38px;
        }
    }

    .step-three {
        position: absolute;
        top: 7px;
        right: 20px;
        display: flex;
        flex-direction: column;
        align-items: end;

        .avatar {
            width: 58px;
            height: 58px;
            border-radius: 50%;
            background-color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 36px;
                height: 36px;
            }
        }

        .line {
            position: absolute;
            top: 245px;
            left: -28px;
            width: 54px;
            height: 8px;
        }

        .person {
            width: 300px;
            height: 501px;
        }

        .handle-view {
            position: absolute;
            top: 202px;
            left: -350px;
        }
    }
}
</style>