< <template>
   <div class="btn-box">
      <div class="btns-main">
         <div class="direction-switching btn-item" v-if="videoList.length > 1">
            <p @click="arrowClick('up')" v-if="!makeMoney">
               <svg class="icon" aria-hidden="true">
                  <use :xlink:href="`#icon-sohu-shangyige`"></use>
               </svg>
            </p>
            <p @click="arrowClick('down')" v-if="!makeMoney">
               <svg class="icon" aria-hidden="true">
                  <use :xlink:href="`#icon-sohu-xiayige`"></use>
               </svg>
            </p>
         </div>
         <div class="btn-item">
            <div class="head-main">
               <img class="head" :src="videoInfo.userAvatar" alt="" @click="goMyDetail" />
               <svg class="icon add" aria-hidden="true" v-if="$util.handlefollowShow(videoInfo) && !videoInfo.followObj"
                  @click="handleOpt('follow')">
                  <use :xlink:href="`#icon-sohu-tianjia`"></use>
               </svg>
               <svg class="icon add" aria-hidden="true" v-if="$util.handlefollowShow(videoInfo) && videoInfo.followObj"
                  @click="handleOpt('follow')">
                  <use :xlink:href="`#icon-sohu-xuanzhonglianxiren`"></use>
               </svg>
            </div>
         </div>
         <div class="btn-item" @click="handleOpt('like', videoInfo, 'Video')">
            <i class="icon-sohu-xihuan-space" :class="{ likeActive: videoInfo.praiseObj }"></i>
            <p>{{ videoInfo.praiseCount }}</p>
         </div>
         <div class="btn-item" @click="handleOpt('comment')">
            <i class="icon-sohu-pinglun-space"></i>
            <p>{{ videoInfo.commentCount }}</p>
         </div>
         <div class="btn-item" @click="handleOpt('collect', videoInfo, 'Video')">
            <i class="icon-sohu-collect-space" :class="{ collectActive: videoInfo.collectObj }"></i>
            <p>{{ videoInfo.collectCount }}</p>
         </div>
         <div class="btn-item" @click="handleOpt('share')">
            <i class="icon-sohu-zhuanfa-space"></i>
            <!-- 转发数量还没做统计，先隐藏 -->
            <!-- <p>{{ videoInfo.forwardCount }}</p> -->
         </div>
         <div class="btn-item" @click="handleOpt('report')" v-if="isReportShow">
            <i class="icon-sohu-more-operations"></i>
         </div>
      </div>
      <reportBox type="Video" @handleOpt="handleOpt" :info="videoInfo" ref="reportBox"></reportBox>
   </div>
</template>
   <script>
   import reportBox from '@/components/reportBox.vue';
   import store from '@/store';
   export default {
      components: { reportBox },
      props: {
         videoInfo: {
            type: Object,
         },
         videoList: {
            type: Array,
            default: () => {
               return [];
            },
         },
         isShortPlay: {
            type: Boolean,
            default: () => {
               return false;
            },
         },
         isMy: {
            type: Boolean,
            default () {
               return false;
            },
         },
         makeMoney: {
            type: Boolean,
            default () {
               return false;
            },
         },
      },
      data () {
         return {
            disRadio: 'DislikeCategory',
            isShow: false,
            isPraise: true,
            isCollect: true,
         };
      },
      computed: {
         isReportShow () {
            let bool = null;
            if (this.isMy) {
               bool = false;
            } else {
               bool = this.isShortPlay ? false : true;
            }
            return bool;
         },
         isLogin () {
            return store.getters.isLogin;
         },
      },
      watch: {
         isLogin (val) {
            if (val) {
               this.$emit('getVideoList');
            }
         },
      },
      methods: {
         arrowClick (val) {
            this.$emit('arrowClick', val);
         },
         async handleOpt (type, obj, m) {
            this.$login().then(async (res) => {
               setTimeout(async () => {
                  switch (type) {
                     case 'follow':
                        const resFollow = await this.$http.follow({
                           focusUserId: this.videoInfo.userId,
                        });
                        if (resFollow.code === 200) {
                           if (resFollow.data) {
                              this.videoInfo.followObj = true;
                              this.$message.success('关注成功');
                           } else {
                              this.videoInfo.followObj = false;
                           }
                        }
                        this.$emit('handleOpt', type);
                        break;
                     case 'like':
                        this.isPraise && this.handlePraise(obj, m);
                        break;
                     case 'comment':
                        this.$emit('commentOpen');
                        break;
                     case 'collect':
                        this.isCollect && this.handleCollect(obj, m);
                        break;
                     case 'share':
                        let url = `${window.location.host}/videoShare?id=${this.videoInfo.id}`;
                        this.$copyText(url).then((e) => {
                           this.$message({
                              message: '链接复制成功，去分享好友吧',
                              type: 'success',
                           });
                        });
                        this.$emit('handleOpt', type);
                        break;
                     case 'disLikeSure':
                        this.$emit('handleOpt', type);
                        break;
                     case 'report':
                        this.$refs.reportBox.isShow = !this.$refs.reportBox.isShow;
                        break;
                     case 'reportSucess':
                        this.$emit('handleOpt', 'report');
                        break;
                  }
               }, 500);
            });
         },
         // 点赞/取消点赞
         async handlePraise (obj, type) {
            this.isPraise = false;
            const res = await this.$http.contentMainLike({
               busyCode: this.videoInfo.id,
               busyType: obj.episodeRelevance ? 'PlayletVideo' : type,
               authorUserId: obj.userId
            });
            if (res.code === 200) {
               if (res.data) {
                  this.videoInfo.praiseObj = true;
                  this.videoInfo.praiseCount = this.videoInfo.praiseCount + 1;
                  this.$message.success('点赞成功');
                  this.$emit('handleOpt', type);
               } else {
                  this.videoInfo.praiseObj = false;
                  this.videoInfo.praiseCount = this.videoInfo.praiseCount - 1;
               }
               this.isPraise = true;
            }
         },
         // 收藏/取消收藏
         async handleCollect (obj, type) {
            this.isCollect = false;
            let collectRes = await this.$http.collect({
               busyCode: this.videoInfo.id,
               busyType: obj.episodeRelevance ? 'PlayletVideo' : type,
               authorUserId: this.videoInfo.userId,
            });
            if (collectRes.code === 200) {
               if (collectRes.data) {
                  this.videoInfo.collectObj = true;
                  this.videoInfo.collectCount = this.videoInfo.collectCount + 1;
                  this.$message.success('收藏成功');
                  this.$emit('handleOpt', type);
               } else {
                  this.videoInfo.collectObj = false;
                  this.videoInfo.collectCount = this.videoInfo.collectCount - 1;
               }
               this.isCollect = true;
            }
         },
         // 查看别人视角我的详情
         goMyDetail () {
            // 如果是本人，跳转我的页面
            if (this.$store.state.userInfo.id == this.videoInfo.userId) {
               this.$login().then((res) => {
                  this.$router.push({
                     path: '/my',
                  });
               });
            } else {
               this.$router.push({
                  path: '/othersHome',
                  query: {
                     userId: this.videoInfo.userId,
                  },
               });
            }
         },
      },
   };
</script>
   <style lang="scss" scoped>
   .btn-box {
      position: absolute;
      bottom: 108px;
      right: 42px;
      z-index: 999;
   }

   .btns-main {
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: 60px;
      display: flex;
      justify-content: center;

      .btn-item {
         display: flex;
         flex-direction: column;
         align-items: center;

         i {
            font-size: 46px;
            color: #fff;
            cursor: pointer;
         }

         p {
            font-size: 15px;
         }
      }

      .direction-switching {
         p {
            font-size: 24px;
            cursor: pointer;

            &:first-child {
               margin-bottom: 20px;
            }
         }

         .icon {
            color: #545454;
            font-size: 40px;
         }
      }

      .head-main {
         position: relative;
         width: 40px;
         height: 40px;
         margin: 48px 0 20px;
         cursor: pointer;

         .head {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border: 2px solid #ffffff;
         }

         .add {
            font-size: 24px;
            position: absolute;
            bottom: -12px;
            left: calc(50% - 12px);
         }
      }
   }

   .likeActive {
      color: $like-color !important;
   }

   .collectActive {
      color: $collect-color !important;
   }
</style>
