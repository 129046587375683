<template>
	<div class="download-dialog" v-show="value" @click="$emit('input', false)">
		<div class="content" @click.stop="$emit('input', true)">
			<i
				class="iconfont icon-sohu-quxiao"
				@click.stop="$emit('input', false)"
			></i>
			<div class="title">该消息不支持PC端打开，可通过狐少少App查看</div>
			<div class="qr-code"></div>
			<div class="tip">扫一扫下载狐少少App</div>
		</div>
	</div>
</template>

<script>
import QRCode from 'qrcodejs2';
export default {
	name: 'download_dialog',
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.qrCodeInit();
	},
	methods: {
		qrCodeInit() {
			this.$nextTick(() => {
				try {
					const qrcodeDiv = this.$el.querySelector('.qr-code');
					if (!qrcodeDiv) {
						console.error('QR code container not found');
						return;
					}

					// 清空容器
					qrcodeDiv.innerHTML = '';

					// 生成二维码
					new QRCode(qrcodeDiv, {
						text: 'https://world.sohuglobal.com/#/contentPackages/downloadApp/downloadApp',
						width: 157,
						height: 157,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H,
					});
				} catch (error) {
					console.error('Error generating QR code:', error);
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.download-dialog {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.3);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	.content {
		width: 301px;
		height: 339px;
		background-color: #ffffff;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 40px 0;
		box-sizing: border-box;
		position: relative;
		.icon-sohu-quxiao {
			position: absolute;
			right: 17px;
			top: 14px;
			font-size: 12px;
		}
		.title {
			font-size: 16px;
			color: #3d3d3d;
			padding: 0 35px 0 36px;
			text-align: center;
			line-height: 24px;
		}
		.qr-code {
			width: 157px;
			height: 157px;
			background-color: #fff; // 确保背景色
			display: block; // 确保显示模式
		}
		.tip {
			font-size: 16px;
			color: #3d3d3d;
			line-height: 24px;
		}
	}
}
</style>
