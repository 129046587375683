<template>
	<!-- 商单结算 -->
	<dialogBox
		appendToBody
		v-model="dialogVisible"
		class="accountDialog"
		@confirm="confirmClick"
		title="结算"
		width="430px"
		:showBottomBtn="true"
		:showHeader="false"
	>
		<div class="text-#222222">
			<div class="text-center text-16px mt-24px font-500">申请结算确认</div>
			<div class="text-16px mt-24px">结算总金额：</div>
			<div class="text-center text-30px mt-24px font-500 text-#FF6C27">
				¥{{ detailData?.planVo?.standAmount }}
			</div>

			<div
				style="border-top: 1px solid #e7e7e7; border-bottom: 1px solid #e7e7e7"
				class="w-full py-16px flex items-center justify-between mt-25px"
			>
				<div class="text-14px lh-22px">交付人数：</div>
				<div class="text-#FF6C27 text-14px">
					{{ detailData?.planVo?.standNum }}人
				</div>
			</div>
			<div
				style="border-bottom: 1px solid #e7e7e7"
				class="w-full py-16px flex items-center justify-between"
			>
				<div class="text-14px lh-22px">结算单价：</div>
				<div class="text-#FF6C27 text-14px">
					¥{{
						formatAmount(
							detailData?.planVo?.standAmount / detailData?.planVo?.standNum,
						)
					}}
				</div>
			</div>

			<div class="text-center text-12px text-#999999 mt-20px">
				确认后不可以取消
			</div>
		</div>
	</dialogBox>
</template>

<script>
import dialogBox from '@/components/dialogBox.vue';
export default {
	components: { dialogBox },
	data() {
		return {
			dialogVisible: false,
			detailData: {},
			taskNumber: '',
		};
	},

	methods: {
		formatAmount(amount) {
			return amount ? amount.toFixed(2) : '0.00';
		},
		open(data) {
			console.log('data: ', data);
			this.detailData = data;
			let groupExtObj = JSON.parse(data.groupExt);
			this.taskNumber = groupExtObj.taskNumber;
			this.dialogVisible = true;
		},

		async confirmClick() {
			const res = await this.$http.settleApi({
				taskNumber: this.taskNumber,
			});

			if (res.code == 200) {
				this.dialogVisible = false;
				this.$message.success('结算成功');
				this.$emit('settleSuccess'); // 添加这行，触发结算成功事件
			}
		},
	},
};
</script>
