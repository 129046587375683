<template>
	<div class="friend-container">
		<div class="menu-view">
			<div class="search-view">
				<searchInput @handleToChatRoom="handleToChatRoom" />
			</div>
			<div class="menu-list">
				<div
					:class="['item', tabActive === item.type ? 'active' : '']"
					v-for="(item, index) in menuList"
					:key="item.type"
					@click="handleTabChange(item)"
				>
					<div class="icon-view">
						<div
							class="read-count"
							v-if="['newFriend'].includes(item.type) && item.count"
						>
							{{ item.count >= 99 ? '99+' : item.count }}
						</div>
						<img :src="item.icon" alt="" />
					</div>
					<div class="title">{{ item.title }}</div>
					<div class="count" v-if="!['newFriend'].includes(item.type)">
						{{ item.count }}
					</div>
				</div>
			</div>
		</div>
		<div class="main-view">
			<new-friend-view
				:list="newFriendList"
				v-if="['newFriend'].includes(tabActive)"
				@pageChange="handlePageChange"
				@updateList="getList"
				:total="pageData.total"
				@close="$emit('close')"
			/>
			<me-friend-view
				:list="meFriendList"
				v-if="['meFriend'].includes(tabActive)"
				@updateList="getList"
				@toChat="handleToChat"
				@close="$emit('close')"
			/>
			<me-group-view
				ref="meGroupRef"
				:list="meGroupList"
				v-if="['meGroup'].includes(tabActive)"
				@toChat="handleToChat"
				@close="$emit('close')"
			/>
			<label-view
				:list="labelList"
				:friendList="meFriendList"
				v-if="['label'].includes(tabActive)"
				@updateList="getList"
				@close="$emit('close')"
			/>
		</div>
	</div>
</template>

<script>
import SearchInput from '@/views/chat/components/chat/searchInput.vue';
import LabelView from '@/views/chat/components/friend/labelView.vue';
import MeGroupView from '@/views/chat/components/friend/meGroupView.vue';
import MeFriendView from '@/views/chat/components/friend/meFriendView.vue';
import NewFriendView from '@/views/chat/components/friend/newFriendView.vue';
import { MessageEnum } from '@/views/chat/enum/message';
import { CommandEnum } from '@/views/chat/enum/command';
import { isJsonString } from '@/utils/util';

export default {
	components: {
		SearchInput,
		LabelView,
		MeGroupView,
		MeFriendView,
		NewFriendView,
	},
	data() {
		return {
			menuList: [
				{
					icon: require('@/assets/images/chat/new-friend-icon.png'),
					title: '新的朋友',
					type: 'newFriend',
					count: 0,
				},
				{
					icon: require('@/assets/images/chat/me-friend-icon.png'),
					title: '我的好友',
					type: 'meFriend',
					count: 0,
				},
				{
					icon: require('@/assets/images/chat/group-icon.png'),
					title: '我的群组',
					type: 'meGroup',
					count: 0,
				},
				{
					icon: require('@/assets/images/chat/label-icon.png'),
					title: '标签',
					type: 'label',
					count: 0,
				},
			],
			tabActive: 'meFriend',
			pageData: {
				pageSize: 10,
				pageNum: 1,
				total: 0,
			},
			newFriendList: [],
			meFriendList: [],
			meGroupList: [],
			labelList: [],
		};
	},
	computed: {
		isLogin() {
			return this.$store.getters.isLogin;
		},
		message() {
			return this.$store.state.message;
		},
	},
	watch: {
		message: {
			handler(val) {
				if (val == 'http_pong') return;
				const messageData = isJsonString(val) ? JSON.parse(val) : null;
				if (!messageData) return;
				const isCommand = [MessageEnum.command].includes(
					messageData.messageType,
				);
				const isGroupAddFriend =
					[CommandEnum.groupAddFriend].includes(messageData.commandType) &&
					this.tabActive === 'meGroup';
				//需要刷新列表的命令处理
				const isRefreshList = [
					CommandEnum.addFriendApply,
					CommandEnum.groupGreet,
					CommandEnum.ungroup,
					CommandEnum.createSubgroup,
					CommandEnum.subgroupDissolve,
					CommandEnum.createGroup,
					CommandEnum.quitGroup,
					CommandEnum.kickGroup,
					CommandEnum.joinGroup,
					CommandEnum.addFriendPass,
				];
				if (isCommand) {
					if (isRefreshList.includes(messageData.commandType)) {
						this.getList();
					} else if (isGroupAddFriend) {
						//若为命令消息 并且 设置群成员是否可相互添加好友 刷新群详情
						this.$refs.meGroupRef.getGroupDetail();
					}
				}
			},
			deep: true,
		},
		isLogin: {
			handler(val) {
				if (val) this.getList();
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		/**
		 * 操作分页加载
		 */
		handlePageChange() {
			this.pageData.pageNum++;
			this.getList(false);
		},
		/**
		 * 获取外层总数
		 * @returns {Promise<void>}
		 */
		async getCount() {
			const res = await this.$http.getFriendNumber();
			if (res.code === 200) {
				this.menuList.forEach((v, i) => {
					switch (v.type) {
						case 'newFriend':
							this.$set(this.menuList, i, {
								...v,
								count: res.data.approvalFriendCount,
							});
							this.$emit('updateRead', res.data.approvalFriendCount);
							break;
						case 'meFriend':
							this.$set(this.menuList, i, {
								...v,
								count: res.data.friendCount + 1,
							});
							break;
						case 'meGroup':
							this.$set(this.menuList, i, { ...v, count: res.data.groupCount });
							break;
						case 'label':
							this.$set(this.menuList, i, { ...v, count: res.data.tagCount });
							break;
					}
				});
			}
		},
		/**
		 * 前往聊天
		 * @param model
		 */
		handleToChat(model) {
			this.$emit('toChat', model);
		},
		handleToChatRoom(model) {
			this.$emit('toChat', model);
		},
		/**
		 * 清空数据
		 */
		clearData() {
			this.pageData = {
				pageSize: 10,
				pageNum: 1,
				total: 0,
			};
			this.newFriendList.splice(0);
			this.meFriendList.splice(0);
			this.meGroupList.splice(0);
			this.labelList.splice(0);
		},
		/**
		 * tab功能切换
		 * @param model
		 */
		handleTabChange(model) {
			if (this.tabActive === model.type) return;
			this.tabActive = model.type;
			this.clearData();
			this.getList();
		},
		/**
		 * 操作获取列表
		 * @returns {Promise<void>}
		 */
		async getList(flag = true) {
			if (flag) this.clearData();
			switch (this.tabActive) {
				case 'newFriend':
					await this.getNewFriendList();
					break;
				case 'meFriend':
					await this.getMeFriendList();
					break;
				case 'meGroup':
					await this.getMeGroupList();
					break;
				case 'label':
					await this.getLabelList();
					await this.getMeFriendList();
					break;
			}
			await this.getCount();
		},
		/**
		 * 获取标签列表
		 * @returns {Promise<void>}
		 */
		async getLabelList() {
			const res = await this.$http.appUserTagList();
			if (res.code === 200) {
				this.labelList.push(...res.data);
			}
		},
		/**
		 * 获取新的好友列表
		 * @returns {Promise<void>}
		 */
		async getNewFriendList() {
			const res = await this.$http.appFriendsList({
				pageSize: this.pageData.pageSize,
				pageNum: this.pageData.pageNum,
			});
			if (res.code === 200) {
				this.newFriendList.push(...res.data.filter((v) => v.id));
				this.pageData.total = res.total;
			}
		},
		/**
		 * 获取我的好友列表
		 * @returns {Promise<void>}
		 */
		async getMeFriendList() {
			const res = await this.$http.appFriendBook();
			if (res.code === 200) {
				this.meFriendList = res.data
					.filter((v) => v.friendId)
					.map((v) => ({
						...v,
						displayName: v.alias || v.nickName || v.userName,
					}));
				console.log(this.meFriendList, 'this.meFriendList');
			}
		},
		/**
		 * 获取我的群组列表
		 * @returns {Promise<void>}
		 */
		async getMeGroupList() {
			const res = await this.$http.imGroupJoinList();
			if (res.code === 200) {
				this.meGroupList = res.data.filter((v) => v.id);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.friend-container {
	width: calc(100% - 60px);
	height: 100%;
	border-radius: 40px;
	background-color: #fff;
	box-sizing: border-box;
	display: flex;
	overflow: hidden;

	.menu-view {
		width: 260px;
		height: 100%;
		border-right: 1px solid #e7e7e7;

		.search-view {
			width: 100%;
			height: 79px;
			padding: 0 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			border-bottom: 1px solid #e7e7e7;
		}

		.menu-list {
			padding: 20px 10px;
			box-sizing: border-box;
			.item {
				width: 100%;
				height: 74px;
				display: flex;
				align-items: center;
				padding: 0 21px 0 11px;
				box-sizing: border-box;
				cursor: pointer;
				border-radius: 8px;

				&.active {
					background-color: #f3f3f3;
				}

				.icon-view {
					position: relative;

					img {
						width: 44px;
						height: 44px;
						margin-right: 20px;
					}

					.read-count {
						width: 20px;
						height: 20px;
						background-color: #f94040;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 10px;
						border-radius: 50%;
						color: #fff;
						position: absolute;
						top: -7px;
						right: 10px;
						box-sizing: border-box;
					}
				}

				.title {
					flex: 1;
					font-size: 16px;
					color: #222;
				}

				.count {
					font-size: 16px;
					color: #999999;
				}
			}
		}
	}

	.main-view {
		flex: 1;
	}
}
</style>
