<template>
	<div class="base-view" v-loading="loading">
		<header class="header">
			<div class="title">{{ title }}</div>
			<div class="close-btn" @click="$emit('close')">
				<i class="icon-sohu-guanbidanchuang iconfont"></i>
			</div>
		</header>
		<main class="main" @scroll="handleScroll($event)">
			<slot />
		</main>
	</div>
</template>

<script>
export default {
	name: 'mainBaseView',
	props: {
		title: {
			type: String,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			// 保存上一次滚动的位置
			lastScrollTop: 0,
		};
	},
	methods: {
		/**
		 * 滚动事件
		 * @param event
		 */
		handleScroll(event) {
			// 获取滚动容器
			const scrollContainer = event.target;
			// 计算滚动容器的底部距离
			const scrollBottom =
				scrollContainer.scrollHeight - scrollContainer.clientHeight;
			// 当前滚动位置
			const currentScrollTop = scrollContainer.scrollTop;
			console.log(currentScrollTop + 1 >= scrollBottom && !this.loading);
			// 判断是否滚动到底部
			if (currentScrollTop + 1 >= scrollBottom && !this.loading) {
				// 加1是为了留出一点空间，避免最后一次滚动不触发
				this.$emit('scrollBottom');
			}

			// 更新上一次滚动的位置
			this.lastScrollTop = currentScrollTop;
		},
	},
};
</script>

<style lang="scss" scoped>
.base-view {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.header {
		width: 100%;
		height: 79px;
		border-bottom: 1px solid #e7e7e7;
		padding: 0 24px 0 30px;
		box-sizing: border-box;
		color: #222;
		font-size: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title {
			line-height: 79px;
		}

		.close-btn {
			width: 40px;
			height: 40px;
			text-align: center;
			margin-left: 10px;
			cursor: pointer;

			i {
				font-size: 28px;
				line-height: 40px;
			}

			&:hover {
				background: #f3f3f3;
			}
		}
	}

	.main {
		flex: 1;
		overflow-y: auto;
		background-color: #fff;
	}
}
</style>
