<template>
	<dialogBox appendToBody :showHeader="false" v-model="dialogVisible">
		<div class="businessCardDialogBoxContent">
			<div class="userData">
				<el-avatar :src="details.userAvatar"></el-avatar>
				<div class="userInfoBox">
					<div class="userName">{{ details.alias || details.nickName }}</div>
					<div>昵称：{{ details.nickName }}</div>
					<div>ID：{{ details.userName }}</div>
				</div>
			</div>
			<div>
				<div class="rowBox" @click="setRemarksClick" v-if="details.meFriend">
					<span> 备注和标签 </span>
				</div>
				<div class="rowBox" v-if="!isMe">
					<span @click="othersHOme">个人空间</span>
				</div>
			</div>

			<div class="bottom_btn">
				<button @click="dialogVisible = false">取消</button>
				<button @click="handleConfirm">{{ handleText }}</button>
			</div>
		</div>

		<!-- 申请添加好友弹窗 -->
		<ApplicationDialog ref="ApplicationDialogRef"></ApplicationDialog>

		<setRemarksDialog ref="setRemarksDialogRef" @update="handleRemarkUpdate"></setRemarksDialog>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import setRemarksDialog from './setRemarksDialog.vue';
import ApplicationDialog from "@/views/chat/components/user/applicationDialog.vue";
export default {
	components: { ApplicationDialog, dialogBox, setRemarksDialog },
	data() {
		return {
			dialogVisible: false,
			details: {},
			isSend: false,
			friendId: "",
		};
	},
	provide() {
		return {
			getAppFriends: this.getAppFriendsDetails,
		};
	},
	computed:{
		handleText() {
			if (this.isSend) {
				return '发消息'
			} else {
				return this.details.meFriend ? '确认' : '添加好友'
			}
		},
		isMe(){
			return this.details.friendId == this.$store.state.userInfo.id
		},
	},
	mounted() {},
	methods: {
		handleRemarkUpdate() {
			this.$emit("update");
			this.getAppFriendsDetails(this.friendId);
		},
		handleConfirm() {
			if (this.isSend) {
				this.dialogVisible = false;
				this.$emit('toChat', { ...this.details });
				return;
			}
			if(!this.details.meFriend){
				this.$refs.ApplicationDialogRef.open(this.details.friendId);
			}else if(this.details){
				this.dialogVisible = false
			}else if(!this.details){
				this.$message.error('无权限添加该用户')
			}
		},

		open(id,isSend = false) {
			this.dialogVisible = true;
			this.isSend = isSend;
			this.friendId = id;
			this.getAppFriendsDetails(id);
		},

		// 好友详情
		async getAppFriendsDetails(id) {
			const res = await this.$http.appFriendsDetails(id);
			console.log(res);
			this.details = res.data;
			console.log('详情啊')
		},

		// 设置备注
		setRemarksClick() {
			console.log(this.details);
			this.$refs.setRemarksDialogRef.open(this.details.friendId);
		},

		// 他的空间
		othersHOme() {
			let link = this.$router.resolve({
				path: '/othersHome',
				query: {
					userId: this.details.friendId,
				},
			});
			window.open(link.href, '_blank');
		},
	},
};
</script>
<style lang="scss">
.businessCardDialogBoxContent {
	.userData {
		display: flex;
		margin-bottom: 16px;
		.el-avatar {
			width: 60px;
			height: 60px;
			margin-right: 16px;
			border-radius: 8px;
		}
		.userInfoBox {
			color: #999999;
			font-size: 14px;
			line-height: 22px;
			.userName {
				color: #3d3d3d;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
			}
		}
	}
	.rowBox {
		width: 100%;
		height: 54px;
		border-top: 1px solid #e7e7e7;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		&:last-child {
			border-bottom: 1px solid #e7e7e7;
		}
		span {
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.bottom_btn {
		margin-top: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		button {
			height: 40px;
			width: 100px;
			background: #f3f3f3;
			font-size: 16px;
			font-weight: 500;
			cursor: pointer;
			border-radius: 4px;
			color: #3d3d3d;
			&:hover {
				opacity: 0.8;
			}
			& + button {
				margin-left: 16px;
				background: $theme-color;
				color: #ffffff;
			}
		}
	}
}
</style>
