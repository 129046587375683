<template>
    <DialogBox
        confirmText="保存"
        showBottomBtn
        appendToBody
        v-model="dialogVisible"
        class="Group-Ban-Time"
        @confirm="addClick"
    >
        <div slot="header_title">选择时间段</div>
        <div class="setRow">
            <div class="setRow_title">禁言时间</div>
			<p>按时间段禁言 开启后，该时间段仅群主和群管理员允许发言</p> 
		</div>

        <div class="setRow">
            <div class="setRow_title_b">每天</div>
			
            <div class="setRow_tick" v-for="item,index in times" :key="index">
                <el-time-picker
                    is-range
                    class="setRow_tick_time"
                    v-model="times[index]"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    format="HH:mm"
                    :picker-options="{
                        format:'HH:mm'
                    }"
                    placeholder="选择时间范围"
                    @change="activeTimes"
                    >
                </el-time-picker>
            </div> 
            <common-button :disabled="times.length>=5" v-show="times.length<5" type="theme" @click="addTimeS">
                <i class="el-icon-plus"></i>
                添加时间段</common-button>

		</div>
        <div class="rowLine"></div>

    </DialogBox>
</template>

<script>
import { dateFormat,timeStringToDate } from '@/utils/util.js';
import DialogBox from '@/components/dialogBox.vue';
import CommonButton from "@/views/chat/components/common/commonButton.vue";

export default {
    components:{
        DialogBox,
        CommonButton
    },
    provide() {
		return {
			groupAdminList: this.open,
		};
	},
    data(){
        return{
            dialogVisible:false,
            times:[], //时间段
            disabled:false,
            optionsTem:{
                start: '00:01',
                step: '00:01',
                end: '23:59',
            },
            timeTem:[], //时间模板
            groupDetails:{}, //IM详情
        }
    },
    watch:{
        times(val){
            val.map((item,index)=>{
                if(!item) val.splice(index,1)
            })
            if(val.length == 5){
                this.disabled = true
            }else{
                this.disabled = false
            }
        }
    },
    mounted(){
        
    },
    methods:{
        //时间排序 判断时间是否重叠
         checkOverlap(intervals) {
            let timeArr = intervals
            timeArr.sort((a, b) => a[0] - b[0]);
            for (let i = 0; i < timeArr.length - 1; i++) {
                if (new Date(timeArr[i][1]) > new Date(timeArr[i + 1][0])) {
                    return true; // 重叠
                }
            }
            return false; // 不重叠
        },
        async open(details){
            this.times = []
            this.dialogVisible = true
            const year = new Date().getFullYear()
            const month = new Date().getMonth()
            const days = new Date().getDate()
            //时间选择器模板
            this.timeTem = [new Date(year,month,days,0,0),new Date(year,month,days,23,59)]
            if(!this.times.length) this.times.push(this.timeTem)
            this.groupDetails = details
            if(this.groupDetails.timeList.length){
                this.times = []
                this.groupDetails.timeList.map((item,index)=>{
                    //获取每个时间段
                    this.times.push([timeStringToDate(item.startTime),timeStringToDate(item.endTime)])
                })
            }
        },
        async addClick(close){
            let newTimes = []
            let isTime = false
            let bool
            console.log('提交之前..',this.times)
            this.times.map((item,index)=>{
                if(!item){
                    this.times.splice(index,1)
                }else{
                    isTime = true
                }
            })
            if(isTime){
                bool = this.checkOverlap(this.times)
            } 
            this.times.map((item)=>{
                if(item && item[0] && item[1]) newTimes.push({'startTime' : dateFormat(item[0],'HH:mm'),'endTime' : dateFormat(item[1],'HH:mm')})
            }) 
            if(bool){
                this.$message.info('禁言时间已存在请修改后保存') 
            }else{
                console.log('提交设置',this.times,newTimes)  
                let res = await this.$http.imGroupForbidTimeList({
                    groupId : this.groupDetails.id,
                    timeList : newTimes
                })
                if(res.code == 200){
                    this.$emit('setTimeList',newTimes)
                    this.$message.success('保存设置成功')
                    this.dialogVisible = false 
                }      
            }     
            close(true)     
        },
        //添加时间段按钮
        addTimeS(){
            if(this.times.length <= 4){
                this.times.push(this.timeTem)
                if(this.times.length == 5) this.disabled = true
            }else{
                this.disabled = true
            }
        },
        //选择时间事件
        activeTimes(event){
            if(event){
                if(this.times.length <= 5){     
                    if(this.times.length == 5){
                        this.disabled = true
                    }else{
                        // this.times.push(this.timeTem)
                    } 
                }else{
                    this.disabled = true
                }
            }      
        },
    }
}
</script>
<style lang="scss">
.Group-Ban-Time .el-range-editor.el-input__inner{
    width: 436px;
    height: 36px;
    border-radius: 4px;
    background: #F7F7F7;
    border: 0;
    margin: 5px 0;
}
.el-range-editor .el-range-input{
    background: #F7F7F7;
}
 .el-time-spinner__item.active{
    color: #FF6C27!important;
    font-weight: bold!important;
}
.el-time-spinner__item.active.disabled{
    color: #C0C4CC!important;
}
</style>
<style lang="scss" scoped>

.Group-Ban-Time{
    .common-button.theme{
        background: rgba(255, 108, 39, 0.1);
        font-size: 14px;
        font-weight: normal;
        color: #FF6C27;
        border: 0;
        .el-icon-plus{
            margin-right: 3px;
        }
    }
    .rowLine {
        margin: 16px 0;
        height: 1px;
        background-color: #e7e7e7;
    }
    .setRow{
        margin-top: 9px;     
        p{
            color: #999999;
            font-size: 12px;
            margin: 2px 0;
        }
        .setRow_title {
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
        }
        .setRow_title_b{
            font-size: 16px;
            color: #3D3D3D;
            font-weight: 500;
            padding: 4px 0;
        }
        .setRow_item{
            width: 430px;
            height: 40px;
            // border-radius: 10px;
            // border: 1px solid #ccc;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 20px;
            .setRow_text_input{
                width: 80px;
                height: 30px;
                border-radius: 5px;
                .el-input{
                    width: 100%;
                    height: 100%;
                    .el-input__inner{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .setRow_text{
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

</style>