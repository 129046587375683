<template>
	<div
		v-show="dialogVisible"
		class="content-menu"
		:style="{ left: fixLeft, top: fixTop }"
	>
	<!-- :style="{ right: right, top: top }" -->
		<!-- !isSender ? '456px' : '45px' -->
		<div
			v-for="(item, index) in operateList"
			:key="index.icon"
			@click="getOperate(item, index)"
			class="operate"
		>
			<div :class="[{ on: index === activeIndex }, 'item']">
				<div>
					<i :class="item.icon" :style="{'fontSize': '16px'}" v-if="item.icon !== 'icon-sohu-shanchu2'"></i>
					<i class="icon-sohu-shanchu2" v-if="item.name == '删除'"></i>
				</div>

				<div class="name">{{ item.name }}</div>
			</div>
		</div>

		<el-dialog
			width="380px"
			:visible.sync="delteDialogVisible"
			:append-to-body="true"
		>
			<div class="delteDialog">
				<p class="del">删除消息</p>
				<p class="title">是否确定删除该条消息？</p>
				<div class="btn">
					<div>取消</div>
					<div @click="handleDeleteOperate" class="comfirm">确定</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { sendWSPush } from "@/utils/websocket";
export default {
	name: 'operateDialog',
	props:['userJoinGroups'],
	data() {
		return {
			dialogVisible: false,
			message: null,
			operateList: [
				{ name: '复制', icon: 'icon-sohu-fuzhi' },
				{ name: '转发', icon: 'icon-sohu-zhuanfa1' },
				{ name: '删除', icon: 'icon-sohu-shanchu2' },
				{ name: '撤回', icon: 'icon-sohu-chehui' },
			],
			activeIndex: '',
			localId: '',
			isSender: false,
			delteDialogVisible: false,
			isMoreThan24HoursAgo: false,
			isMoreThan2MinutesAgo: false,
			currentTime: '',
			createTime: '',
			fixLeft:'',
			fixTop:''
		};
	},
	computed: {
		itMe() {
			return this.message.sender.id == this.$store.state.userInfo.id;
		},
		roleName() {
			switch (this.message.sender.role) {
				case 'group_leader':
					return '群主';
				case 'group_admin':
					return '管理员';
			}
		},
		right() {
			if (this.message?.messageType === 'file') {
				if (!this.isSender) {
					// return '300px';
					return '300px'
				} else {
					// return '93px';
					return '93px'
				}
			} else {
				if (!this.isSender) {
					// return '456px';
					return '456px'
				} else {
					// return '60px';
					return '60px';
				}
			}
		},
		top() {
			if (this.message?.messageType === 'file') {
				if (!this.isSender) {
					return '';
				} else {
					// return '75px';
					return '75px';
				}
			} else {
				return '';
			}
		},
	},
	methods: {
		show(visible, message, role ,x, y) {
			// console.log('message', message,x, y,this.$rem(x),this.$rem(y));
			this.fixLeft = x
			this.fixTop = y
			this.dialogVisible = visible;
			this.message = message;
			this.isSender = this.message.sender.id === this.$store.state.userInfo.id;
			this.currentTime = this.$moment();
			this.createTime = this.$moment(this.message.createTime);
			this.isMoreThan24HoursAgo =
				this.currentTime.diff(this.createTime, 'hours') > 24;
			this.isMoreThan2MinutesAgo =
				this.currentTime.diff(this.createTime, 'minutes') > 2;

			if (this.message.messageType === 'file') {
				this.operateList = this.operateList.filter(
					(item) => item.name !== '复制',
				);
			}
			if (this.message.sessionType === 'group') {
				//群聊分为管理员、群主和普通群成员这两种情况
				if (!role && !this.isSender) {
					console.log(1);
					return (this.operateList = this.operateList.filter(
						(item) => item.name !== '撤回',
					));
				} else if (!role && this.isSender && this.isMoreThan2MinutesAg) {
					console.log(2);
					return (this.operateList = this.operateList.filter(
						(item) => item.name !== '撤回',
					));
				} else if (role && this.isMoreThan24HoursAgo) {
					console.log(3);
					return (this.operateList = this.operateList.filter(
						(item) => item.name !== '撤回',
					));
				}
			} else {
				if (!this.isSender) {
					return (this.operateList = this.operateList.filter(
						(item) => item.name !== '撤回',
					));
				} else if (this.isSender && this.isMoreThan2MinutesAgo) {
					return (this.operateList = this.operateList.filter(
						(item) => item.name !== '撤回',
					));
				}
			}
			// if (
			// 	['group_leader', 'group_admin'].includes(this.message.sender.role) &&
			// 	this.isMoreThan24HoursAgo
			// ) {
			// 	this.operateList = this.operateList.filter(
			// 		(item) => item.name !== '撤回',
			// 	);
			// }

			// //如果不是群主的情况下不是自己发的消息不能撤回
			// if (!role && !this.isSender) {
			// 	this.operateList = this.operateList.filter(
			// 		(item) => item.name !== '撤回',
			// 	);
			// }
			// //普通群成员超过两分钟不能撤回自己的账号
			// if (!role && this.isSender && this.isMoreThan2MinutesAgo) {
			// 	this.operateList = this.operateList.filter(
			// 		(item) => item.name !== '撤回',
			// 	);
			// }
			// if (
			// 	this.message.sessionType === 'single' &&
			// 	!this.isSender &&
			// 	this.message.messageType === 'file'
			// ) {
			// 	this.operateList = this.operateList.filter(
			// 		(item) => item.name !== '撤回',
			// 	);
			// }
			// if (this.message.sessionType === 'single' && !this.isSender) {
			// 	this.operateList = this.operateList.filter(
			// 		(item) => item.name !== '撤回',
			// 	);
			// }
			
		},

		close() {
			this.activeIndex = '';
			this.dialogVisible = false;
			this.isLeft = false;
		},

		//操作项
		getOperate(item, index) {
			this.activeIndex = index;
			switch (item.name) {
				case '复制':
					// this.$copyText(this.message.body.content).then((e) => {
					// 	this.$message({
					// 		message: '复制成功',
					// 		type: 'success',
					// 	});
					// });
					this.copyTextToClipboard(this.message.body.content,()=>{
						this.$message({
							message: '复制成功',
							type: 'success',
						});
					})
					break;

				case '删除':
					this.delteDialogVisible = true;
					break;

				case '撤回':
					this.recallMessage();
					break;
				case '转发':
					this.$emit('forwardOperate', this.message);
					break;
			}

			this.close();
		},

		//删除消息
		handleDeleteOperate() {
			this.localId = this.$util.getUuid();

			sendWSPush({
				localId: this.message.localId,
				receiverId: this.message.receiver.id,
				sessionType: this.message.sessionType,
				commandType: 'deleteChatMessage',
				messageType: 'command',
				chatId: this.message.chatId,
				content: this.message.body.content,
			});
			this.$emit('deleteOperate', this.message.id);

			this.delteDialogVisible = false;
		},

		//撤回消息
		recallMessage() {
			let time = '';

			// if (
			// 	['group_leader', 'group_admin'].includes(this.message.sender.role) &&
			// 	this.isMoreThan24HoursAgo
			// ) {
			// 	return this.$message({
			// 		message: '撤回失败，超过24小时不可撤回',
			// 		type: 'warning',
			// 	});
			// }

			// if (
			// 	!['group_leader', 'group_admin'].includes(this.message.sender.role) &&
			// 	this.isMoreThan2MinutesAgo
			// ) {
			// 	return this.$message({
			// 		message: '撤回失败，超过两分钟不可撤回',
			// 		type: 'warning',
			// 	});
			// }

			// this.$emit('changeRecall', this.message.localId);
		},
		/**
		 * 复制文本
		 * @param text
		 */
		 copyTextToClipboard(text, success) {
			var aux = document.createElement('input');
			aux.setAttribute('value', text);
			document.body.appendChild(aux);
			aux.select();
			document.execCommand('copy');
			document.body.removeChild(aux);
			success();
		},
	},
};
</script>

<style lang="scss" scoped>
.content-menu {
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
	position: fixed;
	border-radius: 4px;
	width: 100px;
	background: #ffffff;
	z-index: 1000;
	// top: 100%;
	padding: 16px 6px 10px 6px;

	cursor: pointer;
  .icon-sohu-fuzhi,.icon-sohu-zhuanfa1,.icon-sohu-shanchu2,.icon-sohu-chehui{
    font-size: 16px!important;
  }
	.item {
		width: 100%;
		height: 28px;
		text-align: center;
		line-height: 28px;
		// margin-left: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
    div{
      display: flex;
      justify-content: center;
      align-items: center;
    }
		.name {
			font-size: 12px;
			line-height: 22px;
			margin-left: 16px;
			// margin-bottom: 10px;
		}
		&.on {
			background: #f3f3f3;
			border-radius: 4px;
		}
	}
}
.delteDialog {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	.del {
		font-size: 18px;
		line-height: 36px;
		color: #3d3d3d;
		font-weight: 500;
	}
	.title {
		font-size: 14px;
		line-height: 36px;
		color: #333333;
		margin-bottom: 29px;
	}
	.btn {
		display: flex;
		div {
			width: 100px;
			height: 40px;
			background: #f3f3f3;
			border-radius: 4px;
			line-height: 40px;
			text-align: center;
			margin-right: 16px;
			cursor: pointer;
		}
		.comfirm {
			background: #ff6c27;
			color: #ffffff;
		}
	}
}
</style>