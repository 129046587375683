<template>
	<select-member ref="selectMemberRef" title="发送给" :list="list" :limit="limit" @confirm="handleForwardSend">
		<template #content-info>
			<div class="forward-content">
				<div class="content">{{ messageTypeFilter() }}</div>
			</div>
		</template>
	</select-member>
</template>
<script>
import { sendWSPush } from "@/utils/websocket";
import { timeStringToDate } from '@/utils/util.js';
import SelectMember from "@/views/chat/components/common/selectMember.vue";
import { shareFilter } from "@/views/chat/enum/share";
export default {
	components: {SelectMember},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		//不传则不限制转发数量
		limit: {
			type: Number,
			default: 0
		},
		userJoinGroups: { //用户加入的群聊
			type: Array,
			default: () => [],
		}
	},
	data() {
		return {
			forwardContent: {},
			forwardType: "message",
		};
	},
	computed: {
		userInfo() {
			return this.$store.state.userInfo;
		},
	},
	methods: {
		/**
		 * 操作消息类型文本展示
		 * @returns {string}
		 */
		messageTypeFilter() {
			switch (this.forwardContent.messageType) {
				case "text":
					return this.forwardContent.body.content;
				case "photo":
					return "[图片]";
				case "video":
					return "[视频]";
				case "share":
					return shareFilter(this.forwardContent.share.type);
				case "file":
					// return "[文件]";
					return "[文件]" + this.forwardContent.file?.fileName;
			}
		},
		shareTypeFilter() {

		},
		/**
		 * 打开弹窗
		 * @param message
		 * @param type
		 */
		open(message, type = "message") {
			this.forwardType = type;
			this.$refs.selectMemberRef.open();
			if (type === 'message') {
				this.forwardContent = message;
			} else {
				this.forwardContent = message;
			}
		},
		/**
		 * 处理消息发送数据
		 * @param model 对方信息
		 * @returns {Object}
		 */
		handleSendParams(model) {
			let sendParams = {};
			console.log(this.forwardType, "forwardType");
			switch (this.forwardType) {
				case "message":
					sendParams = {...this.handleMessageSendParams(model)}
					break;
				case "newMessage":
					sendParams = {
						localId: this.$util.getUuid(),
						receiverId: model.receiver.id,
						sessionType: model.sessionType,
						...this.forwardContent,
					};
					break;
			}
			return sendParams
		},
		/**
		 * 处理转发消息数据类型
		 * @param model 对方信息
		 * @returns {Object}
		 */
		handleMessageSendParams(model) {
			const sendParams = {
				content: this.forwardContent.body.content,
				localId: this.$util.getUuid(),
				messageType: this.forwardContent.messageType,
				sessionType: model.sessionType,
				receiverId: model.receiver.id,
			};
			switch (this.forwardContent.messageType) {
				case "text":
					break;
				case "file":
					sendParams.file = this.forwardContent.file;
					break;
				case "share":
					sendParams.shareType = this.forwardContent.share.type; //分享类型
					sendParams.shareId = this.forwardContent.share.id; //分享id
					sendParams.share = this.forwardContent.share;
					break;
			}
			return sendParams;
		},
		/**
		 * 操作转发发送消息
		 */
		handleForwardSend(list) {		
			let isSendError = 0
			this.userJoinGroups.map((item)=>{ //查询相同的id 判断是否被禁
				list.map((items)=>{
					if(items.receiver.id == item.id && items.sessionType == item.groupType){
						let isNoLeaderAdmin = (item.groupUserPermissionType != 'group_leader' || item.groupUserPermissionType != 'group_admin')
						console.log('相同得转发项',item.timeList,item.forbidTime)
						if(item.forbid && isNoLeaderAdmin){
							isSendError += 1
						}  
						if(item.forbidTime && isNoLeaderAdmin && this.isforbidTime(item.timeList)){
							isSendError += 1
						} 
						
					}
				})
			})

			list.forEach(item => {
				sendWSPush({
					...this.handleSendParams(item),
				});
				this.dialogVisible = false;
			});
			console.log('转发得被禁用次数',isSendError)
			if(!isSendError || isSendError < list.length) this.$message.success("发送完成")
      		if(isSendError == list.length) this.$message.error("发送失败")
			// this.$message.success("发送完成");
			this.$refs.selectMemberRef.close();
		},
		//判断是否在禁言时间段内
		isforbidTime(timeList){
			if(!timeList) return false
			if(timeList.length == 0) return false
			let timeNum = 0
			timeList.map((item) => {
				if (new Date() >= timeStringToDate(item["startTime"]) && new Date() <= timeStringToDate(item["endTime"])) {
					timeNum = 0
				} else {
					timeNum += 1;
				}
			})
			if(timeList && timeList.length == timeNum){
				return false
			}else if(timeList && timeNum < timeList.length ){
				return true
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.forward-content {
	width: 100%;
	height: 78px;
	border-top: 1px solid #E7E7E7;
	margin-top: 5px;

	.content {
		font-size: 14px;
		color: #999;
		line-height: 22px;
		margin-top: 10px;
	}
}
</style>