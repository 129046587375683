<template>
	<div class="handle-view">
		<img class="bgc" src="@/assets/images/guide/guide-bg.png">
		<div class="content">
			<div class="header">
				<div class="title">{{ title }}</div>
				<div class="skip" @click="$emit('skip')" v-if="value < 3">跳过</div>
			</div>
			<div class="desc">
				{{ desc }}
			</div>
			<div class="footer">
				<div class="step-count">(<span>{{ value }}</span>/3)</div>
				<div class="next" @click="$emit('next')">{{ value < 3 ? '下一步' : '知道啦' }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "stepCard",
	props: {
		value: {
			type: [Number,String],
			default: 1
		},
		title: {
			type: String,
			default: ""
		},
		desc: {
			type: String,
			default: ""
		}
	}
};
</script>

<style lang="scss" scoped>
.handle-view {
    position: relative;
    width: 329px;
    height: 86px;
    border-radius: 8px;
    background-color: #FFF;
    .bgc {
        position: absolute;
        bottom: 0;
        left: -3px;
        width: 120px;
        height: 108px;
    }
    .content {
        width: 100%;
        height: 100%;
        padding-left: 120px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .header {
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
            margin-top: 9px;
            line-height: 20px;
            .title {
                color: #FF6C27;
                font-size: 14px;
            }
            .skip {
                color: #D1D4D6;
                font-size: 12px;
                cursor: pointer;
            }
        }
        .desc {
            color: #3D3D3D;
            font-size: 12px;
            line-height: 20px;
            margin-top: 2px;
        }
        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            margin-top: 5px;
            .step-count {
                font-size: 12px;
                color: #989494;
                span {
                    color: #FF6C27;
                }
            }
            .next {
                width: 50px;
                height: 21px;
                background-color: #FF6C27;
                border-radius: 4px;
                font-size: 10px;
                text-align: center;
                line-height: 21px;
                color: #FFF;
                cursor: pointer;
            }
        }
    }
}
</style>