<template>
    <main-base-view title="新的朋友" :loading="loading" @scrollBottom="handleScrollBottom" @close="$emit('close')">
        <div class="list-view" v-if="list.length">
            <div class="item" v-for="(item,index) in list" :key="item.id">
                <div class="avatar-view">
                    <el-image class="avatar" :src="item.userAvatar"></el-image>
                    <div class="point" v-if="!item.readState"></div>
                </div>
                <div class="content-view">
                    <div class="info">
                        <div class="name-view">
                            <div class="name">{{ item.userName }}</div>
                            <div class="time">{{ item.createTime }}</div>
                        </div>
                        <div class="desc">{{ item.applyMsgList[0] }}</div>
                    </div>
                    <div class="handle-view">
                        <template v-if="item.applyState == 'audit'">
                            <div class="agree" v-if="item.active == 'N'" @click="handleAgree(item)">同意</div>
                            <div class="tip" v-else>
                                <i class="icon-sohu-youshangjiantou"></i>
                                <span>等待对方审核</span>
                            </div>
                        </template>
                        <div class="tip" v-else>{{ stateFilter(item.applyState) }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="empty" v-if="!list.length && !loading">
            <common-empty content=" "/>
        </div>
    </main-base-view>
</template>

<script>
import { sendWSPush } from "@/utils/websocket";
import MainBaseView from "@/views/chat/components/friend/base/mainBaseView.vue";
import CommonEmpty from "@/views/chat/components/common/commonEmpty.vue";
export default {
    name: "newFriendView",
    components: {
        MainBaseView,
        CommonEmpty
    },
    data() {
        return {
            loading: true,
        }
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        total: {
            type: Number,
            default: 0
        }
    },
    watch: {
        list: {
            handler(val) {
                this.loading = false;
                if (!val.length) return;
            },
            deep: true,
            // immediate: true,
        }
    },
    methods: {
        /**
         * 新的好友状态过滤
         * @param val
         * @returns {String}
         */
        stateFilter(val) {
            const obj = {
                timeOut: '已过期',
                pass: '已添加',
                audit: '审核中',
                reject: '拒绝',
            };
            return val && obj[val];
        },
        /**
         * 操作同意
         * @param model
         * @returns {Promise<void>}
         */
        async handleAgree(model) {
            console.log(model,"????")
            const res = await this.$http.appFriendsPass({
                ...model
            });
            if (res.code === 200) {
                this.$message.success('操作成功');
                model.applyState = 'pass';
                sendWSPush({
                    localId: this.$util.getUuid(),
                    receiverId: model.friendId,
                    sessionType: 'single',
                    messageType: 'text',
                    content: `你们已经是好友了，现在可以开始聊天了`,
                })
                this.$emit("updateList");
                this.loading = true;
            }
        },
        /**
         * 处理触底加载
         */
        handleScrollBottom() {
            if (this.total > this.list.length) {
                this.loading = true;
                this.$emit("pageChange");
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.list-view {
  width: 100%;
  height: 100%;

  .item {
    width: 100%;
    height: 106px;
    display: flex;
    align-items: center;
    padding: 0 30px 0 30px;
    box-sizing: border-box;

    .avatar-view {
      position: relative;
      margin-right: 15px;

      .avatar {
        width: 44px;
        height: 44px;
        border-radius: 8px;
      }

      .point {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #F94040;
        position: absolute;
        top: -6px;
        right: -6px;
      }
    }

    .content-view {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E7E7E7;

      .info {
        .name-view {
          display: flex;
          align-items: center;

          .name {
            color: #222;
            font-size: 16px;
            line-height: 22px;
            margin-right: 19px;
          }

          .time {
            color: #BBBBBB;
            font-size: 12px;
            line-height: 17px;
          }
        }

        .desc {
          max-width: 324px;
          color: #999;
          font-size: 12px;
          line-height: 17px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 6px;
        }
      }

      .handle-view {
        .agree {
          width: 90px;
          height: 36px;
          border-radius: 8px;
          background-color: $theme-color;
          color: #FFF;
          font-size: 14px;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
        }

        .tip {
          font-size: 14px;
          color: #999;
          line-height: 20px;
          display: flex;
          gap: 2px;
          align-items: center;

          .icon-sohu-youshangjiantou {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>