<template>
	<div class="user_item">
		<div class="leftUserData">
			<el-avatar :src="item.avatar || item.userAvatar || item.logo"></el-avatar>
			<div class="line-1 nickName">
				{{ myName }}
				<span v-if="item.groupUserNum || item.groupUserCount">
					（{{ item.groupUserNum || item.groupUserCount }}）
				</span>
			</div>
			<span class="tag" v-if="item.isMain">主群</span>
		</div>

		<div class="rightSet">
			<div
				class="setBox"
				v-if="['好友', '群聊', '切换群聊'].includes(type)"
				@click="sendMessageClick(item)"
			>
				<i class="icon-sohu-xiaoxi1"></i>
			</div>
			<button
				v-if="['添加好友'].includes(type)"
				@click="applicationClick(item)"
				:class="['addFriend-btn', { disable: disable }]"
				:disabled="disable"
			>
				{{ disable ? '已添加' : '添加' }}
			</button>

			<div
				class="setBox"
				v-if="['群关联', '群聊', '切换群聊'].includes(type)"
				@click="setRow"
			>
				<i class="icon-sohu-shezhi"></i>
			</div>
			<!-- <div
				v-if="['好友', '添加好友'].includes(type)"
				class="setBox"
				@click="businessClick(item)"
			>
				<i class="icon-sohu-mingpian"></i>
			</div> -->
			<div
				v-if="['好友'].includes(type)"
				class="setBox"
				@click="businessClick(item)"
			>
				<i class="icon-sohu-mingpian"></i>
			</div>
			<div
				class="setBox"
				v-if="['群关联', '设置管理员', '群禁言'].includes(type)"
				@click="delRow"
			>
				<i class="icon-sohu-shanchu"></i>
			</div>
		</div>

		<!-- 个人名片弹窗 -->
		<businessCardDialog ref="businessCardDialogRef"></businessCardDialog>

		<!-- 申请添加好友弹窗 -->
		<ApplicationDialog
			@addFriendIng="handleAddFriendIng"
			ref="ApplicationDialogRef"
		></ApplicationDialog>

		<!-- 群设置弹窗 -->
		<GroupSettingsDialog
			ref="GroupSettingsDialogRef"
			:chatUserMsgActive="chatUserMsgActive"
		></GroupSettingsDialog>
	</div>
</template>
<script>
import BusinessCardDialog from '@/views/chat/components/user/businessCardDialog.vue';
import ApplicationDialog from '@/views/chat/components/user/applicationDialog.vue';
export default {
	props: ['item', 'type', 'chatUserMsg'],
	components: {
		BusinessCardDialog,
		ApplicationDialog,
		GroupSettingsDialog: () =>
			import('@/views/chat/components/group/groupSettingsDialog.vue'),
	},
	data() {
		return {
			disable: false,
		};
	},
	computed: {
		myName() {
			if (this.item.alias) return this.item.alias;
			if (this.item.groupUserNickName) return this.item.groupUserNickName;
			if (this.item.nickName) return this.item.nickName;
			if (this.item.name) return this.item.name;
			if (this.item.userName) return this.item.userName;
		},
		chatUserMsgActive() {
			let chatUserMsgActive;
			if (this.chatUserMsg)
				this.chatUserMsg.map((item, index) => {
					if (
						item.receiver.id == this.item.id &&
						item.sessionType != 'single'
					) {
						chatUserMsgActive = item;
					}
				});
			return chatUserMsgActive;
		},
	},
	inject: {
		close: { value: 'close', default: null },
		imGroupSubList: { value: 'imGroupSubList', default: null },
		groupAdminList: { value: 'groupAdminList', default: null },
		imGroupForbidList: { value: 'imGroupForbidList', default: null },
		switchGroupChat: { value: 'switchGroupChat', default: null },
	},
	watch: {
		item: {
			//item.meFriend
			depp: true,
			immediate: true,
			handler(val) {
				this.disable = val.meFriend;
			},
		},
	},
	mounted() {
		console.log(' this.item: ', this.item);
		console.log('this.chatUserMsg: ', this.chatUserMsg);
	},
	methods: {
		// 已经确认添加好友
		handleAddFriendIng(bool) {
			if (bool) this.disable = bool;
		},
		// 点击名片按钮
		businessClick(val) {
			this.$refs.businessCardDialogRef.open(val.friendId || val.id);
		},

		// 申请添加好友
		applicationClick(val) {
			this.$refs.ApplicationDialogRef.open(val.id);
		},

		// 发送消息
		sendMessageClick(val) {
			console.log(val);
			console.log(this.type);
			if (this.type == '切换群聊') {
				this.switchGroupChat(val);
				this.$emit('close');
			} else {
				let messageList = this.$store.state.messageList;
				let userId = val.friendId || val.id;
				let data = {
					receiver: {
						avatar: val.userAvatar || val.logo,
						id: userId,
						name: val.userName || val.name,
					},
					sessionType: val.userAvatar ? 'single' : 'group',
					userAvatar: val.userAvatar || val.logo,
					userName: val.userName || val.name,
				};
				if (
					this.$store.state.messageList.every(
						(item) => item.receiver.id != userId,
					)
				) {
					console.log(data);
					messageList.unshift(data);
				}
				this.close(data);
			}
		},

		// 设置按钮
		setRow() {
			this.$refs.GroupSettingsDialogRef.open(this.item.id);
		},

		// 删除按钮
		delRow() {
			console.log(this.item);
			let content = '';
			if (this.type == '群关联') {
				content = '是否删除群关联？';
			}
			if (this.type == '设置管理员') {
				content = '是否取消其管理员身份？';
			}
			if (this.type == '群禁言') {
				content = '是否取消其禁言权限？';
			}
			this.$confirmDialog({
				content: content,
				beforeClose: async (state, close) => {
					if (state == 'confirm') {
						let res;
						if (this.type == '群关联') {
							res = await this.$http.imGroupDismiss(this.item.id);
							this.$store.dispatch('getUserMessageList');
						} else if (this.type == '群禁言') {
							res = await this.$http.imGroupBatchSetforbid({
								forbid: false,
								groupId: this.item.groupId,
								userIds: [this.item.userId],
							});
						} else {
							res = await this.$http.imGroupBatchSetrole({
								permissionType: 'group_user',
								groupId: this.item.groupId,
								userIds: [this.item.userId],
							});
						}
						if (res.code == 200) {
							this.$message.success('删除成功');
							if (this.type == '群关联') {
								this.imGroupSubList();
							} else if (this.type == '群禁言') {
								this.imGroupForbidList(this.item.groupId);
							} else {
								this.groupAdminList(this.item.groupId);
							}
							this.$store.dispatch('getUserMessageList');
							close();
						}
					}
				},
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.user_item {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.leftUserData {
		display: flex;
		align-items: center;
		color: #3d3d3d;
		font-size: 14px;
		font-weight: 500;

		.nickName {
			width: 240px;
			max-width: 208px;
			color: #222;
			line-height: 36px;
			// white-space: nowrap;
			overflow: hidden;
			/* 隐藏超出容器的内容 */
			text-overflow: ellipsis;
			/* 当文本溢出时显示省略号 */
		}

		.el-avatar {
			width: 40px;
			// min-width: 40px;
			height: 40px;
			margin-right: 10px;
		}

		.tag {
			background: #fef0e2;
			font-size: 12px;
			padding: 1px 8px;
			line-height: 18px;
			margin-left: 17px;
			color: #f27f00;
			border-radius: 2.86px;
			display: inline-block;
			text-wrap: nowrap;
			border-radius: 4px;
		}
	}

	.rightSet {
		display: flex;
		align-items: center;

		.addFriend-btn {
			width: 90px;
			height: 36px;
			border-radius: 8px;
			background: $theme-color;
			color: #ffffff;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.addFriend-btn.disable {
			background: #c8e8ff;
			color: #fff;
		}

		.setBox {
			background: #f3f3f3;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			margin-left: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}

			i {
				font-size: 20px;
				color: #333333;
			}
		}
	}
}
</style>
