
//分享类型
export const ShareEnum = {
    //任务
    task: "task",
    //商品
    good: "good",
    //图文
    article: "article",
    //视频
    video: "video",
    //回答
    answer: "answer",
    //商品橱窗
    goodWindow: "goodWindow",
    //个人名片
    person: "person",
    //邀请好友
    invite: "invite",
    //店铺商品
    merchantGoods: "merchantGoods",
    //店铺订单
    merchantOrder: "merchantOrder",
    //拆单任务分享
    splitTask: "splitTask",
    //商单分享
    merchantTask: "merchantTask"
}

/**
 * 分享展示文本
 * @returns {string}
 * @param type
 */
export function shareFilter(type) {
    switch (type) {
        case ShareEnum.person:
            return "[个人名片]"
        case ShareEnum.good:
            return "[商品]"
        case ShareEnum.task:
            return "[任务]"
        case ShareEnum.answer:
            return "[回答]"
        case ShareEnum.article:
            return "[图文]"
        case ShareEnum.video:
            return "[视频]"
    }
}